import videojs from 'video.js';
import debug from 'debug';

// Setup debugging
const debugLog = debug('AdBlockerBlockerPlugin');

const STYLES = {
  BLOCK: 'block',
  BLUR: 'blur',
};

const DEFAULT_OPTIONS = {
  debug: false,
  style: STYLES.BLOCK,
};

export default class AdBlockerBlocker {
  constructor(player, incomingOptions) {
    this._player = player;
    this._options = videojs.mergeOptions(DEFAULT_OPTIONS, incomingOptions);

    this._log('options', this._options);

    if (this._options.enabled === false) {
      this._log('AdBlocker blocker disabled');
      return;
    }

    if (!incomingOptions) throw new Error('no options provided');

    this._player.el().classList.add('vjs-ad-blocker-blocker');


    this._player.on('ready', () => {
      if (this._player.env.iPhone) this._options.style = STYLES.BLOCK;
      this._player.setTimeout(() => { // wait for avod-player to finish loading
        if (this._player.connectivity) {
          this._player.connectivity.on(this._player.connectivity.AD_BLOCKER, this._adBlockerDetected.bind(this));
          this._player.connectivity.on(this._player.connectivity.DISCONNECTED, this._adBlockerDetected.bind(this));
        }
      }, 0);
    });
  }

  _adBlockerDetected() {
    if (this._options.style === STYLES.BLOCK) {
      this._log('AdBlocker detected and blocked!');
      this._showStyleBlock();
    } else if (this._options.style === STYLES.BLUR) {
      this._log('AdBlocker detected and blurred!');
      this._showStyleBlur();

      this._player.on('adRollEnd', this._showStyleBlur.bind(this));
      this._player.on('adRollStart', this._hideStyleBlur.bind(this));
    }
  }

  _showStyleBlock() {
    this._destroyPlayer();
    this._showMessage({
      message: this._options.label,
      className: 'solid',
      reloadable: !!this._player.originalOptions.reloadCallback,
    });
  }

  _showStyleBlur() {
    this._removeQualitySelector();
    this._useLowestQualityStream();
    this._player.trigger('showOverlayBlur');
    this._showMessage({
      message: this._options.label,
      closeable: true,
      showOnce: true,
    });
  }

  _hideStyleBlur() {
    this._player.trigger('hideOverlayBlur');
  }

  _destroyPlayer() {
    this._player.trigger('destroyPlayer');
  }

  _showMessage(options) {
    this._player.trigger('showOverlayMessage', options);
  }

  _useLowestQualityStream() {
    if (!this._player.tech_.hls) {
      return;
    }

    if (this._player.tech_.hls.playlists.master) {
      this._player.tech_.hls.selectPlaylist = this._getPlaylistWithLowestBitrate.bind(this);
    }
  }

  _removeQualitySelector() {
    const removeQualitySelector = () => {
      const container = this._player.getChild('controlBar');

      if (container.getChild('qualitySelectorButton')) {
        container.removeChild('qualitySelectorButton');
      }
      if (container.getChild('qualitySelectorItem')) {
        container.removeChild('qualitySelectorItem');
      }
    };

    removeQualitySelector();
    this._player.one('loadedmetadata', removeQualitySelector.bind(this));
  }

  _getPlaylistWithLowestBitrate() {
    const playlists = this._player.tech_.hls.playlists;
    const resolutions = playlists.master.playlists
      .map(playlist => (
        playlist.attributes &&
        playlist.attributes.RESOLUTION &&
        playlist.attributes.RESOLUTION.height
      ))
      .filter(resolution => resolution)
      .sort((a, b) => a - b);

    const resolution = resolutions[0];

    return playlists.master.playlists
      .filter(playlist => (
        playlist.attributes.RESOLUTION && playlist.attributes.RESOLUTION.height === resolution
      ))[0];
  }

  _log(...args) {
    debugLog(`[#${this._player.id()}]`, ...args);
  }
}

videojs.plugin('adBlockerBlocker', function init(options) {
  this.adBlockerBlocker = new AdBlockerBlocker(this, options);
});
