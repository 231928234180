import React from 'react';
import debug from 'debug';

const debugLog = debug('chromecastUI:log');

class Duration extends React.Component {
  constructor(props, context) {
    super(props, context);
    debugLog('Constructing react Duration', this.props);

    /** Assume props
     {} this.props.currentTime
     bool this.props.duration
     */
    this.state = {
      durationValue: this.getDuration(this.props.currentTime, this.props.duration),
    };
  }
  componentWillReceiveProps(nextProps) {
    this.setState({ durationValue: this.getDuration(nextProps.currentTime, nextProps.duration) });
  }

  getDuration(currentTime, duration) {
    const remainingTime = duration - currentTime;

    if (!duration || !remainingTime) {
      return '00:00';
    }

    let remainingTimeSeconds = Math.floor(remainingTime % 60);
    remainingTimeSeconds = `${remainingTimeSeconds < 10 ? '0' : ''}${remainingTimeSeconds}`;
    let remainingTimeMins = Math.floor(remainingTime / 60);
    if (remainingTimeMins === 0) {
      return `00:${remainingTimeSeconds}`;
    }
    const remainingTimeHours = Math.floor(remainingTimeMins / 60);
    remainingTimeMins = `${remainingTimeMins < 10 ? '0' : ''}${remainingTimeMins}`;
    if (remainingTimeHours === 0) {
      return `${remainingTimeMins}:${remainingTimeSeconds}`;
    }

    remainingTimeMins = Math.floor(remainingTimeMins % 60);
    remainingTimeMins = `${remainingTimeMins < 10 ? '0' : ''}${remainingTimeMins}`;

    return `${remainingTimeHours}:${remainingTimeMins}:${remainingTimeSeconds}`;
  }

  render() {
    return (
      <div className="duration">
        <div>-{this.state.durationValue}</div>
      </div>
    );
  }
}

export default Duration;
