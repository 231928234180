import vjs from 'video.js';

const Component = vjs.getComponent('Component');

export default vjs.extend(Component, {
  createEl: function createEl() {
    return Component.prototype.createEl.call(this, 'div', {
      className: 'ad-click-through',
      innerHTML: this.player().localize('Go to the advertiser\'s page'),
    });
  },
});
