import videojs from 'video.js';
import debug from 'debug';

import OverlayMessageOverlayComponent from './components/overlay-message-overlay';
import OverlayMessageLabelComponent from './components/overlay-message-label';
import OverlayMessageCloseButtonComponent from './components/overlay-message-close-button';
import OverlayMessageReloadButtonComponent from './components/overlay-message-reload-button';

// Setup debugging
const debugLog = debug('AVODPlayer:OverlayMessage');

export default class OverlayMessage {

  constructor(player) {
    this._player = player;

    videojs.registerComponent('OverlayMessageOverlay', OverlayMessageOverlayComponent);
    videojs.registerComponent('OverlayMessageLabel', OverlayMessageLabelComponent);
    videojs.registerComponent('OverlayMessageCloseButton', OverlayMessageCloseButtonComponent);
    videojs.registerComponent('OverlayMessageReloadButton', OverlayMessageReloadButtonComponent);

    this._overlayMessageDismissed = [];
    this._player.on('showOverlayMessage', this._showOverlayMessage.bind(this));
    this._player.on('hideOverlayMessage', this._hideOverlayMessage.bind(this));
    this._player.on('showOverlayBlur', this._blurPlayer.bind(this));
    this._player.on('hideOverlayBlur', this._unblurPlayer.bind(this));
  }

  _showOverlayMessage(event, data) {
    if (data.onlyOnce && this._overlayMessageDismissed.indexOf(data.message) !== -1) return;

    if (this._hasMessageShowing) {
      this._player.trigger('hideOverlayMessage');
    }

    if (!data.message) data.message = '';

    this._log('_showOverlayMessage');
    this._player.addClass('x-overlay-message-showing');

    this._hasMessageShowing = true;

    this._player.ready(() => {
      if (data.blur) this._blurPlayer();

      this._overlayComponent = this._player.addChild('OverlayMessageOverlay');
      if (data.className) this._overlayComponent.addClass(data.className);

      this._labelComponent = this._overlayComponent.addChild('OverlayMessageLabel', { label: data.message });

      if (data.renderMessageComponent) {
        data.renderMessageComponent(this._labelComponent);
      }

      if (data.closeable) {
        this._showOverlayMessageCloseButton(data.message, data.closeCallback);
        this._player.on('adRollStart', () => {
          this._overlayMessageDismissed.push(data.message);
          this._hideOverlayMessage();
          if (data.closeCallback) data.closeCallback();
        });
      }

      if (data.reloadable) this._showOverlayMessageReloadButton();

      this._player.trigger('overlayMessageShown');
    });
  }

  _showOverlayMessageCloseButton(message, closeCallback) {
    const closeButton = this._labelComponent.addChild('OverlayMessageCloseButton', {
      label: 'X',
    });

    Array.prototype.forEach.call(
      this._labelComponent.el().children,
      child => child.addEventListener('click', event => event.stopPropagation())
    );

    const doClose = () => {
      this._overlayMessageDismissed.push(message);
      this._hideOverlayMessage();
      if (closeCallback) closeCallback();
    };
    this._overlayComponent.on(['click', 'touchend'], doClose.bind(this));
    closeButton.on(['click', 'touchend'], doClose.bind(this));
  }

  _showOverlayMessageReloadButton() {
    const reloadButton = this._overlayComponent.addChild('OverlayMessageReloadButton');

    Array.prototype.forEach.call(
      this._labelComponent.el().children,
      child => child.addEventListener('click', event => event.stopPropagation())
    );

    reloadButton.on(['click', 'touchend'], this._player.reload);
  }

  _hideOverlayMessage() {
    this._hasMessageShowing = false;
    this._player.removeClass('x-overlay-message-showing');
    this._player.removeChild('OverlayMessageOverlay');
    this._player.trigger('overlayMessageHidden');
  }

  _blurPlayer() {
    this._log('_blurPlayer');
    const techEl = this._player.tech_.el();
    techEl.className += ' blurred';
    this._player.el().className += ' blurred';

    // Make it harder to remove the blur with dev tools and plugins
    if (this._blurInterval) this._player.clearInterval(this._blurInterval);
    this._blurInterval = this._player.setInterval(() => {
      const blur = this._hasMessageShowing ? 25 : 5;
      const brightness = this._hasMessageShowing ? 0.6 : 1;
      techEl.style.cssText =
        `filter: saturate(0.95) grayscale(.05) brightness(${brightness}) contrast(.95) blur(${blur}px) !important; \
 -webkit-filter: saturate(0.95) grayscale(.05) brightness(${brightness}) contrast(.95) blur(${blur}px) !important;`;
    }, 200);
  }

  _unblurPlayer() {
    this._log('_unblurPlayer');
    const techEl = this._player.tech_.el();
    techEl.className = techEl.className.replace(/[ ]*blurred/g, '');
    this._player.el().className = this._player.el().className.replace(/[ ]*blurred/g, '');
    techEl.style.cssText = '';
    this._player.clearInterval(this._blurInterval);
  }

  _log(...args) {
    debugLog(`[#${this._player.id()}]`, ...args);
  }
}

videojs.plugin('overlayMessage', function init() {
  this.overlayMessage = new OverlayMessage(this);
});
