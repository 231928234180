import vjs from 'video.js';
import debug from 'debug';
import meta from '../../../src/util/meta';

// Setup debugging
const debugLog = debug('gemius-prism:log');
const errorLog = debug('gemius-prism:error');

export default class GemiusPrismPlugin {
  constructor(player, options) {
    if (!window.GemiusPlayer) {
      this._log('Gemius script not loaded');
      return;
    }
    this._player = player;
    this._options = options;
    this._log('options', this._options);

    this.setupListeners();
  }

  setupListeners() {
    const { materialIdentifier, identifier, category, title, formatTitle, duration } = this._options;
    const { _player } = this;
    const gemius = new window.GemiusPlayer(`${meta.playerName}-${meta.playerVersion}`, identifier);
    const gemiusEvent = (type, ...args) => {
      const time = _player.currentTime();
      debugLog('gemius event', { materialIdentifier, time, type }, ...args);
      return gemius.programEvent(materialIdentifier, time, type, ...args);
    };
    const seekReport = () => {
      if (this.isSeeking) return;
      this.isSeeking = true;
      gemiusEvent('seek');
      _player.one('playing', () => { this.isSeeking = false; });
    };
    _player.on('controlStart', () => {
      gemius.newProgram(
        materialIdentifier,
        {
          programName: title,
          programType: 'Video',
          typology: category,
          series: formatTitle,
          // if not possible to get duration (e.g. live) programDuration should be set to -1
          programDuration: duration || -1,
        }
      );
      gemiusEvent('play');

      window.addEventListener('unload', () => gemiusEvent('close'));
    });

    _player.on(['adPrerollEnd', 'adMidrollEnd', 'mediaBufferEnd', 'mediaPlay'], () => gemiusEvent('play'));
    _player.on('mediaPause', () => gemiusEvent('pause'));
    _player.on('mediaSeekStart', () => seekReport());
    _player.on('mediaBufferStart', () => gemiusEvent('buffer'));
    _player.on('adRollStart', () => gemiusEvent('break'));
    _player.on('controlEnd', () => gemiusEvent('complete'));
  }

  _log(...args) {
    debugLog(`[#${this._player.id()}]`, ...args);
  }

  _error(...args) {
    errorLog(`[#${this._player.id()}]`, ...args);
    console.error('> avodp - gemius error >>', args[0]); // eslint-disable-line no-console
  }
}

vjs.plugin('gemiusPrism', function init(options) {
  this.gemiusPrism = new GemiusPrismPlugin(this, options);
});
