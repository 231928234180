import videojs from 'video.js';

const VjsButton = videojs.getComponent('Button');

export default class OverlayMessageReloadButtonComponent extends VjsButton {
  createEl() {
    const props = {
      className: 'vjs-overlay-message-reload-button',
      innerHTML: this.player().localize('Try again'),
    };
    return VjsButton.prototype.createEl('div', props, {});
  }
}
