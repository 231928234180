import DkStreams from 'denmarkStreams';
import NoStreams from 'norwayStreams';

import videojs from 'video.js';
import debug from 'debug';
import meta from '../../../src/util/meta';

// Setup debugging
const debugLog = debug('GallupPlugin');

const DEFAULT_OPTIONS = {
  ct: '',
  cq: '',
  trackAds: false,
};

export default class GallupPlugin {
  constructor(player, options) {
    if (!options) throw new Error('no options provided');
    if (!options.siteId) throw new Error('options.siteId needs to be specified');
    if (!options.stream) throw new Error('options.stream needs to be specified');
    if (!options.country) throw new Error('options.country needs to be specified');

    this._player = player;
    this._options = videojs.mergeOptions(DEFAULT_OPTIONS, options);

    // Add prefix when included for test accounts
    if (this._options.prefix) this._options.stream = this._options.prefix + this._options.stream;

    this._trackers = {};
    this._hasMediaEnded = true;

    this._options.playerName = this._options.playerName || meta.playerName;
    this._options.playerVersion = this._options.playerVersion || meta.playerVersion;

    this._init();
  }

  _init() {
    this._log('_init: Initalizing Plugin');

    this._sensors = this._createSensors(this._options.country, this._options.siteId);

    if (this._sensors !== null) {
      this._player.on('mediaStart', this._onMediaStart.bind(this));
      this._player.on('mediaPlay', this._onMediaPlay.bind(this));
      this._player.on('mediaEnd', this._onMediaEnd.bind(this));

      if (this._options.trackAds) {
        this._player.on('adStart', this._onAdStart.bind(this));
        this._player.on('adEnd', this._onAdEnd.bind(this));
        this._player.on('adRollEnd', this._onAdRollEnd.bind(this));
      }

      this._player.on('dispose', this._onDispose.bind(this));

      window.unload = () => {
        this._onDispose();
        // give time for submission
        const start = new Date();
        let now = null;
        do {
          now = new Date();
        }
        while (now - start < 100);
      };
    }
  }

  _onDispose() {
    this._log('_onDispose: Unload');
    if (this._sensors) {
      this._sensors.unload();
    }
  }

  _onMediaPlay() {
    // Replay media tracking
    if (this._hasMediaEnded) {
      this._startTracking({ streamPath: this._options.stream, trackerId: 'main' });
      this._hasMediaEnded = false;
    }
  }

  _onAdRollEnd() {
    // Resume tracking
    if (!this._hasMediaEnded) {
      this._startTracking({ streamPath: this._options.stream, trackerId: 'main' });
    }
  }

  _onMediaStart() {
    // Start Media tracking
    this._startTracking({ streamPath: this._options.stream, trackerId: 'main' });
    this._hasMediaEnded = false;
  }

  _onMediaEnd() {
    // Stop Media tracking
    this._stopTracking('main');
    this._hasMediaEnded = true;
  }

  _onAdStart(event) {
    // Starting Ad tracking
    this._adCq = (event && event.ad && event.ad.customId) ? event.ad.customId : 'unknown';
    this._startTracking({ streamPath: this._options.adsStream, trackerId: this._adCq });
  }

  _onAdEnd() {
    // Stopping Ad tracking
    this._stopTracking(this._adCq);
    this._adCq = null;
  }

  _createSensors(country, siteId) {
    let sensors;
    switch (country) {
      case 'no':
        sensors = new NoStreams(siteId);
        break;
      case 'dk':
        sensors = new DkStreams(siteId);
        break;
      default:
        sensors = null;
        break;
    }
    return sensors;
  }

  _startTracking({ streamPath, trackerId }) {
    const isAds = (trackerId !== 'main');
    if (isAds && !this._hasMediaEnded) this._stopTracking('main');

    const streamObject = { stream: streamPath };
    if (this._options.country === 'no') {
      streamObject.ct = this._options.ct;
      streamObject.cq = isAds ? this._adCq : this._options.cq;
    }
    this._log('_startTracking', this._player.id(), streamObject, this._getAdapter(isAds).getMeta());
    this._trackers[trackerId] = this._sensors.track(this._player.id(), streamObject, this._getAdapter(isAds));
  }

  _stopTracking(trackerId) {
    if (this._trackers[trackerId]) {
      this._log('_stopTracking', trackerId);
      this._trackers[trackerId].stop();
    }
  }

  _getDuration(isAds) {
    const isLive = this._player.isLive();

    if (isLive && this._options.country === 'no') {
      return 0;
    }

    return Math.floor(isAds ? this._player.duration() : this._player.getMediaDuration());
  }

  _getAdapter(isAds) {
    return {
      getMeta: () => {
        const baseObject = {
          pl: this._options.playerName,
          plv: this._options.playerVersion,
          sx: this._player.el().offsetWidth,
          sy: this._player.el().offsetHeight,
        };
        if (this._options.country === 'dk') {
          baseObject.ct = this._options.ct;
          baseObject.cq = isAds ? this._adCq : this._options.cq;
        }
        return baseObject;
      },
      getDuration: () => this._getDuration(isAds),
      getPosition: () => Math.floor(isAds ? this._player.currentTime() : this._player.getMediaCurrentTime()),
    };
  }

  _log(...args) {
    debugLog(`[#${this._player.id()}]`, ...args);
  }
}

videojs.plugin('gallupTracking', function init(options) {
  this.gallupPlugin = new GallupPlugin(this, options);
});
