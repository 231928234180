import debug from 'debug';

const debugLog = debug('playerHandler:log');

/**
 * PlayerHandler
 *
 * This is a handler through which the application will interact
 * with both the RemotePlayer and LocalPlayer. Combining these two into
 * one interface is one approach to the dual-player nature of a Cast
 * Chrome application. Otherwise, the state of the RemotePlayer can be
 * queried at any time to decide whether to interact with the local
 * or remote players.
 *
 * To set the player used, implement the following methods for a target object
 * and call setTarget(target).
 *
 * Methods to implement:
 *  - play()
 *  - pause()
 *  - stop()
 *  - seekTo(time)
 *  - load(mediaIndex)
 *  - getMediaDuration()
 *  - getCurrentMediaTime()
 *  - setVolume(volumeSliderPosition)
 *  - mute()
 *  - unMute()
 *  - isMuted()
 *  - updateDisplayMessage()
 */
const FULL_VOLUME_HEIGHT = 100;
const PLAYER_STATE = {
  IDLE: 'IDLE',
  LOADING: 'LOADING',
  LOADED: 'LOADED',
  PLAYING: 'PLAYING',
  PAUSED: 'PAUSED',
  STOPPED: 'STOPPED',
  ERROR: 'ERROR',
};

export default class PlayerHandler {

  constructor(playerInfo, playerController) {
    this.playerInfo = playerInfo;
    this.playerController = playerController;
  }

  play() {
    debugLog('Play');
    if (this.playerInfo.isPaused) {
      this.playerController.playOrPause();
    }
  }

  pause() {
    if (!this.playerInfo.isPaused) {
      this.playerController.playOrPause();
    }
  }

  load(videoId, time, customData) {
    // this.castPlayer.playerState = PLAYER_STATE.LOADING;
    debugLog('Loading new media', videoId);
    const mediaInfo = new window.chrome.cast.media.MediaInfo(videoId, 'video/mp4');
    mediaInfo.metadata = new window.chrome.cast.media.GenericMediaMetadata();
    mediaInfo.metadata.metadataType = window.chrome.cast.media.MetadataType.GENERIC;
    mediaInfo.customData = customData;
    const request = new window.chrome.cast.media.LoadRequest(mediaInfo);
    request.currentTime = time;
    window.cast.framework.CastContext.getInstance().getCurrentSession().loadMedia(request).then(
      this.loaded.bind(this),
      (errorCode) => {
        this.playerState = PLAYER_STATE.ERROR;
        debugLog(`Remote media load error ${this.getErrorMessage(errorCode)}`);
      });
  }

  loaded() {
    debugLog('LOADED', this.playerInfo.mediaInfo);
  }

  getCurrentMediaTime() {
    return this.playerInfo.currentTime;
  }

  getMediaDuration() {
    return this.playerInfo.duration;
  }

  setVolume(volumeSliderPosition) {
      // // Add resistance to avoid loud volume
    let currentVolume = this.playerInfo.volumeLevel;

    if (volumeSliderPosition < FULL_VOLUME_HEIGHT) {
      currentVolume = volumeSliderPosition / FULL_VOLUME_HEIGHT;
    } else {
      currentVolume = 1;
    }
    this.playerInfo.volumeLevel = currentVolume;
    this.playerController.setVolumeLevel();
  }

  mute() {
    if (!this.playerInfo.isMuted) {
      this.playerController.muteOrUnmute();
    }
  }

  unMute() {
    if (this.playerInfo.isMuted) {
      this.playerController.muteOrUnmute();
    }
  }

  isMuted() {
    return this.playerInfo.isMuted;
  }

  seekTo(time) {
    this.playerInfo.currentTime = time;
    this.playerController.seek();
  }

  hideSuggestion() {
    window.cast.framework.CastContext.getInstance().getCurrentSession()
      .sendMessage('urn:x-cast:viafree.suggestions', { name: 'hideSuggestions' });
  }

  playSuggestion() {
    window.cast.framework.CastContext.getInstance().getCurrentSession()
      .sendMessage('urn:x-cast:viafree.suggestions', { name: 'playSuggestion' });
  }

  changeSubtitles(id) {
    const activeTrackIds = id === 0 ? [] : [id];
    const tracksInfoRequest = new window.chrome.cast.media.EditTracksInfoRequest(activeTrackIds);
    window.cast.framework.CastContext.getInstance().getCurrentSession().getMediaSession()
      .editTracksInfo(tracksInfoRequest);
  }

    /**
   * Makes human-readable message from chrome.cast.Error
   * @param {chrome.cast.Error} error
   * @return {string} error message
   */
  getErrorMessage(error) {
    debugLog('CC Error', error);

    if (!error.code) return error;

    switch (error.code) {
      case window.chrome.cast.ErrorCode.API_NOT_INITIALIZED:
        return `The API is not initialized
          ${error.description ? error.description : ''})`;
      case window.chrome.cast.ErrorCode.CANCEL:
        return `The operation was canceled by the user
          ${error.description ? error.description : ''}`;
      case window.chrome.cast.ErrorCode.CHANNEL_ERROR:
        return `A channel to the receiver is not available
          ${error.description ? error.description : ''}`;
      case window.chrome.cast.ErrorCode.EXTENSION_MISSING:
        return `The Cast extension is not available
          ${error.description ? error.description : ''}`;
      case window.chrome.cast.ErrorCode.INVALID_PARAMETER:
        return `The parameters to the operation were not valid
          ${error.description ? error.description : ''}`;
      case window.chrome.cast.ErrorCode.RECEIVER_UNAVAILABLE:
        return `No receiver was compatible with the session request
          ${error.description ? error.description : ''}`;
      case window.chrome.cast.ErrorCode.SESSION_ERROR:
        return `A session could not be created, or a session was invalid
          ${error.description ? error.description : ''}`;
      case window.chrome.cast.ErrorCode.TIMEOUT:
        return `The operation timed out
          ${error.description ? error.description : ''}`;
      default:
        return error;
    }
  }
}
