import videojs from 'video.js';

const VjsComponent = videojs.getComponent('Component');

export default class OverlayMessageOverlayComponent extends VjsComponent {
  createEl() {
    const props = {
      className: 'vjs-overlay-message-overlay',
    };
    return VjsComponent.prototype.createEl('div', props, {});
  }
}
