import React from 'react';
import debug from 'debug';

const debugLog = debug('chromecastUI:log');

class NextEpisode extends React.Component {
  constructor(props, context) {
    super(props, context);
    debugLog('Constructing react NextEpisode', this.props);

    /** Assume props
     string this.props.translatedPlayingIn
     {} this.props.playerAction
     { countdownTime
      videoTitle
      videoImage
      videoNextType
      } = this.props.nextEpisodeInfo
     */
  }

  playSuggestion() {
    this.props.playerAction.playSuggestion(this.props.playerAction);
  }

  hideSuggestion() {
    this.props.playerAction.hideSuggestion(this.props.playerAction);
  }
  render() {
    return (
      <div className="next-episode">
        <button
          className="next-episode-image control-icon-play"
          onClick={e => this.playSuggestion(e)}
          style={{ backgroundImage: `url('${this.props.nextEpisodeInfo.videoImage}')` }}
        />
        <div className="next-episode-meta">
          <button
            className="next-episode-meta-cancel"
            onClick={e => this.hideSuggestion(e)}
          />
          <div className="next-episode-meta-type">
            {this.props.nextEpisodeInfo.videoNextType}
          </div>
          <div className="next-episode-meta-title">
            {this.props.nextEpisodeInfo.videoTitle}
          </div>
          <div className="next-episode-meta-starting-in">
            <div className="next-episode-meta-starting-in-sentence">{this.props.translatedPlayingIn}</div>
            <div className="next-episode-meta-starting-in-countdown">{this.props.nextEpisodeInfo.countdownTime}</div>
          </div>
        </div>
      </div>
    );
  }
}

export default NextEpisode;
