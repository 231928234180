import debug from 'debug';
import videojs from 'video.js';
import document from 'global/document';
import fullScreenApi from './util/fullscreen-api';

// Setup debugging
const debugLog = debug('FullscreenToggle');

const FullScreenToggle = videojs.getComponent('FullscreenToggle');

export default class CustomFullScreenToggle extends FullScreenToggle {

  constructor(player, options) {
    super(player, options);

    this._player = player;
    this._parentDiv = this._player.el().parentNode;
    this._handleDocumentFullScreenExit = this._handleEscapeButtonFullScreenExit.bind(this);

    this._videoElement = this._player.el().querySelector('video');
    // On iPad we have to listen for webkitendfullscreen on the video element instead of the document.
    if (this._videoElement) this._videoElement.addEventListener('webkitendfullscreen', this._exitFullScreen.bind(this));
  }

  handleClick() {
    this._log('handleClick');
    if (!this._player.isFullscreen()) {
      this._enterFullScreen();
      document.addEventListener(fullScreenApi.fullscreenchange, this._handleDocumentFullScreenExit);
    } else {
      this._exitFullScreen();
    }
  }

  _enterFullScreen() {
    this._log('_enterFullScreen');
    try { // On iPad we can't fullscreen the parent div, but we don't have to either so ignore the errors.
      this._parentDiv[fullScreenApi.requestFullscreen]();
    } catch (e) { /* Do nothing */ }
    this._player.requestFullscreen();
    this._addClass(this._parentDiv, 'vjs-parent-fullscreen');
  }

  _exitFullScreen() {
    this._log('_exitFullScreen');

    // On iPad we can't exit fullscreen on the document node, but we don't have to either.
    if (document[fullScreenApi.exitFullscreen]) document[fullScreenApi.exitFullscreen]();

    this._player.exitFullscreen();
    this._removeClass(this._parentDiv, 'vjs-parent-fullscreen');

    document.removeEventListener(fullScreenApi.fullscreenchange, this._handleDocumentFullScreenExit);
  }

  _handleEscapeButtonFullScreenExit() {
    this._player.isFullscreen(document[fullScreenApi.fullscreenElement]);

    if (!this._player.isFullscreen()) {
      this._player.exitFullscreen();
      this._removeClass(this._parentDiv, 'vjs-parent-fullscreen');
    }
  }

  _addClass(elm, className) {
    if (!elm.classList.contains(className)) elm.classList.add(className);
  }

  _removeClass(elm, className) {
    if (elm.classList.contains(className)) elm.classList.remove(className);
  }

  _log(...args) {
    debugLog(`[#, ${this._player.id()}]`, ...args);
  }
}
