import vjs from 'video.js';

const Component = vjs.getComponent('Component');

export default vjs.extend(Component, {
  createEl: function createEl() {
    return Component.prototype.createEl.call(this, 'div', {
      className: 'ad-transition-background',
    });
  },
});
