import videojs from 'video.js';
import debug from 'debug';

// Setup debugging
const debugLog = debug('MtgxSkinPlugin:SharingScreen');

export default class SharingScreen {
  constructor(player, options) {
    this._player = player;
    this._options = options;

    this._init();
  }

  _init() {
    this._log('_init');
    this._player.on('showSharingScreen', () => {
      this._log('showSharingScreen');

      this._player.trigger('showOverlayMessage', {
        closeCallback: () => {
          this._log('hideSharingScreen');
          this._player.trigger('hideOverlayBlur');
        },
        closeable: true,
        blur: true,
        onlyOnce: false,
        renderMessageComponent: this._renderSharingOverlay.bind(this),
        className: 'sharing',
      });

      this._player.on('dispose', () => this._dispose.bind(this));
    });
  }

  _renderSharingOverlay(parent) {
    if (this._options.name === 'viafree' || this._options.name === 'viasport') {
      this._renderViafreeSharingOverlay(parent);
    } else {
      this._renderDefaultSharingOverlay(parent);
    }
  }

  _renderViafreeSharingOverlay(parent) {
    this._addSharingOverlay(parent);

    if (this._options.title || this._options.formatTitle) {
      let title = '';
      if (this._options.formatTitle) {
        title = this._options.formatTitle;
        if (this._options.videoTitle) title += ` - ${this._options.videoTitle}`;
      } else if (this._options.title) {
        title = this._options.title;
      }

      this._addTitle(this._sharingOverlay, title);

      if (this._options.sharing.socialMediaProviders &&
        this._options.sharing.socialMediaProviders.length > 0) {
        this._addSocialMediaLinks(
          this._sharingOverlay,
          this._options.sharing.socialMediaProviders,
          this._options.sharing.canonicalUrl,
          title,
          true,
        );
      }
    }

    if (this._options.sharing.embedUrl) {
      this._addEmbedCode(this._sharingOverlay);
    }
  }

  _renderDefaultSharingOverlay(parent) {
    this._addSharingOverlay(parent);
    if (this._options.sharing.embedUrl) {
      this._addEmbedButton(this._sharingOverlay);
    }

    if (this._options.sharing.canonicalUrl) {
      this._addCanonicalUrlField(this._sharingOverlay, this._options.sharing.canonicalUrl);

      if (this._options.title && // Title is needed for most social media sharing integrations
        this._options.sharing.socialMediaProviders &&
        this._options.sharing.socialMediaProviders.length > 0) {
        this._addSocialMediaLinks(
          this._sharingOverlay,
          this._options.sharing.socialMediaProviders,
          this._options.sharing.canonicalUrl,
          this._options.title
        );
      }
    }
  }

  _addSharingOverlay(parent) {
    this._sharingOverlay = parent.addChild(name, {
      componentClass: 'Component',
      el: videojs.getComponent('Component').prototype.createEl('div', {
        className: 'x-sharing-overlay',
      }),
    });
  }

  _addTitle(parent, title) {
    const titleWrapper = document.createElement('div');
    titleWrapper.setAttribute('class', 'title');
    titleWrapper.innerHTML = title;

    parent.el().appendChild(titleWrapper);
  }

  _addEmbedCode(parent) {
    const embedLinkWrapper = document.createElement('div');
    const playerUrl = this._options.sharing.embedUrl || window.location.href;
    const iframeHTMLEncoded = `&lt;iframe width=&quot;560&quot; height=&quot;315&quot; src=&quot;${
      playerUrl}&quot; seamless=&quot;seamless&quot; allowfullscreen&gt;&lt;/iframe&gt;`;

    embedLinkWrapper.className += 'embed-wrapper ';
    embedLinkWrapper.innerHTML = `<div class="embed-label">${this._player.localize('Embed Video')}:</div>`;
    embedLinkWrapper.innerHTML += `<input id="embed-link-input" readonly
      value="${iframeHTMLEncoded}"/>`;

    parent.el().appendChild(embedLinkWrapper);

    document.getElementById('embed-link-input').addEventListener('click', event => event.target.select());
  }

  _addEmbedButton(parent) {
    const embedLinkWrapper = document.createElement('div');
    const playerUrl = this._options.sharing.embedUrl || window.location.href;
    const iframeHTMLEncoded = `&lt;iframe width=&quot;460&quot; height=&quot;284&quot; src=&quot;${
      playerUrl}&quot; seamless=&quot;seamless&quot; allowfullscreen&gt;&lt;/iframe&gt;`;

    embedLinkWrapper.className += 'embed-wrapper ';
    embedLinkWrapper.innerHTML = `<span id="embed-btn">${this._player.localize('Embed Video')}</span>`;
    embedLinkWrapper.innerHTML += `<input id="embed-link-input" class="vjs-hidden" readonly
      value="${iframeHTMLEncoded}"/>`;

    parent.el().appendChild(embedLinkWrapper);

    document.getElementById('embed-btn').addEventListener('click', function onClick() {
      this.className = 'vjs-hidden';
      document.getElementById('embed-link-input').className = '';
    });
    document.getElementById('embed-link-input').addEventListener('click', event => event.target.select());
  }

  _addCanonicalUrlField(parent, canonicalUrl) {
    const canonicalUrlWrapper = document.createElement('div');

    canonicalUrlWrapper.className += 'canonical-url-wrapper';
    canonicalUrlWrapper.innerHTML = `<input id="canonical-url-input" readonly value="${canonicalUrl}"/>`;

    parent.el().appendChild(canonicalUrlWrapper);

    document.getElementById('canonical-url-input').addEventListener('click', event => event.target.select());
  }

  _addSocialMediaLinks(parent, providers, url, title, addLabel) {
    const socialMediaButtons = document.createElement('div');
    const urlEncodedTitle = encodeURIComponent(title);
    const urlEncodedUrl = encodeURIComponent(url);

    if (addLabel) {
      const labelWrapper = document.createElement('div');
      labelWrapper.setAttribute('class', 'social-media-label');
      labelWrapper.innerHTML = `${this._player.localize('Share on social media')}:`;

      parent.el().appendChild(labelWrapper);
    }

    socialMediaButtons.className += 'social-media-btns';

    socialMediaButtons.onclick = (event) => {
      window.open(event.target.href, '', 'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=300,width=600');
      return false;
    };

    if (providers.indexOf('facebook') > -1) {
      socialMediaButtons.innerHTML += `<a class="facebook-link" target="_blank" href=
  "http://www.facebook.com/sharer/sharer.php?u=${urlEncodedUrl}&title=${urlEncodedTitle}"></a>`;
    }

    if (providers.indexOf('twitter') > -1) {
      socialMediaButtons.innerHTML += `<a class="twitter-link" target="_blank" href=
  "http://twitter.com/intent/tweet?status=${urlEncodedUrl}+${urlEncodedTitle}"></a>`;
    }

    if (providers.indexOf('googleplus') > -1) {
      socialMediaButtons.innerHTML += `<a class="googleplus-link" target="_blank" href=
  "https://plus.google.com/share?url=${urlEncodedUrl}"></a>`;
    }

    if (providers.indexOf('draugiem') > -1) {
      socialMediaButtons.innerHTML += `<a class="draugiem-link" target="_blank" href=
  "http://www.draugiem.lv/say/ext/add.php?title=${urlEncodedTitle}&link=${urlEncodedUrl}"></a>`;
    }

    parent.el().appendChild(socialMediaButtons);
  }

  _dispose() {
    this._player.trigger('hideOverlayMessage');
  }

  _log(...args) {
    debugLog(`[#${this._player.id()}]`, ...args);
  }
}
