import React from 'react';
import debug from 'debug';

const debugLog = debug('chromecastUI:log');

class SubtitlesMenu extends React.Component {
  constructor(props, context) {
    super(props, context);
    debugLog('Constructing react SubtitleMenu', this.props);
    this.state = {
      loadingTrackId: null,
    };
  }

  /** Assume props
   */

  changeSubtitles(trackId) {
    this.setState({
      loadingTrackId: trackId,
    });
    this.props.playerAction.changeSubtitles(trackId);
  }

  toggleSubtitlesMenu() {
    this.props.toggleSubtitlesMenu();
  }

  render() {
    return (
      <div className="vjs-overlay-message-overlay subtitle-menu chromecast-control-subtitles-menu">
        <div className="vjs-overlay-message-label">
          <div className="x-subtitle-menu-overlay">
            <div className="">{this.props.subtitlesLabel}</div>
            {this.props.tracks && this.props.tracks.length && <ol>
              <li // eslint-disable-line jsx-a11y/no-static-element-interactions
                onClick={() => this.changeSubtitles(0)}
                className={`${this.props.selectedTrackId === 0 ? 'selected' : ''}`}
              >
                {this.props.subtitlesOff}
                {this.state.loadingTrackId === 0 && this.props.selectedTrackId !== 0 && <div
                  className="chromecast-ui-loader"
                />}
              </li>
              {this.props.tracks.map(track =>
                <li // eslint-disable-line jsx-a11y/no-static-element-interactions
                  key={track.trackId}
                  className={`${this.props.selectedTrackId === track.trackId ? 'selected' : ''}`}
                  onClick={() => this.changeSubtitles(track.trackId)}
                >
                  {track.name}
                  {this.state.loadingTrackId === track.trackId && this.props.selectedTrackId !== track.trackId && <div
                    className="chromecast-ui-loader"
                  />}
                </li>) }
            </ol>
            }
            <div // eslint-disable-line jsx-a11y/no-static-element-interactions
              className="vjs-overlay-message-close-button"
              type="button"
              role="button"
              onClick={() => this.toggleSubtitlesMenu()}
            >X
              <span className="vjs-control-text" />
            </div>
          </div>
        </div>
      </div>);
  }
}

export default SubtitlesMenu;
