import React from 'react';
import debug from 'debug';

const debugLog = debug('chromecastUI:log');

class SubtitlesButton extends React.Component {
  constructor(props, context) {
    super(props, context);
    debugLog('Constructing react SubtitleButton', this.props);
  }

  /** Assume props
   */

  toggleSubtitlesMenu() {
    this.props.toggleSubtitlesMenu();
  }

  render() {
    return (
      <button
        className="vjs-caption-toggle-control vjs-control vjs-selected"
        onClick={() => this.toggleSubtitlesMenu()}
      />);
  }
}

export default SubtitlesButton;
