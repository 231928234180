import videojs from 'video.js';
import debug from 'debug';

// Setup debugging
const debugLog = debug('Countdown');

const DEFAULT_OPTIONS = {
  template: null,
  placeholder: 'HHMMSS',
  containerClass: 'countdown-container',
  getCurrentTime: Date.now,
};

export default class CountdownPlugin {
  constructor(player, options) {
    if (!options) throw new Error('no options provided');
    if (!options.until) throw new Error('no until provided');

    this._player = player;
    this._options = videojs.mergeOptions(DEFAULT_OPTIONS, options);

    if (!this._options.template) {
      this._options.template = `
        <div class="countdown-timer">
        <h1 class="title">localizeText</h1> 
        <span class="digits">HHMMSS</span>
        </div>`;
    }

    this._init();
  }

  _init() {
    this._calculateSecondsToCount();

    if (this._shouldShowCountdown()) {
      this._startCountdown();
    }
  }

  _startCountdown() {
    this._log(`Starting a countdown for ${this._getCountdownTime()}`);
    // pause asap, we dont want video to play until countdown is finished
    this._fakePlayHandler = this._onPlay.bind(this);
    this._player.trigger({
      type: 'registerVideoStartBehavior',
      name: 'COUNTDOWN',
      playHandler: this._fakePlayHandler,
    });

    this._addVisualTimer();

    this._countdownInterval = setInterval(this._timerCallback.bind(this), 200);
    this._timerCallback();

    this._player.trigger('showCountdown');
  }

  _endCountdown() {
    this._player.trigger('hideCountdown');

    this._removeVisualTimer();

    this._player.trigger({
      type: 'removeVideoStartBehavior',
      playHandler: this._fakePlayHandler,
      forcePlay: true,
    });
  }

  _onPlay() {
    this._log('Fake play function: Blocking play until countdown is done');
  }

  _addVisualTimer() {
    this._wrapperDiv = document.createElement('div');
    this._wrapperDiv.className = this._options.containerClass;
    this._wrapperDiv.addEventListener('click', (evt) => {
      // This is to avoid click events being bubbled to
      // the video object and trigger a click through on the preroll
      // Happens when autoplay is activated
      evt.stopPropagation();
    });
    this._player.el().appendChild(this._wrapperDiv);
  }


  _timerCallback() {
    this._calculateSecondsToCount();

    if (this._shouldShowCountdown()) {
      this._updateVisualTimer();
    } else {
      this._endCountdown();
    }
  }

  _removeVisualTimer() {
    clearInterval(this._countdownInterval);
    this._player.el().removeChild(this._wrapperDiv);
  }

  _updateVisualTimer() {
    const timerText = this._getCountdownTime();
    let updatedTemplate = this._options.template.replace(this._options.placeholder, timerText);
    const localize = this._inDayTime ? 'The program starts in' : 'The program starts';
    updatedTemplate = updatedTemplate.replace('localizeText', this._player.localize(localize));
    this._wrapperDiv.innerHTML = updatedTemplate;
  }

  _shouldShowCountdown() {
    return this._secondsLeft > 0;
  }

  _calculateSecondsToCount() {
    const untilDateInSeconds = new Date(this._options.until).getTime() / 1000;
    const currentDateInSeconds = this._getCurrentTimestamp();
    this._secondsLeft = untilDateInSeconds - currentDateInSeconds;
  }

  _getCurrentTimestamp() {
    return this._options.getCurrentTime() / 1000;
  }

  _getCountdownTime() {
    let hours = Math.floor(this._secondsLeft / 3600);

    if (hours > 24) {
      const date = new Date(this._options.until);
      const month = date.toLocaleString('en-us', { month: 'short' }).toLowerCase();
      const minutes = (date.getMinutes() < 10) ? `0${date.getMinutes()}` : date.getMinutes();
      const dateHour = (date.getHours() < 10) ? `0${date.getHours()}` : date.getHours();
      this._inDayTime = false;
      return `${date.getDate()} ${month} ${dateHour}:${minutes}`;
    }

    let minutes = Math.floor((this._secondsLeft - (hours * 3600)) / 60);
    let seconds = Math.floor(this._secondsLeft - (hours * 3600) - (minutes * 60));
    this._inDayTime = true;

    if (hours < 10) hours = `0${hours}`;
    if (minutes < 10) minutes = `0${minutes}`;
    if (seconds < 10) seconds = `0${seconds}`;

    return `${hours}:${minutes}:${seconds}`;
  }

  _log(...args) {
    debugLog(`[#${this._player.id()}]`, ...args);
  }
}

// only instantiate the plugin if it's in a AMD module or not under test
videojs.plugin('countdown', function init(options) {
  this.countdown = new CountdownPlugin(this, options);
});
