import React from 'react';
import debug from 'debug';

const debugLog = debug('chromecastUI:log');

class TitleBars extends React.Component {
  constructor(props, context) {
    super(props, context);
    debugLog('Constructing react titlebars', this.props);

    /** Assume props
     string this.props.title
     string this.props.subtitle
     string linkUrl
     */
  }
  render() {
    return (
      <a href={this.props.linkUrl}>
        <div className="title-bars">
          <div className="title">{this.props.title}</div>
          <div
            className="subtitle"
            dangerouslySetInnerHTML={{ __html: this.props.subtitle }} // eslint-disable-line react/no-danger
          />
        </div>
      </a>
    );
  }
}

export default TitleBars;
