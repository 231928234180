import window from 'global/window';
import document from 'global/document';
import CastPlayer from './castPlayer';

/**
 * Note that window.receiverAppId should be set first thing in the application for
 * Chromecast to work
 * */

const castPlayer = new CastPlayer();
window.castPlayer = castPlayer;
const senderScript = document.createElement('script');
senderScript.src = 'https://www.gstatic.com/cv/js/sender/v1/cast_sender.js?loadCastFramework=1';
window.__onGCastApiAvailable = castPlayer.initializeCastPlayer.bind(castPlayer);

document.head.appendChild(senderScript);

export default castPlayer;

