import debug from 'debug';
import vjs from 'video.js';

// Setup debugging
const debugLog = debug('MtgxSkinPlugin-SubtitleMenuButton');

const VjsButton = vjs.getComponent('Button');

export default class SubtitleMenuButton extends VjsButton {
  constructor(player, options) {
    super();

    this.language_ = options.language;
    this._player = player;

    this._player.textTracks().addEventListener('change', this._updateUI.bind(this));

    this._updateUI();
  }

  createEl() {
    return VjsButton.prototype.createEl.call(this, 'div', {
      className: 'vjs-caption-toggle-control vjs-control',
      role: 'button',
      'aria-live': 'polite',
      innerHTML: '<div class="vjs-control-content"><span class="vjs-control-text">Toggle Captions</span></div>',
    });
  }

  handleClick() {
    this._player.trigger('showSubtitleMenu');
  }

  _updateUI() {
    if (!this._player.hasAvailableTextTracks()) return;
    const tracks = this._player.getAvailableTextTracks();

    this._isShowing = tracks.filter(textTrack => textTrack.mode === 'showing').length;

    if (this._isShowing) {
      this.addClass('vjs-selected');
    } else {
      this.removeClass('vjs-selected');
    }
  }

  _log(...args) {
    debugLog(`[#, ${this._player.id()}]`, ...args);
  }
}

