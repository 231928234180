import debug from 'debug';
import videojs from 'video.js';

// Setup debugging
const debugLog = debug('VisualAdCues');

const DEFAULT_OPTIONS = {};

const VjsComponent = videojs.getComponent('Component');

class VisualAdCues extends VjsComponent {
  createEl() {
    const cuepointDivs = this.options_.cuepoints
      .map(c => `<div class="vjs-visual-cuepoint" style="left:${c}%"></div>`).join('');

    const props = {
      className: 'vjs-cuepoint-container',
      innerHTML: cuepointDivs,
    };

    const attributes = {};

    return VjsComponent.prototype.createEl('div', props, attributes);
  }
}

export default class VisualAdCuesPlugin {
  constructor(player, incomingOptions) {
    this._player = player;
    this._options = videojs.mergeOptions(DEFAULT_OPTIONS, incomingOptions);

    this._log('options', this._options);

    if (!incomingOptions) throw new Error('no options provided');

    this._player.one('adSlotsCreated', this._waitForMediaStartToHandleCuepoints.bind(this));
    this._player.ready(() => {
      player.el().classList.add('vjs-visual-ad-cues');
    });
  }

  _waitForMediaStartToHandleCuepoints(data) {
    this._player.one('mediaStart', () => this._handleVisualCuepoints(data));
  }

  _handleVisualCuepoints(data) {
    if (this._player.duration() && this._player.duration() !== Infinity) {
      const cuepoints = data.slots
        .filter(slot => slot.type === 'MIDROLL')
        .map(slot => (slot.time / this._player.duration()) * 100)
        .filter(percent => percent < 100);
      this._createComponent(cuepoints);
    } else {
      this._player.one('durationchange', () => this._handleVisualCuepoints(data));
    }
  }

  _createComponent(cuepoints) {
    videojs.registerComponent('VisualAdCues', VisualAdCues);
    this._player.controlBar.progressControl.seekBar.addChild('visualAdCues', {
      cuepoints,
    });
  }

  _log(...args) {
    debugLog(`[#${this._player.id()}]`, ...args);
  }
}

videojs.plugin('visualAdCues', function init(options) {
  this.visualAdCues = new VisualAdCuesPlugin(this, options);
});
