import videojs from 'video.js';
import debug from 'debug';

// Setup debugging
const debugLog = debug('EventsPlugin');

export default class EventsPlugin {
  constructor(player) {
    if (!player.cuepoints) throw new Error('events plugin needs cuepoints plugin to work correctly');

    this._player = player;

    this._adMode = false;
    this._seeking = false;
    this._lastTimeUpdate = false;
    this._lastTimeUpdateBeforeJump = false;
    this._shouldWaitForPostroll = false;
    this._hasEnded = false;
    this._hasMediaStarted = false;
    this._buffering = false;

    this._initListeners();
  }

  _initListeners() {
    this._player.one('play', this._onFirstPlay.bind(this));
    this._player.on('pause', this._onPause.bind(this));
    this._player.on('waiting', this._onBuffer.bind(this));
    this._player.on('seeking', this._onSeek.bind(this));
    this._player.on('seeked', this._onSeekComplete.bind(this));
    this._player.on('fullscreenchange', this._onFullscreenChange.bind(this));
    this._player.on('volumechange', this._onVolumeChange.bind(this));
    this._player.on('ended', this._onEnd.bind(this));
    this._player.on('error', this._onError.bind(this));
    this._player.on('timeupdate', this._onTimeUpdate.bind(this));
    this._player.on('mediaStart', this._setUpProgressCuepoints.bind(this));
    this._player.on('adRollEnd', this._onAddRollEnd.bind(this));

    window.onunload = this._onUnload.bind(this);

    this._player.on('adRequestSucceeded', () => { this._shouldWaitForPostroll = true; });
    this._player.on('adRequestFailed', () => { this._shouldWaitForPostroll = false; });
    this._player.on('adRollStart', () => { this._adMode = true; });
    this._player.on('adRollEnd', () => { this._adMode = false; });

    this._player.ready(() => {
      this._hasEnded = false;
      this._hasMediaStarted = false;
      const controlBar = this._player.getChild('controlBar');
      const playToggle = controlBar && controlBar.getChild('playToggle');
      const bigPlayButton = this._player.getChild('bigPlayButton');

      if (this._player.autoplay() || this._player.hasStarted() || this._player.env.chromecast) {
        this._trigger('controlStart');
      } else {
        bigPlayButton.one('touchend', (event) => {
          event.preventDefault();
          event.stopPropagation();
          this._trigger('controlStart');
          this._player.play();
        });
        bigPlayButton.one('click', (event) => {
          event.preventDefault();
          event.stopPropagation();
          this._trigger('controlStart');
        });
      }

      if (playToggle) {
        playToggle.on(['touchend', 'click'], this._onTogglePlay.bind(this));
      }
    });
  }

  _trigger(evt) {
    this._log('Trigger:', evt);

    this._player.trigger(evt);
  }

  _onPlay() {
    if (this._adMode) return;

    this._hasEnded = false;

    if (this._hasMediaStarted === false) {
      return;
    }

    // On Firefox and Safari, when seek is triggered, the video element fires 'pause' and 'play' at the exact same time
    // then 'seek'. This is a fix to avoid firing mediaPause when that happens.
    // Here, we check if there was a pause event that happened within a few milliseconds of the play event.
    // If so, we do not fire the event
    const currentTime = this._player.currentTime();
    if (this._mediaPauseTime > 0 &&
      (currentTime - this._mediaPauseTime) >= 0 &&
      (currentTime - this._mediaPauseTime) < 0.100) {
      this._log('Ignore mediaPause/mediaPlay sequence as they are too close to each other');
      this._mediaPlayIgnored = true;
    } else {
      this._mediaPauseTime = -1;
      this._trigger('mediaPlay');
    }
  }

  _onPause() {
    if (this._adMode) return;

    // We wait 200ms after the pause event was fired, if there was a play event that was ignored after
    // it, then we ignore it. See comment above in onPlay().
    this._mediaPauseTime = this._player.currentTime();
    setTimeout(() => {
      if (this._hasEnded) return;
      if (this._mediaPlayIgnored) {
        this._mediaPlayIgnored = false;
      } else {
        this._trigger('mediaPause');
      }
      this._mediaPauseTime = -1;
    }, 200);
  }

  _onBuffer() {
    if (this._adMode) return;
    this._onMediaBuffer();
    this._trigger('mediaBuffer');
  }

  _onMediaBuffer() {
    const time = this._player.currentTime();

    if (!time || this._seeking || this._hasEnded) {
      return;
    }

    // If current state is adEnd then we are resuming play after a midroll, we should not send a
    // buffer event before starting the vod
    if (!this._adMode) {
      this._log(`mediaBufferStart -> BUFFER: ${time}`);
      this._trigger('mediaBufferStart');
    }
    this._buffering = true;
    const checkForBufferEnd = () => {
      if (this._buffering && this._player.currentTime() !== time) {
        this._buffering = false;
        this._log('mediaBufferEnd ->', this._player.currentTime());
        this._trigger('mediaBufferEnd');
        this._player.off('timeupdate', checkForBufferEnd);
      }
    };
    this._player.on('timeupdate', checkForBufferEnd);
  }

  _onSeek() {
    if (this._adMode) return;
    this._hasEnded = false;
    if (!this._seeking) {
      // Normally lastTimeUpdate gives us the time before seeking (and player.currentTime() gives us the time after)
      // But this is a fix for browsers (e.g. safari) that trigger time updates with the after seek time before
      // the onSeek event Basically we save the time before any big time jump in the onTimeUpdate event handler
      let beforeSeekTime = this._lastTimeUpdate;
      if (this._lastTimeUpdate === this._player.currentTime()) {
        // since last time update is equal to current time it means that, there was a time update
        // after seeking. use the last saved media time instead
        beforeSeekTime = this._player.getMediaCurrentTime() || 0;
      }

      this._seeking = { beforeSeekTime };
      this._trigger({
        type: 'mediaSeekStart',
        beforeSeekTime,
      });
    }

    this._trigger('mediaSeek');
  }

  _onSeekComplete() {
    if (this._adMode) return;
    if (!this._seeking) return;

    this._trigger({
      type: 'mediaSeekComplete',
      beforeSeekTime: this._seeking.beforeSeekTime,
      afterSeekTime: this._player.currentTime(),
    });
    this._seeking = false;
  }

  _onFirstPlay() {
    const firstPlayHandler = () => {
      // If it's a live stream run _onPlay now to trigger mediaStart because _setMediaStartCuepoint won't get triggered.
      setTimeout(() => {
        // TODO: Timeout is a Temporary solution. It still sometimes does not get fired
        if (this._player.duration() === Infinity) {
          this._onPlay();
        }
      }, 500);

      this._player.on('play', this._onPlay.bind(this));
    };

    if (this._player.connectivity) {
      this._player.connectivity.one(this._player.connectivity.NO_AD_BLOCKER, firstPlayHandler);
    } else {
      firstPlayHandler();
    }
  }

  _onFullscreenChange() {
    if (this._player.isFullscreen()) this._trigger('fullscreenEnter');
    else this._trigger('fullscreenClose');
  }

  _onVolumeChange() {
    this._trigger('volumeChange');
  }

  _onEnd() {
    if (this._adMode || this._hasEnded) return;
    this._hasEnded = true;

    this._trigger('mediaEnd');
    this._trigger('mediaEnded');

    // if FreeWheel didn't load, or failed loading, we trigger controlEnded right away
    // otherwise we should try to wait for a postroll to start
    if (this._shouldWaitForPostroll) {
      // wait for a postroll to start, if it hasn't after a short delay assume there is none
      const waitForPostrollTimeout = setTimeout(() => {
        this._trigger('controlEnded');
      }, 100);

      // wait for the postroll to end before we trigger
      this._player.one('adPostrollStart', () => {
        clearTimeout(waitForPostrollTimeout);

        this._player.one('adPostrollEnd', () => {
          this._trigger('controlEnded');
        });
      });
    } else {
      this._trigger('controlEnded');
    }
  }

  _onUnload() {
    this._trigger('unload');
  }

  // player.currentTime() reports the afterSeekTime time if queried in onSeek
  // so we need to keep track on the beforeSeekTime manually here
  _onTimeUpdate() {
    if (!this._willSendMediaStart) {
      this._willSendMediaStart = true;

      // Make sure media start event fires at actual media start and not during ad playback
      const sendMediaStart = () => {
        if (
          this._player.isPlayingAd() ||
          this._player.duration() === 0
        ) {
          this._player.one('durationchange', sendMediaStart.bind(this));
          return;
        }
        if (this._adMode) { // _adMode is sometimes set slightly later so we'll wait for this to be false as well
          this._player.one('timeupdate', sendMediaStart.bind(this));
          return;
        }

        this._trigger('preMediaStart');
        this._trigger('mediaStart');
        this._hasMediaStarted = true;
      };
      sendMediaStart();
    }

    if (!this._hasMediaStarted) return;

    const currentTime = this._player.currentTime();

    // [PLP-8900] Sometimes the player gets stuck before the end event, so we need to check for
    // progress and force and timeupdate if needed.
    if (Math.abs(this._player.getMediaDuration() - currentTime) <= 1) {
      this._player.clearTimeout(this._pollForEnd);
      this._pollForEnd = this._player.setTimeout(() => {
        if (currentTime === this._player.currentTime() && !this._player.paused()) {
          this._log('Player stuck near the end, trying to recover');
          this._player.currentTime(this._player.getMediaDuration());
        }
      }, 200);
    }

    // If the time has jumped a lot it means that the user has scrubbed.
    // Store the lastTimeUpdateBeforeJump so we can use it for browsers that report after-seek time update before
    // the seek event is fired
    if (
      !this._player.isPlayingAd() &&
      Math.abs(currentTime - this._lastTimeUpdate) > 2
    ) {
      this._lastTimeUpdateBeforeJump = this._lastTimeUpdate;
    }

    this._lastTimeUpdate = currentTime;
  }

  _onProgress(cuepoint, name, params) {
    if (this._adMode || name !== 'percentage') return false;

    this._trigger({ type: 'mediaProgress', percentage: params.percentage });
    return true;
  }

  _setUpProgressCuepoints() {
    if (this._adMode) return;

    for (let i = 5; i <= 100; i += 5) {
      this._trigger({
        type: 'addCuepoint',
        time: this._player.duration() * (i / 100),
        name: 'percentage',
        callback: this._onProgress.bind(this),
        data: { percentage: i },
      });
    }
  }

  _onTogglePlay() {
    if (this._player.paused()) this._trigger('controlPause');
    else this._trigger('controlPlay');
  }

  _onAddRollEnd() {
    this._player.one('timeupdate', () => this._trigger('firstTimeUpdateAfterAdEnd'));
  }

  _onError() {
    if (this._player.src() === this._getCurrentUrl()) {
      // When we empty the source in order to load a new source for ads, firefox
      // fires some errors. We need to ignore these errors since they do not affect
      // the playback
      return;
    }
    if (!this._adMode) {
      this._log('mediaError');
      this._trigger('mediaError');
    } else {
      this._log('adError', this._player.src());
      this._trigger({
        type: 'adError',
        src: this._player.src(),
      });
    }
  }

  _getCurrentUrl() {
    return window.location.href;
  }

  _log(...args) {
    debugLog(`[#${this._player.id()}]`, ...args);
  }
}

videojs.plugin('events', function init(options) {
  this.events = new EventsPlugin(this, options);
});
