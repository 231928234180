import vjs from 'video.js';

const DEFAULT_OPTIONS = {
  additionalPackage: [],
  treeId: [],
};

// gemius script tag is loaded on sites that use it
const gemius = window.gemiusStream;

export default class GemiusPlugin {
  constructor(player, options) {
    if (!gemius) {
      this._log('Gemius script not loaded');
      return;
    }
    this.player = player;
    this._options = vjs.mergeOptions(DEFAULT_OPTIONS, options);

    const customPackage = [
      { name: 'gA', value: this._options.formatTitle },
      { name: 'CNAME', value: this._options.title },
      { name: 'CATEGORY', value: this._options.category },
    ];

    this.setupListeners(customPackage, this._options);
  }

  setupListeners(customPackage, { materialIdentifier, additionalPackage, identifier, hitCollector, treeId }) {
    const { player } = this;
    const playerId = this.getPlayerId();
    const gemiusEvent = gemius.event.bind(gemius, playerId, materialIdentifier);
    player.on('mediaStart', () => {
      const duration = player.duration();
      gemius.newStream(playerId,
        materialIdentifier,
        duration,
        customPackage,
        additionalPackage,
        identifier,
        hitCollector,
        treeId);

      window.addEventListener('unload', () => gemius.closeStream(playerId, materialIdentifier, player.currentTime()));
    });

    player.on(['mediaStart', 'mediaPlay'], () => gemiusEvent(player.currentTime(), 'playing'));
    player.on('mediaPause', () => gemiusEvent(player.currentTime(), 'paused'));
    player.on('mediaSeek', () => gemiusEvent(player.currentTime(), 'seekingStarted'));
    player.on('mediaEnd', () => gemiusEvent(player.currentTime(), 'complete'));
  }

  getPlayerId() {
    if (!this.playerId) this.playerId = `player${Math.round(Math.random() * 1000000)}`;
    return this.playerId;
  }
}

vjs.plugin('gemius', function init(options) {
  this.gemius = new GemiusPlugin(this, options);
});
