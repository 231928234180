import UAParser from 'ua-parser-js'; // https://www.npmjs.com/package/ua-parser-js

// Chromecast is not included as of v0.7.12 but will be included in the following version
const chromecastPattern = [
  [/(CrKey)\/([\w\.]+)/i],
  [[UAParser.DEVICE.MODEL, 'Chromecast'], 'version', [UAParser.DEVICE.VENDOR, 'Google']],
];
const uaParser = new UAParser(window.navigator.userAgent, { device: chromecastPattern }).getResult();

const C = {
  // Browsers
  CHROME: /Chrome|Chromium/,
  EDGE: 'Edge',
  FIREFOX: 'Firefox',
  IE: /IE/, // IE [Mobile]
  SAFARI: /Safari/, // [Mobile] Safari

  // OS
  ANDROID: 'Android',
  IOS: 'iOS',
  MACOS: 'Mac OS',
  WINDOWS: /Windows/, // Windows [Phone/Mobile]

  // Devices
  CHROMECAST: 'Chromecast',
  IPHONE: 'iPhone',

  // Platforms
  MOBILE: 'mobile',
  TABLET: 'tablet',
};

const env = {
  // Browsers
  browserVersion: String(uaParser.browser.version || '').split('.').map(v => parseInt(v, 10)),
  chrome: !!uaParser.browser.name.match(C.CHROME),
  edge: uaParser.browser.name === C.EDGE,
  firefox: uaParser.browser.name === C.FIREFOX,
  ie: !!uaParser.browser.name.match(C.IE),
  safari: !!uaParser.browser.name.match(C.SAFARI),

  // OS
  osVersion: String(uaParser.os.version || '').split('.').map(v => parseInt(v, 10)),
  android: uaParser.os.name === C.ANDROID,
  iOS: uaParser.os.name === C.IOS,
  mac: uaParser.os.name === C.MACOS,
  windows: !!uaParser.browser.name.match(C.WINDOWS),

  // Devices
  chromecast: uaParser.device.model === C.CHROMECAST,
  iPhone: uaParser.device.model === C.IPHONE,

  // Platforms
  mobile: uaParser.device.type === C.MOBILE,
  tablet: uaParser.device.type === C.TABLET,

  uaParser,
};

if (env.chromecast) env.android = false;

env.desktop = !env.mobile && !env.tablet; // Will include smarttv, console, etc

env.osVersion.major = env.osVersion[0] || 0;
env.osVersion.minor = env.osVersion[1] || 0;
env.osVersion.patch = env.osVersion[2] || 0;

env.browserVersion.major = env.browserVersion[0] || 0;
env.browserVersion.minor = env.browserVersion[1] || 0;

/*
 * [VIP-15941][PLP-8161] Returns true if the browser support native HLS or VideoJS contrib HLS.
 */
function isBrowserSupported() {
  // Windows Vista === version 6.0
  // Windows 7     === version 6.1
  // Windows 8     === version 6.2
  // Windows 8.1   === version 6.3
  // Windows 10    === version 10

  // FF < 42
  if (env.firefox && env.browserVersion.major < 42) return false;

  // IE <= IE11
  if (env.ie && env.browserVersion.major <= 11) return false;

  // Windows Phone 8.1
  if (env.mobile && env.isWindows && env.osVersion.major <= 8) return false;

  // Safari on OSX Mavricks (10.9)
  // [VIP-25290] Safari bugs out in mavricks when doing mid-rolls
  if (env.mac && env.safari && env.osVersion.major === 10 && env.osVersion.minor <= 9) return false;

  return true;
}

export default {
  isBrowserSupported,
  env,
};
