import vjs from 'video.js';
import debug from 'debug';
import window from 'global/window';
import browserUtils from '../../../src/util/browser'; // We need this before avod player sets it to the vjs object
import OverlayComponent from './components/overlay';
import AdCountdownComponent from './components/ad-countdown';
import AdClickThroughComponent from './components/ad-click-through';
import TransitionBackgroundComponent from './components/transition-background';
import CTAOverlayComponent from './components/cta-overlay';
import DisplayAdHandler from './display-ad-handler';
import AdManagerHandler from './ad-manager-handler';
import PauseAdHandler from './pause-ad-handler';
import fwgw from './freewheel-sdk-wrapper';

// Setup debugging
const debugLog = debug('fw:log');
const errorLog = debug('fw:error');

const DEFAULT_OPTIONS = {
  adManagerUrl: 'https://mssl.fwmrm.net/p/MTG_Brightcove_HTML5/AdManager.js',
  autoplay: undefined,
  unintendedAutostart: true,
  showPrerolls: true,
  showMidrolls: false,
  showPostrolls: true,
  showOverlays: true,
  showDisplayAds: true,
  midrollCuepoints: null,
  showAdCountdown: true,
  adRequestKeyValues: {},
  setMidrollSequence: false,
  videoStartDetectTimeout: 4000,
  videoProgressDetectTimeout: 4000,
  videoLiveDuration: 0,
  pauseAdDelay: 0,
  pauseAdLargeClickArea: false,
};

/**
 * Global method to create elements for freewheel display ads.
 *
 * If the content is a full HTML page or if we get an URL to the HTML we will render the ad in an iframe to make the
 * scripts work.
 * If we get partial HTML we will try to add the elements directly to our DOM to be able to resize it correctly.
 */
window.writeDisplay = DisplayAdHandler.writeDisplay;

export default class FreewheelPlugin {
  constructor(player, options) {
    if (!options) throw new Error('no options provided');
    if (!options.networkId) throw new Error('options.networkId needs to be specified');
    if (!options.videoAssetId) throw new Error('options.videoAssetId needs to be specified');
    if (!options.serverUrl) throw new Error('options.serverUrl needs to be specified');
    if (!options.profileId) throw new Error('options.profileId needs to be specified');
    if (!options.siteSectionId) throw new Error('options.siteSectionId needs to be specified');
    if (options.showMidrolls && !options.showLiveMidrolls && !options.midrollCuepoints) {
      throw new Error('options.midrollCuepoints needs to be specified if you want midrolls');
    }

    this._player = player;
    this._options = vjs.mergeOptions(DEFAULT_OPTIONS, options);
    if (!this._options.siteSectionNetworkId) this._options.siteSectionNetworkId = this._options.networkId;

    // consumer can indicate whether or not this should be seen as an autoplay or not,
    // if not declared look at autoplay attribute instead
    this._options.autoplay = typeof options.autoplay !== 'undefined' ? options.autoplay : player.autoplay();

    if (this._options.liveMidrolls && this._options.liveMidrolls.showLiveMidrolls) this._options.showMidrolls = true;

    this._logLevel = debug.enabled() ? 'LOG_LEVEL_DEBUG' : 'LOG_LEVEL_QUIET';

    // Disable ads for some OS/Browser combinations since it doesn't work well
    this._env = browserUtils.env;

    this._platformSpecificConfig();

    this._log('FreeWheelPlugin options', options);

    // Keep track of if the player tries to start before prerolls are ready to play
    this._playWasRequested = false;

    // Timeout for resetting _play- and _pauseEvent
    this._pTimeout = null;

    this._slots = {};

    // keep track of finished slots, so we don't play them again (midrolls/overlays in particular)
    this._finishedSlots = [];

    this._currentVideoAd = null;
    this._currentOverlay = null;
    this._positionInSlot = 0;

    this._currentMidrollPosition = 0;

    // add overlay and ad countdown components to the player component
    vjs.registerComponent('FWOverlay', OverlayComponent);
    vjs.registerComponent('FWAdCountdown', AdCountdownComponent);
    vjs.registerComponent('FWTransitionBackground', TransitionBackgroundComponent);
    vjs.registerComponent('FWCTAButton', CTAOverlayComponent);
    vjs.registerComponent('FWAdClickThrough', AdClickThroughComponent);

    this._player.addChild('FWOverlay');
    this._player.addChild('FWAdCountdown');
    this._player.addChild('FWCTAButton');
    this._player.addChild('FWAdClickThrough');

    this._player.addChild('FWTransitionBackground');

    AdManagerHandler.load(
      this._options.adManagerUrl,
      this._logLevel,
      this._onAdManagerLoaded.bind(this),
      () => {
        this._player.ready(() => this._onRequestComplete({ success: false }));
      }
    );

    // clean up if the player object is disposed
    this._player.on('dispose', this._onDispose.bind(this));

    // if prerolls are enabled, make sure we hide the controls (css) and keep the player paused until ads are loaded
    if (this._options.showPrerolls) this._replacePlaybackBehavior(() => this._fakePlay());

    this._fixAdClickBehavior();
    if (this._options.useSoundThresholdFilter) this._setupSoundThresholdFilter();

    if (this._options.liveMidrolls && this._options.liveMidrolls.showLiveMidrolls) this._liveMidrolls();

    if (this._options.showPauseAds) new PauseAdHandler(this._options, this._player);
  }

  _replacePlaybackBehavior(onPlayCallback) {
    this._onPlayCallback = onPlayCallback.bind(this);

    this._trigger({
      type: 'registerVideoStartBehavior',
      name: 'FREEWHEEL',
      playHandler: this._onPlayCallback,
    });
  }

  _restorePlaybackBehavior() {
    if (!this._onPlayCallback) return;

    this._trigger({
      type: 'removeVideoStartBehavior',
      playHandler: this._onPlayCallback,
    });
  }

  _liveMidrolls() {
    this._log('_liveMidrolls: Initializing live midrolls');
    if (!this._options.liveMidrolls.cuepointsApiUrl) {
      this._log('_liveMidrolls: cuepointsApiUrl missing');
      return;
    }

    let pollingInterval = 1000;
    const previousCuepoints = [];

    const getCuepoints = () => {
      let resolve;
      let reject;

      setTimeout(() => {
        const xhr = new XMLHttpRequest();
        xhr.onload = () => {
          if (xhr.readyState === xhr.DONE && xhr.status === 200) {
            try {
              const data = JSON.parse(xhr.responseText);
              resolve(data);
            } catch (error) {
              reject(error);
            }
          }
        };
        xhr.onerror = reject;
        xhr.open('GET', this._options.liveMidrolls.cuepointsApiUrl, true);
        xhr.send();
      }, 0);

      const promise = {
        then: (_resolve) => { resolve = _resolve; return promise; },
        catch: (_reject) => { reject = _reject; return promise; },
      };
      return promise;
    };


    function pollForCuepoints() {
      const currentTime = this._snapshot && !this._snapshot.restored ? this._snapshot.time : this._player.currentTime();
      const isNewCuepoint = cuepoint => (
        cuepoint.cuepoint > currentTime &&
        previousCuepoints.indexOf(cuepoint.cuepoint) === -1
      );

      // Don't check for cuepoints before starting or during an ad since creating a new ad context will invalidate
      // the old ad context and no old ads will play.
      if (!currentTime || this.isPlayingAd()) {
        setTimeout(pollForCuepoints.bind(this), pollingInterval);
        return;
      }

      getCuepoints()
        .then((cuepointsData) => {
          if (cuepointsData.pollingInterval) pollingInterval = cuepointsData.pollingInterval * 1000;

          const cuepoints = cuepointsData.liveMidrolls;
          if (cuepoints.filter(isNewCuepoint).length > 0) {
            this._adContext = this._adManager.newContextWithContext(this._adContext);
            this._adContext.addKeyValue('_fw_fss', '_fw_search');

            cuepoints.filter(isNewCuepoint).forEach((cuepoint) => {
              previousCuepoints.push(cuepoint.cuepoint);
              const cuepointTime = Math.floor(cuepoint.cuepoint);
              const customSlotId = `Midroll_${cuepointTime}`;
              this._log(`_setupCuepoints: binding ${customSlotId} to cuepoint at ${cuepointTime}`);
              this._adContext.addTemporalSlot(
                customSlotId, // customId, unique id for the slot
                window.tv.freewheel.SDK.ADUNIT_MIDROLL, // adUnit
                cuepointTime, // timePosition, time of the slot in seconds
                null, // slotProfile
                1, // cuepointSequence
                0, // maxDuration, 0 = unlimited
                0 // minDuration, 0 = no minimum duration
              );
            });

            this._adContext.setRequestMode(window.tv.freewheel.SDK.REQUEST_MODE_LIVE);
            this._setupAdContextEvents();
            this._adContext.submitRequest();
          }

          setTimeout(pollForCuepoints.bind(this), pollingInterval);
        })
        .catch((error) => {
          this._log('Error getting live midrolls', error);
          setTimeout(pollForCuepoints.bind(this), pollingInterval);
        });
    }

    this._player.one('adRequestConfigured', pollForCuepoints.bind(this));
  }

  _platformSpecificConfig() {
    // Check if OS is Android 4 or less, and in that case disable midrolls (since it doesn't work well)
    // Unless it is Android 5 or higher and Chrome browser where they work
    if (
      this._env.android && (
        this._env.osVersion.major <= 4 ||
        !this._env.chrome
      )
    ) {
      this._options.showMidrolls = false;
    }

    // Check if Browser is iPhone and then disable overlays and pause ads since they cannot appear in native fullscreen
    if (this._env.iPhone) {
      this._options.showOverlays = false;
      this._options.showPauseAds = false;

      // In videojs 5, iphones use Native controls by default, but when Freewheel is added to the mix, we
      // notice strange behaviours when changing source such as the "controls" attribute disappearing from
      // the video object after pausing so we end up with no controls. Solution is to force custom controls.
      this._player.ready(() => { this._player.usingNativeControls(false); });
    }

    // [VIP-18967] Since we don't have hover on mobile and tablet we show a 'more info' link for the ad click
    // this way we can both pause the player and click the ad link more than one time
    if (this._env.mobile || this._env.tablet) {
      this._showAdClickThroughLink = true;
      this._player.addClass('ad-show-click-through-link');
    }

    // Edge and Android/Chrome indexes the live stream from the first segment loaded, not the first segment in
    // the manifest. This makes it hard to set cuepoints for the live midrolls so we disable midrolls for now.
    if (
      this._options.showLiveMidrolls &&
      (
        this._env.android ||
        this._env.edge
      )
    ) {
      this._options.showMidrolls = false;
      this._options.showLiveMidrolls = false;
    }
  }

  _onAdManagerLoaded() {
    this._player.on('error', () => {
      this.streamError = true;

      this._restorePlayerAfterBrokenAd();
    });

    this._player.ready(() => this._setupFreeWheel(this._options));
  }

  _restorePlayerAfterBrokenAd() {
    // [PLP-8679] Fix for when the ad stream returns a 404
    const slot = this._currentSlot;
    if (!slot) return;
    const adInstance = slot.getCurrentAdInstance();
    if (!adInstance) return;

    this._log('Trying to restore player after broken ad');

    try {
      this._adImpressionEnd();
      adInstance.getRendererController().setRendererState(window.tv.freewheel.SDK.RendererFailedState.instance);
      slot.playNextAdInstance();
    } catch (e) {
      this._error('Restoring after broken ad failed', e);
    }

    return;
  }

  // [VIP-16842] The player is paused on mouse down and immediatley shows the big play button, this makes the ad
  // click event (which triggers on mouse up) click on the big play button instead if the mouse is near the middle of
  // the video. Here we wrap the click handler and ignore the pause click if we want the ad to be clicked.
  // _handleAdClick will pause the player after freewheel has registered the click.
  _fixAdClickBehavior() {
    this._oldPlayerHandleTechClick = this._player.handleTechClick_;
    this._player.handleTechClick_ = (event) => {
      if (this._showAdClickThroughLink || !this._currentVideoAd) {
        return this._oldPlayerHandleTechClick.apply(this._player, [event]);
      }
      return false;
    };
  }

  _onDispose() {
    this._disposed = true;
    this._log('_onDispose');
    this._stopUpdatingCurrentTime();

    try {
      if (this._currentSlot) this._currentSlot.stop();
      if (this._adContext) this._adContext.dispose();
      if (this._adManager) this._adManager.dispose();
    } catch (error) { /* Do nothing*/ }

    window._fw_admanager = null; // [VIP-22296] All traces of the ad manager needs to be removed for linktag2 to work
  }

  _fakePlay() {
    this._log('Fake player.play call');

    // we still want the player to know it's in a started state, even though we're muting the play calls
    this._player.hasStarted(true);
    // Also update our internal variable, as the hasStarted seems to be reset to false later in some scenarios
    this._playWasRequested = true;
  }

  _setupFreeWheel(options) {
    this._log('_setupFreeWheel');

    this._adManager = AdManagerHandler.getNewAdManagerInstance(options.networkId, options.serverUrl);

    this._adContext = AdManagerHandler.getAdContext(
      this._adManager,
      options,
      this._player.duration(),
      this._player.currentTime(),
      this._player.bitrate.getIdealBitrate(),
      this._env.desktop,
      this._log.bind(this)
    );

    this._setupAdContextEvents();

    if (this._showAdClickThroughLink) {
      this._player.getChild('FWAdClickThrough').el().addEventListener('touchend', this._handleAdClick.bind(this));
      this._player.getChild('FWAdClickThrough').el().addEventListener('click', this._handleAdClick.bind(this));
    } else {
      this._player.getChild('FWAdClickThrough').el().addEventListener('click', this._handleAdClick.bind(this));
      this._player.on(['click', 'touchend'], this._handleAdClick.bind(this));
    }

    // This is in order to resize VPAID ads
    const resizeHandler = this._resizeHandler.bind(this);
    window.addEventListener('resize', resizeHandler);
    this._player.on('dispose', () => window.removeEventListener('resize', resizeHandler));

    this._adContext.submitRequest();
  }

  _setupAdContextEvents() {
    this._adContext.addEventListener(window.tv.freewheel.SDK.EVENT_AD, this._onAdEvent.bind(this));
    this._adContext.addEventListener(window.tv.freewheel.SDK.EVENT_SLOT_STARTED, this._onSlotStarted.bind(this));
    this._adContext.addEventListener(window.tv.freewheel.SDK.EVENT_SLOT_ENDED, this._onSlotEnded.bind(this));
    this._adContext.addEventListener(
      window.tv.freewheel.SDK.EVENT_REQUEST_COMPLETE,
      this._onRequestComplete.bind(this)
    );
  }

  _resizeHandler() {
    this._adContext.registerVideoDisplayBase(this._player.id());
  }

  _onRequestComplete(evt) {
    this._log('_onRequestComplete', evt);

    // Allow play again, regardless of ad request result
    this._restorePlaybackBehavior();

    if (this.streamError || !evt.success) {
      if (this.streamError) this._trigger('adError');
      else this._trigger('adRequestFailed', evt);

      this._onAdRequestFailure();

      if (this._onRequestComplete_initialAdRequestHandled) return;
      this._onRequestComplete_initialAdRequestHandled = true;

      // If we can't load the ad request there probably is an adblocker installed. If not, we still have problems
      // loading ads due to network issues and can't recover so we'll dispose the player and show the
      // "adblocker/can't play videos"-message here (if configured).
      if (this._player.connectivity) this._player.connectivity.trigger(this._player.connectivity.AD_BLOCKER);

      this._handleInitialAdRequest();
      return;
    }

    this._trigger('adRequestSucceeded', evt);

    if (this._options.showPrerolls) {
      this._slots[fwgw.SLOT_TYPES.PREROLL] = this._adContext.getSlotsByTimePositionClass(
        fwgw.SLOT_TYPES.PREROLL);
    }

    this._slots[fwgw.SLOT_TYPES.POSTROLL] = this._adContext.getSlotsByTimePositionClass(
      fwgw.SLOT_TYPES.POSTROLL);
    if (this._slots[fwgw.SLOT_TYPES.POSTROLL].length) {
      this._options.showPostrolls = true;
    }

    if (this._options.showOverlays) {
      this._slots[fwgw.SLOT_TYPES.OVERLAY] = this._adContext.getSlotsByTimePositionClass(
        fwgw.SLOT_TYPES.OVERLAY).sort((a, b) => a.getTimePosition() - b.getTimePosition());
    }

    if (this._options.showDisplayAds) {
      this._slots[fwgw.SLOT_TYPES.DISPLAY] = this._adContext.getSlotsByTimePositionClass(
        fwgw.SLOT_TYPES.DISPLAY);
    }

    if (this._options.showMidrolls) {
      // add cuepoints for midrolls
      this._slots[fwgw.SLOT_TYPES.MIDROLL] = this
        ._adContext.getSlotsByTimePositionClass(fwgw.SLOT_TYPES.MIDROLL)
        .sort((a, b) => a.getTimePosition() - b.getTimePosition());

      if (this._slots[fwgw.SLOT_TYPES.MIDROLL]) {
        this._slots[fwgw.SLOT_TYPES.MIDROLL].forEach((slot) => {
          this._log('add midroll cuepoint at', slot.getTimePosition());
          this._trigger({
            type: 'addCuepoint',
            name: 'midroll',
            time: slot.getTimePosition(),
            callback: this._onMidrollCuepoint.bind(this),
          });
        });
      }
    }

    this._log('_onRequestComplete: slots added', this._slots);
    this._triggerAdSlotsCreated(this._slots);

    if (this._onRequestComplete_initialAdRequestHandled) return;
    this._onRequestComplete_initialAdRequestHandled = true;

    this._handleInitialAdRequest();
  }

  _onAdRequestFailure() {
    // ad blocker will sometimes cause player to be in playing state, but not actually playing (vjs bug?)
    // at this point, issuing a play() or pause() command will take the player out of this broken state
    if (this._player.hasStarted() || this._playWasRequested) this._player.play();
    else this._player.pause();

    this._options.showPrerolls = false;
    this._options.showMidrolls = false;
    this._options.showPostrolls = false;
    this._options.showOverlays = false;
    this._options.showDisplayAds = false;
  }

  _handleInitialAdRequest() {
    this._startUpdatingCurrentTime();

    if (this._hasAds()) {
      this._trackVideoState();
      this._trackAdPlayPauseState();
      this._setupCTAAds();
    }

    if (this._options.showPostrolls) this._setupPostrolls();
    if (this._options.showAdCountdown) this._setupAdCountdown();

    // To trigger a midroll if a cuepoint was scrubbed past
    if (this._options.showMidrolls) this._player.on('mediaSeekComplete', this._onMediaSeekComplete.bind(this));
    if (this._options.showOverlays) this._setupOverlays();
    if (this._options.showDisplayAds) this._loadDisplayAds();

    // start/queue preroll now, if there is any
    if (this._options.showPrerolls) this._playPrerollSlots();
    else this._trigger('noPrerolls');

    this._trigger('adRequestConfigured');
  }

  _trackVideoState() {
    const _this = this;
    function trackPlayState() {
      _this._adContext.setVideoState(window.tv.freewheel.SDK.VIDEO_STATE_PLAYING);
    }

    function trackPauseState() {
      _this._adContext.setVideoState(window.tv.freewheel.SDK.VIDEO_STATE_PAUSED);
    }

    function trackEndedState() {
      _this._adContext.setVideoState(window.tv.freewheel.SDK.VIDEO_STATE_COMPLETED);
    }

    if (this._hasAds()) {
      this._player.on('play', trackPlayState);
      this._player.on('pause', trackPauseState);
      this._player.on('ended', trackEndedState);
    }

    this._player.on('adRollEnd', () => {
      this._player.on('play', trackPlayState);
      this._player.on('pause', trackPauseState);
      this._player.on('ended', trackEndedState);
    });

    this._player.on('adRollStart', () => {
      this._player.off('play', trackPlayState);
      this._player.off('pause', trackPauseState);
      this._player.off('ended', trackEndedState);
    });
  }

  _trackAdPlayPauseState() {
    this._player.on('play', () => {
      if (!this._currentVideoAd) return;

      // We ignore Pause/Play Sequences that are too close to each other. They tend to mess up tracking
      const currentTime = this._player.currentTime();
      if (this._videoAdPauseTime > 0 && (currentTime - this._videoAdPauseTime) < 0.100) {
        this._log('Ignore adPause/adPlay sequence as they are too close to each other');
        this._videoAdPlayIgnored = true;
      } else {
        this._mediaPauseTime = -1;
        this._trigger('adPlay');
      }
    });

    this._player.on('pause', () => {
      if (!this._currentVideoAd) return;

      // We ignore Pause/Play Sequences that are too close to each other. They tend to mess up tracking
      this._videoAdPauseTime = this._player.currentTime();

      // Ignore pause events at the end of the Ad
      if (this._currentVideoAd && this._currentVideoAd.getDuration() === this._videoAdPauseTime) {
        this._videoAdPauseTime = -1;
        return;
      }

      setTimeout(() => {
        if (this._videoAdPlayIgnored) {
          this._videoAdPlayIgnored = false;
        } else {
          this._videoAdPauseTime = -1;
          this._trigger('adPause');
        }
      }, 200);

      if (this._isCurrentAdVPAID()) {
        // VPAID ads should be paused through the VPAID interface but Freewheel has not implemented that yet
        // this is a hack they suggested
        if (this._currentVideoAd._rendererController &&
          this._currentVideoAd._rendererController.getRenderer()) {
          this._currentVideoAd._rendererController.getRenderer().pause();
        }
      }
    });
  }

  _setupSoundThresholdFilter() {
    this._player.on('ready', () => { this._createSoundThreshold(); });
    this._player.on(['adStart', 'adRollStart'], () => { this._setSoundThreshold(); });
    this._player.on(['mediaPlaying', 'mediaStart', 'adEnd'], () => { this._removeSoundThreshold(); });
  }

  _createSoundThreshold() {
    this._soundThresholdEnabled = false;
    this._filterContext = new window.AudioContext();
    this._filterSource = this._filterContext.createMediaElementSource(this._player.tech_.el_);
    this._filter = this._filterContext.createDynamicsCompressor();
    this._filter.threshold.value = -50;
    this._filter.knee.value = 0;
    this._filter.ratio.value = 12;
    this._filter.attack.value = 0;
    this._filter.release.value = 0.25;

    this._filterSource.connect(this._filterContext.destination);
  }

  _setSoundThreshold() {
    if (this._soundThresholdEnabled) return;
    this._filterSource.disconnect(this._filterContext.destination);
    this._filterSource.connect(this._filter);
    this._filter.connect(this._filterContext.destination);
    this._soundThresholdEnabled = true;
  }

  _removeSoundThreshold() {
    if (!this._soundThresholdEnabled) return;
    this._filterSource.disconnect(this._filter);
    this._filter.disconnect(this._filterContext.destination);
    this._filterSource.connect(this._filterContext.destination);
    this._soundThresholdEnabled = false;
  }


  _setupCTAAds() {
    this._player.on('adStart', (evt) => {
      if (!evt.ad || !evt.ad.getEventCallbackUrls || !evt.ad.getActiveCreativeRendition) return;

      const clickThroughLink = evt.ad.getEventCallbackUrls('ctaClick', 'CLICK');
      if (!clickThroughLink || clickThroughLink.length === 0) return;

      this._log('CTA Ad: detected');

      const ctaOverlay = this._player.getChild('FWCTAButton');

      const icon = evt.ad.getActiveCreativeRendition().getParameter('ctabtn');
      const altText = evt.ad.getActiveCreativeRendition().getParameter('ctaalt');
      const showTimeOffset = evt.ad.getActiveCreativeRendition().getParameter('ctain');
      const hideTimeOffset = evt.ad.getActiveCreativeRendition().getParameter('ctaout');
      const width = evt.ad.getActiveCreativeRendition().getParameter('ctawidth');
      const coordinates = evt.ad.getActiveCreativeRendition().getParameter('ctapos').split(',');

      ctaOverlay.init(icon, altText, clickThroughLink, coordinates[0], coordinates[1], width);

      // Show either instantly or at specified offset
      if (!showTimeOffset || showTimeOffset === 0) {
        this._log('CTA Ad: showing overlay');
        ctaOverlay.show();
      } else {
        this._trigger({
          type: 'addCuepoint',
          name: 'cta-overlay',
          time: showTimeOffset,
          callback: () => {
            if (!this._currentVideoAd) return true;

            this._log('CTA Ad: showing overlay');
            ctaOverlay.show();
            return true; // This removes cuepoint after execution
          },
        });
      }

      // Prepare of hidding
      this._trigger({
        type: 'addCuepoint',
        name: 'cta-overlay',
        time: hideTimeOffset,
        callback: () => {
          this._log('CTA Ad: hidding overlay');
          ctaOverlay.disable();
          return true;
        },
      });

      this._player.on('adEnd', () => {
        // We do this in case the out cuepoint is not triggered (possibly due to a skipped ad)
        if (!this.isDisabled()) ctaOverlay.disable();
      });
    });
  }

  _setupPostrolls() {
    this._player.on('ended', () => {
      if (this._currentVideoAd) return;

      this._playTemporalSlot(this._slots[fwgw.SLOT_TYPES.POSTROLL].shift());
    });
  }

  _setupAdCountdown() {
    this._player.setInterval(() => {
      if (!this._currentSlot) return;
      if (this._player.paused()) return;

      // ad countdown: time left
      const adTimeRemaining = Math.round(this._getTotalAdDurationRemaining());

      // only show the remaining time if we don't have an estimate
      if (adTimeRemaining === -1) {
        this._player.getChild('FWAdCountdown').el().innerHTML = this._player.localize('Ad playing');
      } else {
        this._player.getChild('FWAdCountdown').el().innerHTML = `${
          this._player.localize('Ad playing')}: ${adTimeRemaining} ${this._player.localize('s remaining')}`;
      }
    }, 1000);

    // make sure the ad countdown text is reset before the next ad roll
    // (otherwise we might show "0 s remaining", remaining from the last ad playback)
    this._player.on('adRollEnd', () => {
      this._player.getChild('FWAdCountdown').el().innerHTML = '';
    });
  }

  _loadDisplayAds() {
    this._slots[fwgw.SLOT_TYPES.DISPLAY].forEach((slot) => {
      try {
        slot.play();
      } catch (e) { this._log('unsupported or malformed display ad', e); }
    });
    this._trigger('adDisplayFinish');
  }

  _setupOverlays() {
    // listen to time updates for midrolls and overlays
    this._player.setInterval(() => {
      if (this._currentVideoAd || this._player.scrubbing()) return;
      if (this._currentOverlay || this._currentSlot || !this._slots[fwgw.SLOT_TYPES.OVERLAY]) return;

      // use "Array.every" to be able to short circut ("break")
      this._slots[fwgw.SLOT_TYPES.OVERLAY].every((overlay) => {
        // overlays are sorted according to their time position, so we can break early if the next overlay is in
        // the future
        if (overlay.getTimePosition() > this._player.currentTime()) return false;

        // don't play this overlay slot if we already played it or passed it by more than 10 s
        if (this._finishedSlots.indexOf(overlay.getCustomId()) !== -1) return true;
        if ((overlay.getTimePosition() + 10) < this._player.currentTime()) return true;

        this._slots[fwgw.SLOT_TYPES.OVERLAY].splice(this._slots[fwgw.SLOT_TYPES.OVERLAY].indexOf(overlay), 1);
        this._playOverlaySlot(overlay);

        // break early since we found an overlay
        return false;
      });
    }, 500);
  }

  _playPrerollSlots() {
    if (this._slots[fwgw.SLOT_TYPES.PREROLL].length > 0 && this._slots[fwgw.SLOT_TYPES.PREROLL][0].getAdCount() > 0) {
      // many mobile devices prevent autoplay and play until user interacts with video, so only run preroll logic if
      // the player can actually start
      this._replacePlaybackBehavior(() => {
        this._restorePlaybackBehavior();
        if (this._player.env.mobile) this._player.play();
        // Videojs 6 has a timeout of 1ms when setting the media source. We need to run after that.
        this._player.setTimeout(() => {
          this._playTemporalSlot(this._slots[fwgw.SLOT_TYPES.PREROLL].shift(), false);
        }, 0);
      });
    } else {
      this._log('_playPrerollSlots: No preroll slots or ads to play');
      this._trigger('noPrerolls');
    }
  }


  _startUpdatingCurrentTime() {
    this._currentTime = 0;
    this._currentTimeInterval = setInterval(() => {
      if (!this._player.paused()) {
        this._currentTime = this._player.currentTime();
      }
    }, 1000);
  }

  _stopUpdatingCurrentTime() {
    clearInterval(this._currentTimeInterval);
  }

  _hasAds() {
    return (
      this._options.showPrerolls ||
      this._options.showMidrolls ||
      this._options.showPostrolls ||
      this._options.showOverlays ||
      this._options.showDisplayAds
    );
  }

  _triggerAdSlotsCreated(slots) {
    const eventSlots = [];
    Object.keys(slots).forEach((key) => {
      if ({}.hasOwnProperty.call(slots, key)) {
        slots[key].forEach(s => eventSlots.push({ type: key, time: s.getTimePosition() }));
      }
    });

    this._trigger({
      type: 'adSlotsCreated',
      slots: eventSlots,
    });
  }

  _onAdEvent(evt) {
    this._log('_onAdEvent', evt);

    const slotType = evt.slot.getTimePositionClass();

    if (!this._validateSlotType(slotType)) {
      this._log('_onAdEvent: unknown slotType, aborting', slotType);
      return;
    }

    if (slotType === fwgw.SLOT_TYPES.OVERLAY &&
      evt.subType === window.tv.freewheel.SDK.EVENT_AD_IMPRESSION) {
      this._setOverlayClickEventHandler();
    }

    // nothing to do if not a preroll, midroll or postroll
    if (!fwgw.isTemporal(slotType)) return;

    const ad = evt.adInstance;

    switch (evt.subType) {
      case window.tv.freewheel.SDK.EVENT_AD_AUTO_PLAY_BLOCKED:
        this._log('_onAdEvent: AD_AUTO_PLAY_BLOCKED');
        this._autoPlayBlocked = true;
        this._player.pause();
        this._player.removeClass('ad-playing');
        this._player.removeClass('vjs-waiting');
        this._player.removeClass('loading');
        this._player.removeClass('x-started');
        this._player.removeClass('vjs-has-started');
        this._player.one('timeupdate', () => {
          this._autoPlayBlocked = false;
          this._player.addClass('x-started');
          this._player.addClass('vjs-has-started');
          this._player.addClass('ad-playing');
        });
        break;
      case window.tv.freewheel.SDK.EVENT_AD_INITIATED:
        if (this._env.chromecast && ad) {
          ad.getRendererController().setCapability(
            window.tv.freewheel.SDK.EVENT_AD_CLICK,
            window.tv.freewheel.SDK.CAPABILITY_STATUS_OFF
          );
        }
        break;
      case window.tv.freewheel.SDK.EVENT_AD_IMPRESSION:
        // If we still have an ad impression make sure that we have sent all end events before starting a new
        if (this._currentVideoAd) this._adImpressionEnd();
        this._currentVideoAd = ad;

        // send some ad data together with adStart event
        ad.adType = evt.slot.getTimePositionClass();
        ad.duration = ad.getActiveCreativeRendition().getDuration();
        this._positionInSlot += 1;
        ad.positionInRoll = this._positionInSlot;

        // film code, still missing from FreeWheel
        ad.customId = typeof ad._creative !== 'undefined' ? ad._creative.getParameter('_fw_4AID') : '';

        ad.id = ad.getAdId();

        if (this._isCurrentAdVPAID()) {
          this._player.addClass('vpaid');
          this._setupSourceProtection();
        }

        this._trigger({ type: 'adStart', ad });
        if (ad.adType === fwgw.SLOT_TYPES.PREROLL) this._trigger('adInPrerollStart', ad);
        else if (ad.adType === fwgw.SLOT_TYPES.MIDROLL) this._trigger('adInMidrollStart', ad);
        else if (ad.adType === fwgw.SLOT_TYPES.POSTROLL) this._trigger('adInPostrollStart', ad);
        this._trigger('adPlay');
        break;
      case window.tv.freewheel.SDK.EVENT_AD_FIRST_QUARTILE:
        this._trigger('adFirstQuartile');
        break;
      case window.tv.freewheel.SDK.EVENT_AD_MIDPOINT:
        this._trigger('adMidpoint');
        break;
      case window.tv.freewheel.SDK.EVENT_AD_THIRD_QUARTILE:
        this._trigger('adThirdQuartile');
        break;
      case window.tv.freewheel.SDK.EVENT_AD_IMPRESSION_END:
        this._trigger('adImpressionEnd');
        this._adImpressionEnd();
        break;
      case window.tv.freewheel.SDK.EVENT_AD_CLICK:
        this._trigger('adClickThrough');
        break;
      case window.tv.freewheel.SDK.EVENT_AD_COMPLETE:
        break;
      case window.tv.freewheel.SDK.EVENT_RESELLER_NO_AD:
        /*
         * [PLP-10887] If we don't get any ads back for programatic+freewheel (pre-rolls) we manually trigger
         * the duration change to make the media start trigger correctly. The timeout 0 makes it
         * trigger at the end of the event chain.
        **/
        setTimeout(() => this._trigger('durationchange'), 0);
        break;
      default:
        this._log('_onAdEvent unhandled event', evt);
        break;
    }
  }

  _adImpressionEnd() {
    if (!this._currentVideoAd) return;

    if (this._isCurrentAdVPAID()) {
      this._player.removeClass('vpaid');
      this._resetSourceProtection();
    }

    this._trigger('adEnd');
    if (this._currentVideoAd.adType === fwgw.SLOT_TYPES.PREROLL) this._trigger('adInPrerollFinish');
    else if (this._currentVideoAd.adType === fwgw.SLOT_TYPES.MIDROLL) this._trigger('adInMidrollFinish');
    else if (this._currentVideoAd.adType === fwgw.SLOT_TYPES.POSTROLL) this._trigger('adInPostrollFinish');

    this._currentVideoAd = null;
  }

  _isCurrentAdVPAID() {
    return this._currentVideoAd &&
      this._currentVideoAd.getActiveCreativeRendition &&
      this._currentVideoAd.getActiveCreativeRendition().getCreativeApi() === 'VPAID';
  }

  _onSlotStarted(evt) {
    this._log('_onSlotStarted', evt, evt.slot.getTimePositionClass());

    const slotType = evt.slot.getTimePositionClass();

    if (!this._validateSlotType(slotType)) {
      this._log('_onSlotStarted: unknown slotType, aborting', slotType);
      return;
    }

    if (slotType === fwgw.SLOT_TYPES.OVERLAY) this._player.addClass('ad-overlay-showing');

    // nothing to do if not a preroll, midroll or postroll
    if (!fwgw.isTemporal(slotType)) return;

    this._checkForStalledAdInstance(evt);
  }

  /**
   * [VIP-21291] and [PLP-9287] Calls to liverail will sometimes timeout so we need to detect stalled ad instances and
   * tell the ad manager to skip them. Skip ads on stalling for more than 4000ms.
   * This code will not be able to run unless we use asynchronus ajax calls.
   */
  _checkForStalledAdInstance(evt) {
    if (!this._env.desktop) return;

    const TIMEOUT_IN_MS = 5000;
    const TIMEOUT_IN_MS_BEFORE_LOADING_SCREEN = 1000;
    const INTERVAL_BETWEEN_CHECKS_IN_MS = 200;
    const TOTAL_NUMBER_OF_CHECKS = TIMEOUT_IN_MS / INTERVAL_BETWEEN_CHECKS_IN_MS;
    const TOTAL_NUMBER_OF_CHECKS_BEFORE_LOADING_SCREEN =
      TIMEOUT_IN_MS_BEFORE_LOADING_SCREEN / INTERVAL_BETWEEN_CHECKS_IN_MS;
    const slot = evt.slot;
    let numberOfChecks = 0;
    let adInstance;
    let startTime = 0;

    const intervalId = this._player.setInterval(() => {
      const newAdInstance = slot && slot.getCurrentAdInstance();

      if (!newAdInstance) {
        this._player.clearInterval(intervalId);
        this._player.removeClass('vjs-waiting');
        return;
      }

      if (!adInstance || newAdInstance !== adInstance) {
        adInstance = newAdInstance;
        numberOfChecks = 0;
        startTime = this._player.currentTime();
      }

      const hasStarted = startTime !== this._player.currentTime();

      if (hasStarted) {
        this._player.removeClass('vjs-waiting');
        return;
      }

      if (this._autoPlayBlocked) {
        return;
      }

      numberOfChecks += 1;
      if (numberOfChecks > TOTAL_NUMBER_OF_CHECKS_BEFORE_LOADING_SCREEN) {
        this._player.addClass('vjs-waiting');
      }
      if (numberOfChecks > TOTAL_NUMBER_OF_CHECKS) {
        this._log('_adInstanceStalled', evt);
        this._forceNextAdInstance();
        this._trigger('adStalled');
      }
    }, INTERVAL_BETWEEN_CHECKS_IN_MS);
  }

  _forceNextAdInstance() {
    const slot = this._currentSlot;
    const adInstance = slot && slot.getCurrentAdInstance();
    this._log('_forceNextAdInstance');
    this._adImpressionEnd();
    adInstance.getRendererController().setRendererState(window.tv.freewheel.SDK.RendererFailedState.instance);
    slot.playNextAdInstance();
    this._player.removeClass('vjs-waiting');
  }

  _setOverlayClickEventHandler() {
    // It seems that FW does not provide any click event for overlays, therefore set an event listener
    // on the Overlay in order to pause the player
    const overlayLink = this._player.el().querySelectorAll('.ad-overlay .ad-overlay-content a')[0];
    if (overlayLink) overlayLink.addEventListener('click', () => this._player.pause());
  }

  _onSlotEnded(evt) {
    if (this._disposed) return;

    this._log('_onSlotEnded', evt, evt.slot.getTimePositionClass());

    const slotType = evt.slot.getTimePositionClass();

    if (!this._validateSlotType(slotType)) {
      this._log('_onSlotEnded: unknown slotType, aborting', slotType);
      return;
    }

    if (slotType === fwgw.SLOT_TYPES.OVERLAY) {
      this._player.removeClass('ad-overlay-showing');
      this._currentOverlay = null;
    }

    // nothing to do if not a preroll, midroll or postroll
    if (!fwgw.isTemporal(slotType)) return;

    // Make sure that the last ad impression has ended correctly
    this._adImpressionEnd();
    this._currentSlot = null;

    // If the slot didn't contain any ads we don't have to restore the video
    if (evt.slot.getAdCount() <= 0) {
      return;
    }

    switch (slotType) {
      case fwgw.SLOT_TYPES.PREROLL:
        this._trigger('loading');
        this._restoreSnapshot(() => {
          this._trigger('adRollEnd');
          this._trigger('adPrerollEnd');

          this._trigger('mediaPlaying');
        });
        break;
      case fwgw.SLOT_TYPES.MIDROLL:
        this._trigger('loading');
        this._restoreSnapshot(() => {
          this._trigger('adRollEnd');
          this._trigger('adMidrollEnd', { position: this._currentMidrollPosition });

          this._trigger('mediaPlaying');
          this._trigger('mediaPlay');
        }, true);
        break;
      case fwgw.SLOT_TYPES.POSTROLL:
        this._restoreSnapshot(() => {
          this._trigger('adRollEnd');
          this._trigger('adPostrollEnd');

          this._trigger('ended');
          this._trigger('mediaEnding');
          this._player.one('playing', () => this._trigger('mediaPlaying'));
        }, false, true);
        break;
      default:
        this._trigger('loading');
        break;
    }
  }

  _getTotalAdDurationRemaining() {
    this._log('_getTotalAdDurationRemaining');

    // some VPAID ads don't report their playheadtime correctly, but just return 0 all the time
    // since we don't really mind not showing the remaining time for a couple of hundred milliseconds or so,
    // we simply return -1 to indicate "not known"
    if (this._currentSlot.getPlayheadTime() <= 0) return -1;

    return this._currentSlot.getTotalDuration() - this._currentSlot.getPlayheadTime();
  }

  _handleAdClick(evt) {
    // if the event was triggered by a click on the big play button, abort
    if (evt.target.className && evt.target.className.indexOf('vjs-big-play-button') !== -1) return false;

    // if the event was triggered by a click on a button *anywhere* on the control bar, abort
    for (let cur = evt.target; cur; cur = cur.parentNode) {
      // If we backtracked until we got to the container div then it is a legit clickthrough
      if (cur === this._player.el()) break;

      // Ignore clicks on the control bar
      if (cur.className && cur.className.indexOf('vjs-control-bar') !== -1) {
        evt.stopPropagation();
        return false;
      }
    }

    if (!this._currentVideoAd) return false;

    // If we are waiting to load a new ad instance, ignore this click
    if (this._currentVideoAd !== this._currentSlot.getCurrentAdInstance()) return false;

    this._log('_handleAdClick', evt);

    this._player.pause();
    this._currentVideoAd.getRendererController().processEvent({ name: window.tv.freewheel.SDK.EVENT_AD_CLICK });

    return true;
  }

  setSkipAdAfterNextSeek(skipAdAfterNextSeek) {
    this._skipAdAfterNextSeek = skipAdAfterNextSeek;
  }

  /**
   * Play a midroll if the user has scrubbed past a cuepoint
   */
  _onMediaSeekComplete(e) {
    // When returning from a cast session you should not have to see the last midroll again
    if (this._skipAdAfterNextSeek) {
      this._skipAdAfterNextSeek = false;
      return;
    }

    // Make sure that we don't replace a midroll with another on scrubbing
    if (this._currentVideoAd) return;

    // This happens after we restore from an ad to content and causes multiple
    // ad rolls in a row
    if (e.beforeSeekTime === 0) return;

    this._log('_onMediaSeekComplete', e.beforeSeekTime, this._player.currentTime(), this._player.getMediaCurrentTime());

    if (e.beforeSeekTime === this._player.currentTime()) return;

    // Ignore if no midrolls left to play
    if (!this._hasNextMidroll()) return;

    // Check if there are any midrolls that has been scrubbed past
    const midrolls = this._slots[fwgw.SLOT_TYPES.MIDROLL];
    const hasSeekedPastMidroll = this._some(midrolls, slot =>
      slot.getTimePosition() > e.beforeSeekTime && slot.getTimePosition() < e.afterSeekTime
    );

    if (!hasSeekedPastMidroll) return;

    // Then play the first midroll in queue (which midroll was scrubbed past is not important)
    this._playNextMidroll();
  }

  /**
   * Play a midroll every time we hit a cuepoint and there are midrolls left
   */
  _onMidrollCuepoint() {
    if (this._currentVideoAd) return false; // return false to not destroy the  midroll cuepoint

    this._log('_onMidrollCuepoint');

    // Ignore if no midrolls left to play
    if (!this._hasNextMidroll()) return false;

    this._playNextMidroll();

    // This cuepoint is now consumed, remove it by returning true
    return true;
  }

  _fixIOSSubs(callback) {
    const currentSubtitle = this._player.getCurrentSubtitle();
    this._savedIOSSubtitleLabel = currentSubtitle ? currentSubtitle.label : 'none';
    this._player.changeSubtitleByType('none');

    setTimeout(() => {
      // Delay the execution until the end of the running queue to make the textTrack mode change work
      callback();
    }, 0);
  }

  _hasNextMidroll() {
    const midrolls = this._slots[fwgw.SLOT_TYPES.MIDROLL];
    return midrolls && midrolls.length;
  }

  _playNextMidroll() {
    // We have a timeslot, play the first of our remaining midrolls
    this._currentMidrollPosition += 1;
    const midrolls = this._slots[fwgw.SLOT_TYPES.MIDROLL];
    const midroll = midrolls.shift();

    if (this._env.iOS && this._player.hasAvailableTextTracks()) {
      this._fixIOSSubs(() => this._playTemporalSlot(midroll));
    } else {
      this._playTemporalSlot(midroll);
    }
  }

  _playTemporalSlot(slot) {
    if (this._currentVideoAd) return; // If already playing an ad, skip this one

    if (!slot) {
      this._log('_playTemporalSlot: no slot received, aborting');
      return;
    }

    this._log('_playTemporalSlot', slot, slot.getCustomId());
    const slotType = slot.getTimePositionClass();
    this._currentSlot = slot;
    this._positionInSlot = 0;
    this._finishedSlots.push(slot.getCustomId());

    // If the slot didn't contain any ads we 'play' the empty slot to send all impressions
    if (slot.getAdCount() <= 0) {
      this._log('_playTemporalSlot: no ads in slot');
      this._safePlaySlot(slot);
      return;
    }

    this._trigger('adRollStart');
    if (slotType === fwgw.SLOT_TYPES.PREROLL) {
      this._trigger('adPrerollStart');
    } else if (slotType === fwgw.SLOT_TYPES.MIDROLL) {
      this._trigger('adMidrollStart', { position: this._currentMidrollPosition });
    } else if (slotType === fwgw.SLOT_TYPES.POSTROLL) {
      this._trigger('adPostrollStart');
    }

    this._saveSnapshot();
    this._resetSubtitles();
    this._adContext.setContentVideoElement(this._getTechEl());
    this._releaseMouse();

    // used by custom renderer to find vjs instance
    slot.setBase(this._player.el());

    // Freewheel has issues when preload is deactivated.
    this._player.preload('auto');
    this._player.autoplay(false);

    // Some ads has issues if the crossOrigin attribute is used, but we need to set it to
    // 'anonymous' if we want to use the sound threshold filter
    if (this._getTechEl()) {
      if (this._options.useSoundThresholdFilter && typeof this._getTechEl().setAttribute === 'function') {
        this._getTechEl().setAttribute('crossOrigin', 'anonymous');
      } else if (typeof this._getTechEl().removeAttribute === 'function') {
        this._getTechEl().removeAttribute('crossOrigin');
      }
    }

    // Need to use a reference to the function in order to be able to remove the event listener
    this._disableSeekForwardReference = this._disableSeekForward.bind(this);
    this._player.on('seeking', this._disableSeekForwardReference);

    this._safePlaySlot(slot);
  }

  _playOverlaySlot(slot) {
    if (this._currentVideoAd || this._currentOverlay) return;
    if (!slot) {
      this._log('_playOverlaySlot: no slot received, aborting');
      return;
    }

    this._log('_playOverlaySlot');

    this._currentOverlay = slot;

    // AdManager normally uses the <video> parent as base, but since we might not have a <video> element
    // (e.g. contrib-hls for desktop fallbacks to flash), set the base element manually here
    const overlayComponent = this._player.getChild('FWOverlay');
    slot.setBase(overlayComponent.el());

    this._finishedSlots.push(slot.getCustomId());
    this._safePlaySlot(slot);

    this._setupClosingOfOverlay(slot, overlayComponent);
  }

  _setupClosingOfOverlay(slot, overlay) {
    const overlayComponent = overlay;

    overlayComponent.onCloseClick = () => {
      slot.stop();
    };
  }

  _safePlaySlot(slot) {
    // The player should not crash if the slot fails to play
    try {
      slot.play();
    } catch (e) {
      this._log('Error playing temporal slot', e);
      this._onSlotEnded({ slot });
    }
  }

  _saveSnapshot() {
    this._log('_saveSnapshot', this._player.currentSrc());

    let currentCrossOriginAttribute = null;
    if (this._getTechEl()) currentCrossOriginAttribute = this._getTechEl().getAttribute('crossOrigin');

    this._snapshot = {
      src: this._player.currentSrc(),
      type: this._player.currentType(),
      time: this._player.currentTime(),
      crossOriginAttribute: currentCrossOriginAttribute,
    };
  }

  _restoreSnapshot(cb, restoreTime, end) {
    if (this._disposed) return;
    this._log('_restoreSnapshot', this._snapshot);

    this._player.pause();

    this._player.off('seeking', this._disableSeekForwardReference);

    if (!end) this._player.autoplay(true);

    if (this._getTechEl() && this._snapshot.crossOriginAttribute) {
      this._getTechEl().setAttribute('crossOrigin', this._snapshot.crossOriginAttribute);
    }

    this._snapshot.restored = true;
    if (!this._snapshot.src) {
      // Prerolls on desktop without the sourceloader
      this._player.one('timeupdate', () => this._restoreSubtitles());
      this._player.hasStarted(true);
      cb();
      return;
    }

    this._restoreVideoSource(this._snapshot.src, this._snapshot.type);

    if (restoreTime) {
      // RESTORING FOR REGULAR and LIVE MIDROLLS
      this._restoreSubtitles();
      const seekOffset = this._isLive() ? this._player.seekable().end(0) : this._snapshot.time;
      this._seekToOffset(seekOffset, this._isLive(), () => {
        this._player.hasStarted(true);
        cb();
      });
    } else if (this._snapshot.time > this._player.getMediaDuration() - 1) {
      // Restoring for POSTROLLS
      this._player.hasStarted(true);
      cb();
    } else {
      // Restoring for PREROLLS on Mobile devices and with the sourceloader
      this._player.one('timeupdate', () => {
        this._restoreSubtitles();
        this._player.hasStarted(true);
        cb();
      });
    }

    this._player.load();
  }

  _restoreVideoSource(src, type) {
    const sourceObject = { src, type };

    if (
      this._player.tech_ &&
      this._player.tech_.hls &&
      this._player.tech_.hls.options_ &&
      this._player.tech_.hls.options_.withCredentials
    ) {
      sourceObject.withCredentials = this._player.tech_.hls.options_.withCredentials;
    }
    this._player.src(sourceObject);
  }

  _seekToOffset(offset, isLive, callback) {
    this._log('_seekToOffset', offset);

    this._player.currentTime(offset);
    this._log('_seekToOffset:  player paused after ad', this._player.paused(), offset);

    const _onTimeUpdate = () => {
      this._log('_seekToOffset:  _onTimeUpdate', this._player.currentTime(), offset, this._canSeekTo(offset));
      if (!this._player.currentTime() || !this._canSeekTo(offset)) {
        this._player.one('timeupdate', _onTimeUpdate);
        return;
      }
      if (this._player.paused()) this._player.play();

      // Fire an error if we seek more than a second away from the target offset
      if (Math.abs(this._player.currentTime() - offset) > 1) {
        this._log('_seekToOffset: _onTimeUpdate: could not seek after ad, will try again', this._player.currentTime());
        this._player.currentTime(offset);
        this._player.one('timeupdate', _onTimeUpdate);
        return;
      }

      this._log('_seekToOffset: calling callback', this._player.currentTime(), offset);
      callback();
    };
    this._player.one('timeupdate', _onTimeUpdate);
  }

  /**
   * Save current state, disable subtitles and hide subtitle button
   */
  _resetSubtitles() {
    if (
        !this._player.shouldHaveSubtitles() ||
        typeof this._player.textTracks !== 'function' ||
        (this._player.tech_ && typeof this._player.tech_.textTracks !== 'function')
    ) {
      return;
    }

    // On iOS we need to save and reset the subtitles earlier to prevent ghost subtitles
    if (this._savedIOSSubtitleLabel) {
      this._snapshot.currentTextTrackLabel = this._savedIOSSubtitleLabel;
    } else {
      const currentSubtitle = this._player.getCurrentSubtitle();
      this._snapshot.currentTextTrackLabel = currentSubtitle ? currentSubtitle.label : 'none';
    }

    this._log('_resetSubtitles', this._snapshot.currentTextTrackLabel);

    this._player.changeSubtitleByType('none');

    // Safari on Mac and on iOS (none-mse platforms) add the same track every time the src is changed and
    // restored. The solution is to clear the sources and let the browsers reload the subtitle tracks
    // again
    if (this._isUsingNativeHLS()) {
      this._player.textTracks().tracks_ = [];
    }

    // Hide subtitle buttons for the 'default' skin without the subtitles menu
    if (
      this._player.controlBar &&
      !(
        this._player.options() &&
        this._player.options().plugins.mtgxSkin &&
        (
          this._player.options().plugins.mtgxSkin.name === 'viafree' ||
          this._player.options().plugins.mtgxSkin.name === 'viasport'
        )
      )
    ) {
      this._player.controlBar.subtitlesButton.hide();
      this._player.controlBar.captionsButton.hide();
    }
  }

  /**
   * Restore subtitles and show subtitle button
   */
  _restoreSubtitles() {
    this._log('_restoreSubtitles', this._snapshot.currentTextTrackKind);

    if (
      !this._player.shouldHaveSubtitles() ||
      !this._snapshot.currentTextTrackLabel ||
      typeof this._player.textTracks !== 'function'
    ) return;

    if (!this._player.hasAvailableTextTracks()) {
      // No subtitles available yet
      this._player.one('timeupdate', () => this._restoreSubtitles);
      return;
    }

    this._player.changeSubtitleByLabel(this._snapshot.currentTextTrackLabel);

    // Show subtitle buttons for the 'default' skin without the subtitles menu
    if (
      this._player.controlBar &&
      !(
        this._player.options().plugins.mtgxSkin &&
        (
          this._player.options().plugins.mtgxSkin.name === 'viafree' ||
          this._player.options().plugins.mtgxSkin.name === 'viasport'
        )
      )
    ) {
      const hasSubtitles = this._player.getAvailableTextTracks().filter(
          textTrack => textTrack.kind === 'subtitles'
        ).length > 0;

      if (hasSubtitles) {
        this._player.controlBar.subtitlesButton.show();
      }
    }
  }

  _canSeekTo(time) {
    return this._player.seekable() && this._player.seekable().length > 0 && this._player.seekable().end(0) >= time;
  }

  _validateSlotType(slotType) {
    this._log('_validateSlotType', slotType);

    // only slot types that we have set up queues for are valid (empty queues are OK)
    return !!this._slots[slotType];
  }

  _isUsingHtml5() {
    return this._player.techName_ === 'Html5';
  }

  _isUsingNativeHLS() {
    return this._env.iOS || (this._isUsingHtml5() && this._env.isMac && this._env.safari);
  }

  _some(list, cb) {
    for (let i = 0; i < list.length; i += 1) {
      if (cb(list[i])) return true;
    }
    return false;
  }

  _disableSeekForward() {
    this._log('_disableSeekForward', this._currentTime, this._player.currentTime());
    if (this._currentTime < this._player.currentTime()) {
      this._player.currentTime(0);
    }
  }

  _releaseMouse() {
    this._log('_releaseMouse');
    const mouseUpEvent = document.createEvent('MouseEvents');
    mouseUpEvent.initMouseEvent(
      'mouseup', // type
      true,      // bubbles
      true,      // cancelable
      window,    // view
      0, 0, 0, 0, 0, false, false, false, false, 0, null // required irrelevant stuff
    );
    this._player.el().dispatchEvent(mouseUpEvent);
  }

  /**
   * This sets a listener for an array of events on the player.
   * When all the events in the listner happen, then the handler is fired.
   */
  _mutipleEventListener(events, callback) {
    this._log('_mutipleEventListener');
    const remainingEvents = events;
    events.forEach((event) => {
      this._player.one(event, () => {
        const index = remainingEvents.indexOf(event);
        if (index > -1) {
          remainingEvents.splice(index, 1);
        }

        if (remainingEvents.length === 0) {
          callback();
        }
      });
    });
  }

  _setupSourceProtection() {
    this._log('_setupSourceProtection');
    Object.defineProperty(this._getTechEl(), 'src', {
      get: () => this._getTechEl()._src,
      set: (source) => {
        if (!source) {
          this._trigger('loading');
          return;
        }
        this._getTechEl()._src = source;
        this._getTechEl().setAttribute('src', source);
      },
      configurable: true,
    });
  }

  _resetSourceProtection() {
    this._log('_resetSourceProtection');
    delete this._getTechEl().src;
  }

  _getTechEl() {
    return this._player.tech_.contentEl();
  }


  _isLive() {
    return this._player.duration() === Infinity && !this._env.android;
  }

  isPlayingAd() {
    return !!this._currentSlot;
  }

  _trigger(...evt) {
    this._log('Trigger:', ...evt);

    this._player.trigger(...evt);
  }

  _log(...args) {
    debugLog(`[#${this._player.id()}]`, ...args);
  }

  _error(...args) {
    errorLog(`[#${this._player.id()}]`, ...args);
    console.error('> avodp - fw error >>', args[0]); // eslint-disable-line no-console
  }
}

// only instantiate the plugin if it's not under test
if (process.env.NODE_ENV !== 'test') {
  vjs.plugin('freewheel', function init(options) {
    this.freewheel = new FreewheelPlugin(this, options);
  });
}
