import videojs from 'video.js';
import debug from 'debug';

// Setup debugging
const debugLog = debug('StartManager');

// Lowest number represents the highest priority
const START_MANAGER_PLUGIN_PRIORITY_INDEXES = {
  BROWSER_SUPPORT: 0,
  COUNTDOWN: 1,
  AGE_GATING: 2,
  SOURCE_LOADER: 3,
  FREEWHEEL: 4,
};

/**
 * This Plugin is respossible of organizing plugins that block the start of the video.
 * Plugins register a replacement handler for the play button. The handlers are run depending
 * on the priority set above. For plugins to release control have to call the 'removeVideoStartBehavior'
 * event.
 */
export default class StartManager {
  constructor(player) {
    this._player = player;
    this._handlers = [];
    this._playerOriginallyPaused = false;
    this._autoplay = false;

    this._player.on('registerVideoStartBehavior', this._registerBehavior.bind(this));
    this._player.on('removeVideoStartBehavior', this._removeBehavior.bind(this));
    this._player.on('mediaError', () => {
      this._playerOriginallyPaused = true;
    });

    this._ready = false;
    this._player.ready(() => {
      this._ready = true;
    });
  }

  _setMostPrioritizedPlayHandler(forcePlay) {
    if (this._handlers.length) {
      this._handlers.sort(this._compareByPriority);

      if (this._player.play !== this._handlers[0].playCallback) {
        this._log('Setting Play Behavior to', this._handlers[0].name);
        this._player.play = this._handlers[0].playCallback;
      }

      if (this._autoplay && this._ready) {
        this._log('Calling Play Handler', this._handlers[0].name);
        this._player.play();
      }
    } else {
      this._log('Restoring default Play Behavior');
      delete this._player.play;
      if (this._autoplay && typeof this._player.play === 'function' && (forcePlay || !this._playerOriginallyPaused)) {
        if (this._player.isReady_) {
          this._log('Calling default Play Behavior');
          this._player.play();
        } else {
          this._player.one('ready', () => {
            this._log('Calling default Play Behavior');
            this._player.play();
          });
        }
      }
    }
  }

  _registerBehavior(evt) {
    this._log('Registering Start Behavior', evt.name, this._handlers.map(_handler => _handler.name));
    const pluginName = evt.name;
    const originalOnPlay = evt.playHandler;
    const onPlay = () => {
      this._autoplay = true;
      evt.playHandler();
    };

    this._player.one('ready', () => {
      if (this._autoplay || this._player.autoplay()) {
        this._autoplay = true;
        this._log('Pausing the player');
        this._player.autoplay(false);
        this._player.pause();
        if (this._handlers.length === 1) {
          this._player.play();
        }
      } else {
        this._log('Player was not on autoplay - no need to pause');
        this._playerOriginallyPaused = true;
      }
    });

    this._handlers.push({
      name: pluginName,
      playCallback: onPlay,
      originalPlayCallback: originalOnPlay,
      priority: this._getPluginPriorityIndex(pluginName),
    });
    this._setMostPrioritizedPlayHandler();
  }

  _removeBehavior(evt) {
    const playHandler = evt.playHandler;

    this._handlers.forEach((handler, index) => {
      if (handler.originalPlayCallback === playHandler) {
        this._log('Remove Start Behavior', handler.name, this._handlers.map(_handler => _handler.name));
        this._handlers.splice(index, 1);
        if (index === 0) {
          // IF it is the first then we should run the next play handler
          this._setMostPrioritizedPlayHandler(evt.forcePlay);
        } else if (this._autoplay) {
          // IF it is autoplay then we should run the next play handler
          this._setMostPrioritizedPlayHandler(evt.forcePlay);
        }
      }
    });
  }

  _getPluginPriorityIndex(name) {
    const pluginIndex = START_MANAGER_PLUGIN_PRIORITY_INDEXES[name];
    if (pluginIndex === undefined) {
      throw new Error('Invalid plugin name provided to start manager');
    }
    return pluginIndex;
  }

  _compareByPriority(a, b) {
    if (a.priority > b.priority) return 1;
    if (a.priority < b.priority) return -1;

    return 0;
  }

  _log(...args) {
    debugLog(`[#${this._player.id()}]`, ...args);
  }
}

videojs.plugin('startManager', function init() {
  this.startManager = new StartManager(this);
});
