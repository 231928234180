import Promise from 'promise';
import debug from 'debug';

const debugLog = debug('avodplayer:connectivity-detector:bbab');

/**
 * Tries to detect the presence of BlockBlockAdBlock, Reek's Anti-Adblock Killer and similar
 */
export default class BlockBlockAdBlockDetector {

  check() {
    debugLog('checking');
    return new Promise((resolve, reject) => {
      if (this._hasBlockBlockAdBlocker()) reject('blockblockadblock');
      else resolve();
    });
  }

  _hasBlockBlockAdBlocker() {
    // BlockBlockAdBlock changes the property window.blockAdBlock to read-only to prevent BlockAdBlock from running.
    const blockAdBlockPropertyDescriptor = Object.getOwnPropertyDescriptor(window, 'blockAdBlock');
    const fuckAdBlockPropertyDescriptor = Object.getOwnPropertyDescriptor(window, 'fuckAdBlock');

    return (
      (blockAdBlockPropertyDescriptor && !blockAdBlockPropertyDescriptor.writable) ||
      (fuckAdBlockPropertyDescriptor && !fuckAdBlockPropertyDescriptor.writable)
    );
  }
}
