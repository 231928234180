/* eslint-disable max-len */
export default {
  sv: {
    'Ad playing': 'Videon startar om',
    'More info': 'Mer info',
    's remaining': 's',
    Subtitles: 'Undertexter',
    'Subtitles off': 'Av',
    'Default language': 'Svenska',
    'Default language for hearing impaired': 'Svenska för hörselskadade',
    'You aborted the media playback': 'Uppspelningen avbröts.',
    'A network error caused the media download to fail part-way.': 'Något gick fel och videon går tyvärr inte att spela just nu, försök igen om en stund.',
    'The media playback was aborted due to a corruption problem or because the media used features your browser did not support.': 'Något gick fel och videon går tyvärr inte att spela just nu, testa i en annan webbläsare eller försök att uppgradera till en nyare version.',
    'The media could not be loaded, either because the server or network failed or because the format is not supported.': 'Något gick fel och videon går tyvärr inte att spela just nu, försök igen om en stund.',
    'The media is encrypted and we do not have the keys to decrypt it.': 'Något gick fel och videon går tyvärr inte att spela just nu, försök igen om en stund.',
    'No compatible source was found for this media.': 'Något gick fel och videon går tyvärr inte att spela just nu, försök igen om en stund.',
    'This video is no longer available': 'Denna video är inte längre tillgänglig.',
    'The program starts': 'Sändningen startar',
    'The program starts in': 'Sändningen startar om',
    'Embed Video': 'Bädda in',
    'Share on social media': 'Dela på sociala medier',
    'Try again': 'Försök igen',
    'Your web browser is unfortunately not supported': 'Hej! Vi ser att du använder en webbläsare som inte stöds av oss.',
    // Pause ads
    'Advertisement: Click play to resume watching': 'Reklam: Tryck på play för att forsätta uppspelningen',
    'Go to the advertiser\'s page': 'Gå till annons',
    'Close ad': 'Stäng annons',
    // Chromecast
    'Casting to': 'Castar till', // Casting to <chrome cast device name>
    'Do you want to cast?': 'Vill du casta?', // Do you want to cast? <clicked episode>
  },
  da: {
    'Ad playing': 'Annonce: Videoen starter om',
    's remaining': 's',
    Subtitles: 'Undertekster',
    'Subtitles off': 'Sluk',
    'Default language': 'Dansk',
    'Default language for hearing impaired': 'Dansk for hørehæmmede',
    'You aborted the media playback': 'Afspilningen blev afbrudt.',
    'A network error caused the media download to fail part-way.': 'Der opstod en fejl og videoen kan desværre ikke afspilles, prøv igen senere.',
    'The media playback was aborted due to a corruption problem or because the media used features your browser did not support.': 'Der opstod en fejl og videoen kan desværre ikke afspilles, prøv med en anden browser eller at opdatere til en nyere version.',
    'The media could not be loaded, either because the server or network failed or because the format is not supported.': 'Der opstod en fejl og videoen kan desværre ikke afspilles, prøv igen senere.',
    'The media is encrypted and we do not have the keys to decrypt it.': 'Der opstod en fejl og videoen kan desværre ikke afspilles, prøv igen senere.',
    'No compatible source was found for this media.': 'Der opstod en fejl og videoen kan desværre ikke afspilles, prøv igen senere.',
    'This video is no longer available': 'Denne video er ikke længere tilgængelig.',
    'The program starts': 'Udsendelsen starter',
    'The program starts in': 'Udsendelsen starter om',
    'Embed Video': 'Embed',
    'Share on social media': 'Del på sociale medier',
    'Try again': 'Prøv igen',
    'Your web browser is unfortunately not supported': 'Hej! Vi kan se, du bruger en browser som ikke understøttes af os.',
    // Pause ads
    'Advertisement: Click play to resume watching': 'Advertisement: Click play to resume watching',
    'Go to the advertiser\'s page': 'Gå til annoncørens side',
    'Close ad': 'Luk reklamen',
    // Chromecast
    'Casting to': 'Caster til',
    'Do you want to cast?': 'Vil du caste?',
  },
  nb: {
    'Ad playing': 'Videoen starter om',
    's remaining': 's',
    Subtitles: 'Undertekster',
    'Subtitles off': 'Av',
    'Default language': 'Norsk',
    'Default language for hearing impaired': 'Norsk for hørselshemmede',
    'You aborted the media playback': 'Avspillingen ble avbrutt.',
    'A network error caused the media download to fail part-way.': 'På grunn av en nettverksfeil kan videoen dessverre ikke vises, prøv igjen om en stund.',
    'The media playback was aborted due to a corruption problem or because the media used features your browser did not support.': 'Noe gikk galt under avspillingen. Vennligst forsøk i en annen nettleser, eller oppdater din nettleser til en nyere versjon.',
    'The media could not be loaded, either because the server or network failed or because the format is not supported.': 'Noe gikk galt, og videoen kan ikke vises. Vennligst prøv igjen om en stund.',
    'The media is encrypted and we do not have the keys to decrypt it.': 'Noe gikk galt, og videoen kan ikke vises. Vennligst prøv igjen om en stund.',
    'No compatible source was found for this media.': 'Noe gikk galt, og videoen kan ikke vises. Vennligst prøv igjen om en stund.',
    'This video is no longer available': 'Denne videoen er ikke lenger tilgjengelig',
    'The program starts': 'Sendingen starter',
    'The program starts in': 'Sendingen starter om',
    'Embed Video': 'Legg inn',
    'Share on social media': 'Del på sosiale medier',
    'Try again': 'Prøv igjen',
    'Your web browser is unfortunately not supported': 'Hei! Vi ser at du bruker en nettleser som ikke støttes av oss.',
    // Pause ads
    'Advertisement: Click play to resume watching': 'Annonse: Trykk play for å se videre.',
    'Go to the advertiser\'s page': 'Gå til annonse',
    'Close ad': 'Lukk annonse',
    // Chromecast
    'Casting to': 'Caster til',
    'Do you want to cast?': 'Vil du caste?',
  },
  fi: {
    'Ad playing': 'Aikaa videon käynnistymiseen',
    'More info': 'Lisätietoja',
    's remaining': 's',
    Subtitles: 'Tekstitys',
    'Subtitles off': 'Ilman tekstitystä',
    'Default language': 'Suomi',
    'Default language for hearing impaired': 'Suomi kuulovammaisille',
    'You aborted the media playback': 'Katselu keskeytetty.',
    'A network error caused the media download to fail part-way.': 'Videota ei voida juuri nyt toistaa. Kokeile hetken kuluttua uudelleen.',
    'The media playback was aborted due to a corruption problem or because the media used features your browser did not support.': 'Videota ei voida juuri nyt toistaa. Kokeile toista selainta tai päivitä selaimen versio.',
    'The media could not be loaded, either because the server or network failed or because the format is not supported.': 'Videota ei voida juuri nyt toistaa. Kokeile hetken kuluttua uudelleen.',
    'The media is encrypted and we do not have the keys to decrypt it.': 'Videota ei voida juuri nyt toistaa. Kokeile hetken kuluttua uudelleen.',
    'No compatible source was found for this media.': 'Videota ei voida juuri nyt toistaa. Kokeile hetken kuluttua uudelleen.',
    'This video is no longer available': 'Tämä video ei ole enää saatavilla.',
    'The program starts in': 'Ohjelma alkaa',
    'The program starts': 'Ohjelma alkaa',
    'Embed Video': 'Upota video',
    'Share on social media': 'Jaa sosiaalisessa mediassa',
    'Try again': 'Yritä uudestaan',
    'Your web browser is unfortunately not supported': 'Hei! Nettiselaimesi ei ole tällä hetkelle tuettu',
    // Pause ads
    'Advertisement: Click play to resume watching': 'Mainos: Paina play jatkaaksesi katselua',
    'Go to the advertiser\'s page': 'Siirry Mainostajan sivuille',
    'Close ad': 'Sulje mainos.',
    // Chromecast
    'Casting to': 'Suoratoista',
    'Do you want to cast?': 'Haluatko suoratoistaa?',
  },
  et: {
    'Ad playing': 'Saate alguseni on jäänud',
    Subtitles: null,
    'Subtitles off': null,
    'Default language': null,
    'Default language for hearing impaired': null,
    'More info': 'Rohkem infot siit',
    's remaining': 's',
    'You aborted the media playback': 'Meedia edastus katkes',
    'A network error caused the media download to fail part-way.': 'Midagi läks valesti ja seda videot ei saa praegu mängida. Palun proovige hiljem uuesti.',
    'The media playback was aborted due to a corruption problem or because the media used features your browser did not support.': 'Midagi läks valesti ja seda videot ei saa praegu mängida. Palun proovige hiljem uuesti.',
    'The media could not be loaded, either because the server or network failed or because the format is not supported.': 'Midagi läks valesti ja seda videot ei saa praegu mängida. Palun proovige hiljem uuesti.',
    'The media is encrypted and we do not have the keys to decrypt it.': 'Midagi läks valesti ja seda videot ei saa praegu mängida. Palun proovige hiljem uuesti.',
    'No compatible source was found for this media.': 'Midagi läks valesti ja seda videot ei saa praegu mängida. Palun proovige hiljem uuesti.',
    'This video is no longer available': 'See video ei ole enam saadaval',
    'The program starts in': 'Saate alguseni on jäänud',
    'Embed Video': 'Embed Video',
    'Share on social media': 'Share on social media',
    'Try again': null,
    'Your web browser is unfortunately not supported': 'Tere! Kasutate veebibrauserit, mida leht ei toeta.',
    // Pause ads
    'Advertisement: Click play to resume watching': 'Reklaam: Jätkamiseks vajutage „mängi“',
    'Go to the advertiser\'s page': 'Kliki reklaamile ja loe lähemalt',
    'Close ad': 'Sulge reklaam',
    // Chromecast
    'Casting to': null,
    'Do you want to cast?': null,
  },
  lv: {
    'Ad playing': 'Reklama',
    Subtitles: null,
    'Subtitles off': 'Nav subtitri',
    'Default language': null,
    'Default language for hearing impaired': null,
    's remaining': 's',
    'You aborted the media playback': 'Video atskaņošana tika pārtraukta',
    'A network error caused the media download to fail part-way.': 'Kaut kas saplīsa, tādēļ video šobrīd nav atskaņojams. Pienāc vēlāk!',
    'The media playback was aborted due to a corruption problem or because the media used features your browser did not support.': 'Kaut kas saplīsa, tādēļ video šobrīd nav atskaņojams. Pienāc vēlāk!',
    'The media could not be loaded, either because the server or network failed or because the format is not supported.': 'Kaut kas saplīsa, tādēļ video šobrīd nav atskaņojams. Pienāc vēlāk!',
    'The media is encrypted and we do not have the keys to decrypt it.': 'Kaut kas saplīsa, tādēļ video šobrīd nav atskaņojams. Pienāc vēlāk!',
    'No compatible source was found for this media.': 'Kaut kas saplīsa, tādēļ video šobrīd nav atskaņojams. Pienāc vēlāk!',
    'This video is no longer available': 'Šis video vairs nav pieejams',
    'The program starts in': 'Pārraide sāksies pēc',
    'Embed Video': 'Embed Video',
    'Share on social media': 'Share on social media',
    'Try again': null,
    'Your web browser is unfortunately not supported': 'Izskatās, ka tiek izmantots jocīgs interneta pārlūks.',
    // Pause ads
    'Advertisement: Click play to resume watching': 'Reklāma | Nospied Play, lai turpinātu',
    'Go to the advertiser\'s page': 'Uzzināt vairāk',
    'Close ad': 'Aizvērt',
    // Chromecast
    'Casting to': null,
    'Do you want to cast?': null,
  },
  lt: {
    'Ad playing': 'Programą matysite po',
    Subtitles: null,
    'Subtitles off': null,
    'Default language': null,
    'Default language for hearing impaired': null,
    's remaining': 's',
    'You aborted the media playback': 'Nutrūko vaizdo transliacija',
    'A network error caused the media download to fail part-way.': 'Nutiko kažkas negero ir vaizdo transliacija šiuo metu neveikia. Prašome bandyti vėliau',
    'The media playback was aborted due to a corruption problem or because the media used features your browser did not support.': 'Nutiko kažkas negero ir vaizdo transliacija šiuo metu neveikia. Prašome bandyti vėliau',
    'The media could not be loaded, either because the server or network failed or because the format is not supported.': 'Nutiko kažkas negero ir vaizdo transliacija šiuo metu neveikia. Prašome bandyti vėliau',
    'The media is encrypted and we do not have the keys to decrypt it.': 'Nutiko kažkas negero ir vaizdo transliacija šiuo metu neveikia. Prašome bandyti vėliau',
    'No compatible source was found for this media.': 'Nutiko kažkas negero ir vaizdo transliacija šiuo metu neveikia. Prašome bandyti vėliau',
    'This video is no longer available': 'Šis vaizdo įrašas nebepasiekiamas',
    'The program starts in': 'Programą matysite po',
    'Embed Video': 'Embed Video',
    'Share on social media': 'Share on social media',
    'Try again': null,
    'Your web browser is unfortunately not supported': 'Deja, Jūs naudojate interneto naršyklę, kuri nėra palaikoma',
    // Pause ads
    'Advertisement: Click play to resume watching': 'Norint tęsti - spausk Play',
    'Go to the advertiser\'s page': 'Spausk čia – sužinok daugiau!',
    'Close ad': 'Uždaryti',
    // Chromecast
    'Casting to': null,
    'Do you want to cast?': null,
  },
  bg: {
    'Ad playing': 'Видеото ще започне след',
    Subtitles: null,
    'Subtitles off': null,
    'Default language': null,
    'Default language for hearing impaired': null,
    's remaining': 'секунди',
    'You aborted the media playback': null,
    'A network error caused the media download to fail part-way.': null,
    'The media playback was aborted due to a corruption problem or because the media used features your browser did not support.': null,
    'The media could not be loaded, either because the server or network failed or because the format is not supported.': null,
    'The media is encrypted and we do not have the keys to decrypt it.': null,
    'No compatible source was found for this media.': null,
    'The program starts in': 'Видеото ще започне след',
    'Embed Video': 'Embed Video',
    'Share on social media': 'Share on social media',
    'Try again': null,
    'Your web browser is unfortunately not supported': null,
    // Pause ads
    'Advertisement: Click play to resume watching': 'Натисни Play, за да продължи',
    'Go to the advertiser\'s page': 'Kъм Рекламодателя',
    'Close ad': 'Затвори',
    // Chromecast
    'Casting to': null,
    'Do you want to cast?': null,
  },
};
/* eslint-enable max-len */
