import videojs from 'video.js';
import debug from 'debug';
import MeetricsTracker from 'meetrics-tracker';

const debugLog = debug('meetrics:log');
const errorLog = debug('meetrics:error');

export default class Meetrics {
  constructor(player, options) {
    this._player = player;
    this._options = options || {};
    this._player.one('adRequestSucceeded', (evt, data) => {
      const { networkId, siteSection } = data.response;
      this._networkId = networkId;
      this._csid = siteSection.customId;
      this._setupListeners();
    });

    return this;
  }

  _initAd(ad) {
    const element = this._player.tech_.el_;
    const { mtrcsCollectorDomain, mtrcsProjectID } = this._options;
    const parameters = {
      mtrcsCollectorDomain,
      mtrcsProjectID,
      mtrcsZone: this._csid,
      mtrcsCreative: ad.id,
      mtrcsParameterA: ad.adType,
      mtrcsParameterC: `${element.clientWidth}x${element.clientHeight}`,
      mtrcsParameterE: this._networkId,
      adDuration: ad.duration,
      width: element.clientWidth,
      height: element.clientHeight,
      nodeId: element.id,
    };

    this._tracker = new MeetricsTracker(parameters);
    this._log('Initialized new tracker', parameters);
    this._meetricsEvent('startAd');
  }

  _meetricsEvent(event) {
    if (this._tracker && this._player.isPlayingAd()) {
      switch (event) {
        case 'resumeAd':
          // we only want to trigger resumeAd if the user actually resumes from pause
          if (this._adPaused) {
            this._tracker.resumeAd();
            this._log('Triggered', event);
          }
          break;
        case 'pauseAd':
          this._adPaused = true;
          this._tracker.pauseAd();
          this._log('Triggered', event);
          break;
        default:
          this._tracker[event]();
          this._log('Triggered', event);
          break;
      }
    }
  }

  _setupListeners() {
    this._player.on('adStart', ad => this._initAd(ad));
    this._player.on('adPlay', () => this._meetricsEvent('resumeAd'));
    this._player.on('adPause', () => this._meetricsEvent('pauseAd'));
    this._player.on('adEnd', () => this._meetricsEvent('stopAd'));
    this._player.on('fullscreenEnter', () => this._meetricsEvent('enterFullscreen'));
    this._player.on('fullscreenClose', () => this._meetricsEvent('leaveFullscreen'));
    this._player.on('adClickThrough', () => this._meetricsEvent('clickAd'));
  }

  _log(...args) {
    debugLog(`[#${this._player.id()}]`, ...args);
  }

  _error(...args) {
    errorLog(`[#${this._player.id()}]`, ...args);
    console.error('> avodp - meetrics error >>', args[0]); // eslint-disable-line no-console
  }
}

videojs.plugin('meetrics', function init(options) {
  new Meetrics(this, options);
});
