import vjs from 'video.js';

const Component = vjs.getComponent('Component');

export default vjs.extend(Component, {
  onCloseClick: () => {},

  createEl: function createEl() {
    const overlayComponent = Component.prototype.createEl.call(this, 'div', {
      className: 'ad-overlay',
    });

    const closeButton = document.createElement('div');
    closeButton.className = 'ad-overlay-close-btn';
    closeButton.innerHTML = '×';
    closeButton.addEventListener('click', () => {
      this.onCloseClick();
    });
    this._closeButton = closeButton;

    overlayComponent.appendChild(closeButton);

    const overlayContent = document.createElement('div');
    overlayContent.className = 'ad-overlay-content';

    overlayComponent.appendChild(overlayContent);

    return overlayComponent;
  },
});
