/**
 * This button is a custom mute/unmute volume control used on mobile devices
 */
import debug from 'debug';
import vjs from 'video.js';

// Setup debugging
const debugLog = debug('MtgxSkinPlugin-MobileVolumeButton');

const VjsButton = vjs.getComponent('ClickableComponent');

export default class MobileVolumeButton extends VjsButton {
  constructor(player) {
    super();

    this._player = player;
    this._initialUnmute = false;
    this._updateUI();
  }

  createEl() {
    return VjsButton.prototype.createEl.call(this, 'div', {
      className: 'vjs-mute-control x-mobile-volume vjs-control',
    }, {
      role: 'button',
      'aria-live': 'polite',
    });
  }

  handleClick() {
    this._toggleVolume();
  }

  _toggleVolume() {
    if (this._player.muted()) this._unmute();
    else this._mute();
  }

  _mute() {
    // State
    this._player.muted(true);
    this._player.tech_.el().setAttribute('muted', 'muted');
    this._player.volume(0);

    // Style
    this._player.addClass('mobile-muted');
    this.addClass('vjs-vol-0');

    // Tracking
    this._player.trigger('mobileMute');

    // This is in case the player starts unmuted
    this._initialUnmute = true;
  }

  _unmute() {
    // State
    this._player.muted(false);
    this._player.tech_.el().setAttribute('muted', null);
    this._player.volume(1);

    // Style
    this._player.removeClass('mobile-muted');
    this.removeClass('vjs-vol-0');

    // Tracking
    let eventName;
    if (!this._initialUnmute) {
      eventName = 'initialMobileUnmute';
      eventName += this._player.isPlayingAd() ? 'InAd' : 'InContent';

      this._initialUnmute = true;
    } else {
      eventName = 'mobileUnmute';
    }

    this._player.trigger(eventName);
  }

  _updateUI() {
    if (this._player.muted() ||
      this._player.tech_.el().getAttribute('muted') === 'muted') {
      this._player.addClass('mobile-muted');
      this.addClass('vjs-vol-0');
    } else {
      this._player.removeClass('mobile-muted');
      this.removeClass('vjs-vol-0');
    }
  }

  _log(...args) {
    debugLog(`[#, ${this._player.id()}]`, ...args);
  }
}
