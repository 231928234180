import vjs from 'video.js';
import _difference from 'lodash.difference';
import ns_ from 'streamsense';
import udm_ from 'comscore-udm';
import debug from 'debug';
import meta from '../../../src/util/meta';

// Setup debugging
const debugLog = debug('comscore:log');
const errorLog = debug('comscore:error');
errorLog.log = console.error.bind(console); // eslint-disable-line no-console

function formatDate(date) {
  const year = date.getUTCFullYear().toString();
  let month = (date.getUTCMonth() + 1).toString();

  if (month.length === 1) {
    month = `0${month}`;
  }

  let day = date.getUTCDate().toString();

  if (day.length === 1) {
    day = `0${day}`;
  }

  return year + month + day;
}

const DEFAULT_OPTIONS = {
  labels: {
    defaults: {
      name: 'html5.stream',
      c1: 2,
      ns_st_it: 'c',
      ns_st_dt: formatDate(new Date()),
      ns_st_mp: `${meta.playerName} ${meta.playerVersion}`,
      ns_st_mv: `${meta.playerVersion}`,
    },
  },
};

const SEEKING_OPTIONS = {
  ns_st_ui: 'seek',
};

const RECOGNIZED_LABELS = [
  'ns_site', // site name e.g. tv3, tv6, juicyplay, etc.
  'name', // page name
  'c1', // beacon type, should be 2
  'mms_clnr', // MMS client number
  'mms_subsite', // the domain (or the parent domain if it's embedded) the player is on
  'mms_tid', // unique title id
  'mms_auto', // auto started playback

  'ns_st_it', // implementation type 'c' = content
  'ns_st_dt', // date (YYYY-MM-DD)
  'ns_st_ep', // episode name
  'ns_st_mp', // Player name + version
  'ns_st_mv', // Player version
  'ns_st_pl', // playlist name
  'ns_st_pr', // format name
  'ns_st_st', // channel name
  'ns_st_cl', // content length in ms
];

const REQUIRED_LABELS = {
  defaults: [
    'ns_site',
    'name',
    'c1',
    'mms_clnr',
    'mms_subsite',
    'mms_tid',
    'mms_auto',
    'ns_st_ep',
    'ns_st_pl',
    'ns_st_pr',
    'ns_st_st',
  ],
};

const STATES = {
  beforeStart: undefined,
  play: 'play',
  pause: 'pause',
  seeking: 'seeking',
  buffering: 'buffering',
  end: 'end',
  adPlay: 'adPlay',
  adPause: 'adPause',
  adEnd: 'adEnd',
};

export default class ComscoreTrackingPlugin {
  constructor(player, options) {
    this._player = player;
    this._validated = false;

    if (!options || options.enabled === false) {
      this._log('Comscore tracking disabled');
      return;
    }

    this._options = vjs.mergeOptions(DEFAULT_OPTIONS, options);

    const errors = this._validateOptions(this._options);
    if (errors.length) {
      this._error('Invalid comscore tracking configuration', errors, 'Comscore tracking disabled');
      return;
    }
    this._validated = true;

    this._mediaDuration = null; // Not in the reset method because it won't change
    this._resetInternalState();

    this._init();
  }

  _init() {
    if (!window.udm_initialized) {
      udm_(`https://sb.scorecardresearch.com/b?c1=${this._options.labels.defaults.c1}&c2=${this._options.customerC2}&ns_site=${this._options.labels.defaults.ns_site}`);
      window.udm_initialized = true;
      this._log('udm initialized by player');
    } else {
      this._log('udm already initialized by page script');
    }

    this._streamSense = new ns_.StreamSense();
    this._streamSense.setLabels(this._options.labels.defaults);

    this._player.one('mediaStart', this._onMediaStart.bind(this));
    this._player.on('mediaPlay', this._onMediaPlay.bind(this));
    this._player.on('mediaPause', this._onMediaPause.bind(this));
    this._player.on('mediaSeekStart', this._onMediaSeekStart.bind(this));
    this._player.on('mediaSeekComplete', this._onMediaSeekComplete.bind(this));
    this._player.on('mediaBuffer', this._onMediaBuffer.bind(this));
    this._player.on('mediaEnd', this._onMediaEnd.bind(this));
    this._player.on('adRollStart', this._onAdRollStart.bind(this));
    this._player.on('adRollEnd', this._onAdRollEnd.bind(this));
    this._player.on('adInPrerollStart', this._onAdInRollStart.bind(this));
    this._player.on('adInMidrollStart', this._onAdInRollStart.bind(this));
    this._player.on('adInPostrollStart', this._onAdInRollStart.bind(this));
    this._player.on('adInPrerollFinish', this._onAdInRollEnd.bind(this));
    this._player.on('adInMidrollFinish', this._onAdInRollEnd.bind(this));
    this._player.on('adInPostrollFinish', this._onAdInRollEnd.bind(this));
    this._player.on('adPlay', this._onAdPlay.bind(this));
    this._player.on('adPause', this._onAdPause.bind(this));
    this._player.on('dispose', this._onDispose.bind(this));
    this._player.on('controlEnded', this._resetInternalState.bind(this));

    window.onunload = this._onDispose.bind(this);
  }

  _onMediaStart() {
    this._log(`Comscore MEDIA START: [${this._player.currentTime()},${this._player.duration()}] ${this._currentState}`);

    this._mainContentClipNumber = this._getNextClipNumber();
    // On android we need to wait for duration
    if (this._player.duration() === Infinity) {
      this._options.live = true;
      this._mediaDuration = 0;
    } else {
      this._mediaDuration = this._player.getMediaDuration();
    }

    this._streamSense.setLabel('ns_st_el', this._calculateTimeInMillis(this._mediaDuration)); // Asset length
    this._streamSense.setLabel('ns_st_ca', this._calculateTimeInMillis(this._mediaDuration));
    this._streamSense.setLabel('ns_st_cu', this._player.currentSrc());

    this._hasSentFirstPlayEvent = true;

    this._onMediaPlay(true);
  }

  _onMediaPlay(isMediaStart) {
    if (this._currentState === STATES.play) return;

    // [PLP-8836] In Chrome 56 we trigger media play before we have a duration, so we need to wait for media start
    if (!this._hasSentFirstPlayEvent && this._mediaDuration === null) return;

    // If current state is adEnd and current time is 0 then we are resuming play after a midroll, we should not send a
    // play event until after the seeking.
    if (!isMediaStart && this._currentState === STATES.adEnd && this._player.currentTime() === 0) return;

    this._log(`Comscore MEDIA PLAY: ${this._player.currentTime()}`);
    if (this._currentState === STATES.pause) {
      this._notifyUnPause();
    } else {
      this._notifyMediaPlay(isMediaStart);
    }
    this._currentState = STATES.play;
  }

  _onAdRollStart() {
    if (!this._currentState) return;
    this._log('Comscore AD ROLL START');
    // End the current media part before starting the ad clips
    this._notifyEnd(this._mainContentClipNumber, this._player.currentTime());
  }

  _onAdRollEnd() {
    this._getNextPartNumber();
  }

  _onAdInRollStart(event, ad) {
    this._log(`Comscore AD IN ${ad.adType} START`);
    this._notifyAdInRollStart(ad);
  }

  _onAdInRollEnd() {
    if (this._currentState === STATES.adEnd || !this._currentAd) return;
    this._log(`Comscore AD IN ROLL END: ${this._player.currentTime()}`);

    this._currentState = STATES.adEnd;

    let time;
    if (Math.ceil(this._player.currentTime()) < this._currentAd.duration) {
      time = this._player.currentTime();
    } else {
      time = this._currentAd.duration;
    }

    this._notifyEnd(null, time);
  }

  _onAdPlay() {
    if (this._currentState !== STATES.adPause) return;
    this._log(`Comscore AD PLAY: ${this._player.currentTime()}`);
    this._currentState = STATES.adPlay;
    this._notifyUnPause();
  }

  _onAdPause() {
    if (this._currentState !== STATES.adPlay) return;
    this._log(`Comscore AD PAUSE: ${this._player.currentTime()}`);

    // It sends a pause event just before ending that we want to avoid.
    if (this._player.ended()) return;

    this._currentState = STATES.adPause;
    this._notifyPause();
  }

  _onMediaEnd() {
    if (this._currentState === STATES.end || this._currentState === STATES.adEnd) return;
    this._log(`Comscore MEDIA END: ${this._player.currentTime()}`);

    this._currentState = STATES.end;
    this._notifyEnd(this._mainContentClipNumber, Math.min(this._mediaDuration, this._player.currentTime()));
  }

  _onMediaPause() {
    if (
      this._currentState === STATES.end ||
      this._currentState === STATES.seeking ||
      this._currentState === STATES.pause
    ) {
      return;
    }

    this._log(`Comscore MEDIA PAUSE: ${this._player.currentTime()}`);

    // It sends a pause event just before ending that we want to avoid.
    if (this._player.ended()) return;

    this._currentState = STATES.pause;
    this._notifyPause();
  }

  _onMediaSeekStart(evt) {
    if (evt.beforeSeekTime === 0) return;
    this._log(`Comscore MEDIA SEEK START: ${evt.beforeSeekTime}`);

    this._currentState = STATES.seeking;
    this._notify(
      ns_.StreamSense.PlayerEvents.PAUSE,
      SEEKING_OPTIONS,
      this._calculateTimeInMillis(evt.beforeSeekTime)
    );
  }

  _onMediaSeekComplete() {
    if (this._currentState !== STATES.seeking || this._player.paused()) return;

    this._log(`Comscore MEDIA SEEK COMPLETE: ${this._player.currentTime()}`);

    this._currentState = STATES.play;
    this._notifyPlay();
  }

  _onMediaBuffer() {
    const time = this._player.currentTime();

    if (
      !time ||
      this._currentState === STATES.seeking ||
      this._currentState === STATES.end
    ) {
      return;
    }

    // If current state is adEnd then we are resuming play after a midroll, we should not send a
    // buffer event before starting the vod
    if (!this._currentState === STATES.adEnd) {
      this._log(`Comscore BUFFER: ${time}`);
      this._notify(ns_.StreamSense.PlayerEvents.BUFFER);
    }
    this._currentState = STATES.buffering;

    this._player.one('playing', () => {
      // This is to get back into play mode after buffering that is not due to seeking
      // (aka after midrolls rolls)
      if (this._currentState === STATES.buffering) {
        this._log('Comscore PLAY AFTER BUFFERING');
        this._onMediaPlay();
      }
    });
  }

  _onDispose() {
    this._log(`Comscore DISPOSE: ${this._player.currentTime()}`);
    try {
      this._onAdInRollEnd();
      this._onMediaEnd();
    } catch (e) { /* On error do nothing since we are disposing the player */ }
  }

  _notifyMediaPlay(isMediaStart) {
    const time = this._player.currentTime();

    if (!isMediaStart && !time && this._currentState !== STATES.end && this._currentState !== STATES.adEnd) return;

    const parameters = {
      ns_st_pn: this._partNumberCount,
      ns_st_tp: 0,
      ns_st_cn: this._mainContentClipNumber,
      ns_st_ty: this._options.live ? 'live' : 'vod',
      ns_st_cl: this._calculateTimeInMillis(this._mediaDuration),
      ns_st_li: this._options.live ? 1 : null,
    };

    this._currentState = STATES.play;
    this._notifyPlay(parameters);
  }

  _notifyAdInRollStart(ad) {
    const parameters = {
      ns_st_pn: 1,
      ns_st_tp: 1,
      ns_st_cn: this._getNextClipNumber(),
      ns_st_ty: 'advertisement',
      ns_st_cl: this._calculateTimeInMillis(ad.duration),
      ns_st_ad: ad.adType.toLowerCase(),
      mms_customadid: ad.customId || '0',
      mms_adid: ad._adId,
    };

    this._currentState = STATES.adPlay;
    this._currentAd = ad;
    this._notifyPlay(parameters, 0);
  }

  _notifyPlay(parameters, time) {
    // If we have neither clip labels nor parameters we probably started playing with a seek and
    // didn't get the flrst play event. We can't track play events without the correct parameters.
    if (!this._clipLabels && !parameters) {
      this._notifyMediaPlay();
      this._currentState = STATES.playing;
      return;
    }

    const options = {};

    if (parameters) {
      const defaultParameters = {
        ns_st_cn: null, // clip number
        ns_st_pn: 1, // episode segment number
        ns_st_tp: 0, // total number of episode segments (content parts between ad rolls) or 0 if unknown
        ns_st_ty: null, // video type 'vod', 'live', 'advertisment'
        ns_st_cl: null, // The duration of the advertisement in milliseconds
        ns_st_li: null, // live 1 = true, 0 = false
        ns_st_ad: null, // 'preroll' | 'midroll' | 'postroll'
        mms_customadid: null, // The unique id of the Ad (Film code).
        mms_adid: null, // Set to the internal Ad ID provided by the Ad Server
      };

      const mergedParameters = vjs.mergeOptions(defaultParameters, parameters);

      this._clipLabels = mergedParameters;
      this._streamSense.setClip(this._clipLabels);

      // for some reason we can't just use setLabel to set 'ns_st_cl' - the label gets set but 'ns_st_cl'
      // is always 0 in the actual request so we override it here instead
      options.ns_st_cl = mergedParameters.ns_st_cl;

      if (this._currentState === STATES.seeking) options.ns_st_ui = SEEKING_OPTIONS.ns_st_ui;
    }

    this._notify(ns_.StreamSense.PlayerEvents.PLAY, options, time);
  }

  _notifyEnd(clipNumber, timeInSeconds) {
    if (!this._clipLabels) return; // Don't notify if not started, disposed, or not playing Ad

    this._clipLabels.ns_st_cn = clipNumber || this._clipNumberCount;
    this._streamSense.setClip(this._clipLabels);
    this._notify(ns_.StreamSense.PlayerEvents.END, {}, this._calculateTimeInMillis(timeInSeconds));
    this._currentAd = null;
  }

  _notifyPause() {
    this._notify(ns_.StreamSense.PlayerEvents.PAUSE);
  }

  _notifyUnPause() {
    this._notify(ns_.StreamSense.PlayerEvents.PLAY);
  }

  _notify(type, options, time) {
    let _time = time;
    if (_time === undefined) {
      _time = this._calculateTimeInMillis(this._player.currentTime()) || 1;
    }
    if (this._player.isLive() && !this._player.isPlayingAd()) _time = 0;

    this._log('REPORT >>', this._getStreamSenseEventTypeName(type), _time, options || {}, this._clipLabels);
    this._streamSense.notify(
      type,
      options || {},
      _time
    );
  }

  _getStreamSenseEventTypeName(value) {
    const events = ['PLAY', 'PAUSE', 'END', 'BUFFER', 'KEEP_ALIVE', 'HEART_BEAT',
      'CUSTOM', 'AD_PLAY', 'AD_PAUSE', 'AD_END', 'AD_CLICK'];

    return (value < events.length) ? events[value] : 'UNKNOWN EVENT';
  }

  _getNextClipNumber() {
    this._clipNumberCount += 1;
    return this._clipNumberCount;
  }

  _getNextPartNumber() {
    this._partNumberCount += 1;
    return this._partNumberCount;
  }

  _resetInternalState() {
    this._log('Comscore RESET INTERNAL STATE');

    this._currentState = STATES.beforeStart;
    this._clipNumberCount = 0;
    this._partNumberCount = 0;
    this._mainContentClipNumber = 0;
    this._hasSentFirstPlayEvent = false;
  }

  _calculateTimeInMillis(time) {
    if (time === null || time === undefined) return undefined;
    return Math.round(time * 1000);
  }

  _validateOptions(options) {
    const errors = [];

    if (!options.customerC2) errors.push('options.customerC2 needs to be specified');
    if (!options.labels) {
      errors.push('options.labels needs to be specified');
    } else if (!options.labels.defaults) {
      errors.push('options.labels.defaults needs to be specified');
    }

    REQUIRED_LABELS.defaults.forEach((label) => {
      if (typeof options.labels.defaults[label] === 'undefined') {
        errors.push(`Required label defaults.${label} is missing`);
      }
    });

    const unrecognizedLabels = _difference(Object.keys(options.labels.defaults), RECOGNIZED_LABELS);
    if (unrecognizedLabels.length > 0) {
      errors.push(`Unrecognized label(s) ${unrecognizedLabels.join(', ')}`);
    }

    if (/\//.test(options.labels.defaults.mms_subsite)) {
      errors.push('mms_subsite should be the domain, not the full path');
    }

    if ((options.labels.defaults.ns_st_pl === options.labels.defaults.ns_st_pr ||
      options.labels.defaults.ns_st_pl === options.labels.defaults.ns_st_ep ||
      options.labels.defaults.ns_st_pr === options.labels.defaults.ns_st_ep)) {
      this._log('ns_st_pl, ns_st_pr and ns_st_ep should normally be different');
    }

    return errors;
  }

  _log(...args) {
    debugLog(`[#${this._player.id()}]`, ...args);
  }

  _error(...args) {
    errorLog(`[#${this._player.id()}]`, ...args);
    console.error('> avodp - comscore error >>', args); // eslint-disable-line no-console
  }
}

vjs.plugin('comscoreTracking', function init(options) {
  this.comscoreTracking = new ComscoreTrackingPlugin(this, options);
});
