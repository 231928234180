import videojs from 'video.js';

export class Cuepoint {
  constructor(player, options) {
    this.player = player;
    this.time = options.time;
    this.callback = options.callback;
    this.name = options.name;
    this.data = options.data || {};
    this.active = false;
    this._lastCalledTimestamp = null;
  }

  _process() {
    // Check if cuepoint time is within the current second
    const currentTime = this.player.currentTime();
    if (this.time <= currentTime && currentTime <= this.time + 1) {
      const now = Date.now();

      if (this._lastCalledTimestamp && (now - this._lastCalledTimestamp) < 1000) {
        // We ignore any attempts to trigger the callback if they happen less than 2s
        // between each other. Timeupdate events are very frequent so we need to wait
        // for the currentTime to move one second beyond.
        return;
      }

      if (this.callback(this, this.name, this.data)) this.deactivate();

      this._lastCalledTimestamp = now;
    }
  }

  activate() {
    this.processHandler = this._process.bind(this);
    this.player.on('timeupdate', this.processHandler);
    this.active = true;
  }

  deactivate() {
    this.player.off('timeupdate', this.processHandler);
    this.active = false;
    this.processHandler = null;
  }
}

export class Cuepoints {
  constructor(player) {
    this._player = player;

    this._player.on('addCuepoint', (options) => {
      this._addCuepoint(options);
    });
  }

  _addCuepoint(options) {
    const cuepoint = new Cuepoint(this._player, options);
    cuepoint.activate();
    return cuepoint;
  }
}

videojs.plugin('cuepoints', function init(options) {
  this.cuepoints = new Cuepoints(this, options);
});
