import debug from 'debug';
import { EventEmitter } from 'events';
import Promise from 'promise';
import * as detectors from './detectors';
import utils from './utils';

const debugLog = debug('avodplayer:connectivity-detector');

const events = new EventEmitter();

const defaultBaitURLs = [
  'https://mssl.fwmrm.net/p/MTG_Brightcove_HTML5/AdManager.js',
  'https://freewheel-mtgx-tv.akamaized.net/m/1/{RANDOM}/a/{RANDOM}/{RANDOM}_1024x576.mp4',
  'https://5d3e9.v.fwmrm.net/ad/l/1',
  'https://5d3e9.v.fwmrm.net/ad/g/1',
];

class ConnectivityDetector {
  constructor() {
    debugLog('Connectivity detection init!');

    this.check = utils.throttle(this.check.bind(this), 2000);

    this.on = this.on.bind(this);
    this.one = this.one.bind(this);
    this.trigger = this.trigger.bind(this);

    this.UNKNOWN = 'unknown';
    this.AD_BLOCKER = 'detected'; // Ad blocker detected
    this.DISCONNECTED = 'disconnected'; // Disconnected from internet
    this.NO_AD_BLOCKER = 'notDetected'; // No adblocker and connected to internet

    this._state = this.UNKNOWN;

    this._options = [];

    this._detectors = [
      new detectors.AdBlock(),
      new detectors.BlockBlockAdBlock(),
      ...defaultBaitURLs.map(url => new detectors.UrlAvailability(url)),
    ];

    setTimeout(this.check, 1000);
  }

  // Check for ad blockers
  check() {
    if (this._isChecking) return Promise.resolve();

    this._isChecking = true;
    debugLog('checking');

    return Promise
      .all(this._detectors.map(detector => detector.check()))
      .then(() => {
        this._isChecking = false;
        debugLog('no ad-blocker');
        this._trigger(this.NO_AD_BLOCKER);
      })
      .catch((message) => {
        this._isChecking = false;
        debugLog('found ad-blocker', message);

        // If we have internet then it's probably an adblocker otherwise we're probably just disconnected
        utils.hasInternetAccess()
          .then(this._trigger(this.AD_BLOCKER))
          .catch(this._trigger(this.DISCONNECTED));
      });
  }

  /**
   * For external plugins to trigger connectivity issues
   * @param {String} status the new status type to set
   */
  trigger(status) {
    if (status === 'detected') {
      debugLog('found ad-blocker', 'external');
      this._trigger(status);
    }
    this._trigger(status);
  }

  _trigger(status) {
    if (this._state === status) return; // never trigger events for the the same status twice

    this._state = status;
    events.emit(status);
  }

  on(eventType, callback) {
    events.on(eventType, callback);
    if (this._state === eventType) callback();
  }

  one(eventType, callback) {
    events.once(eventType, callback);
    if (this._state === eventType) callback();
  }

  isDetected() {
    return this._state === this.AD_BLOCKER || this._state === this.DISCONNECTED;
  }
}

const connectivityDetector = new ConnectivityDetector();

export default connectivityDetector;
