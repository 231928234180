import videojs from 'video.js';
import debug from 'debug';
import superagent from 'superagent';

import meta from '../../../src/util/meta';

const debugLog = debug('client-stream:log');
const errorLog = debug('client-stream:error');

const clientEventNames = {
  pause: 1,
  unload: 2,
  running: 3,
  startOver: 4,
  gotoLive: 5,
  scrub: 6,
  buffering: 9,
  videoStartFailure: 10,
  exitBeforeStart: 11,
  resume: 12,
  play: 13,
  ad_start: 14,
  ad_stop: 15,
};

export default class ClientStream {
  constructor(player, incomingOptions) {
    this._player = player;
    this._reset();
    const errors = this._validateOptions(incomingOptions);
    if (errors.length) {
      this._error('Invalid client-stream Configuration', errors);
      this.errors = errors;
    }

    this._options = this._formatOptionsForCSE(incomingOptions);

    this._player.on('adRollStart', () => this._setPrerollListener());
    this._player.on('mediaError', () => this._onMediaError());

    return this;
  }

  _setPrerollListener() {
    this._log('_setPrerollListener');
    this._prerollHasStarted = true;
  }

  _setStreamListener() {
    this._log('_setStreamListener');
    this._player.one('gotSourceUrl', ({ source }) => this._requestSession(source));
  }

  _requestSession(source) {
    const mediaUrlArr = source.split('?');
    return this._createReporting(mediaUrlArr[0])
      .then(() => this._setUpListeners())
      .catch(error => this._error(error));
  }

  _createErrorReporting() {
    return this._createReporting(window.location.href)
      .then(() => this._trackEvent({
        actionType: clientEventNames.videoStartFailure,
        errorObject: this._player.error(),
      }))
      .catch(error => this._error(error));
  }

  _createReporting(mediaUrl) {
    this._options.data.mediaUrl = mediaUrl;
    this._options.data.correlationId = this._getCorrelationId();
    this._options.data.clientVersion = meta.playerVersion;

    if (this._options.durationInSeconds) {
      this._options.data.duration = parseInt(this._options.durationInSeconds * 1000, 10);
    }
    const url = `${this._options.base}/session/${this._options.brand}`;

    this._log('_requestSession', this._options.data);
    return superagent.post(url).send(this._options.data)
      .then((response) => {
        if (response && response.body && response.body.link &&
            response.body.link.reportingUrl && response.body.link.reportingUrl.href &&
            response.body.data && response.body.data.updateInterval) {
          this._reportingUrl = response.body.link.reportingUrl.href
                              .replace('{mtg-api-domain}', this._options.mtgApiDomain);
          this._updateInterval = parseInt(response.body.data.updateInterval / 1000, 10) || 60;
        } else {
          throw new Error('Wrong response', response);
        }
        return true;
      });
  }

  _reset() {
    this._sequenceNumber = 0;
    this._startReportTimeStamp = Date.now();
    this._reportingUrl = '';
    this._updateInterval = 60;

    this._lastTimeUpdate = 0;
    this._bufferingStartTimeStamp = 0;
    this._isSeeking = false;
    this._seekStartTimeStamp = 0;
    this._carry = 0;
    this._beforeSeekTime = 0;
    this._prerollHasStarted = false;
    this._adMode = false;
  }

  _setUpListeners() {
    this._log('_setUpListeners');
    this._player.off('adRollStart', () => this._setPrerollListener());
    if (this._prerollHasStarted) {
      this._prerollHasStarted = false;
      this._onAdRollStart();
    }
    this._player.one('mediaStart', () => this._onMediaStart());
    this._player.on('mediaPause', () => this._onMediaPause());
    this._player.on('mediaEnd', () => this._onMediaEnd());

    this._player.on('timeupdate', () => this._onTimeupdate());
    this._player.on('firstTimeUpdateAfterAdEnd', () => this._onFirstTimeUpdateAfterAdEnd());

    this._player.on('mediaSeekStart', () => this._onMediaSeekStart());
    this._player.on('mediaSeekComplete', data => this._onMediaSeekComplete(data));

    this._player.on('adRollStart', () => this._onAdRollStart());
    this._player.on('adRollEnd', () => this._onAdRollEnd());

    this._player.on('dispose', () => this._onDispose());

    this._player.on('mediaBufferStart', () => this._onMediaBufferStart());
    this._player.on('mediaBufferEnd', () => this._onMediaBufferEnd());
    this._player.on('forceLiveAfterPause', () => this._onForceLiveAfterPause());
    this._player.on('controlEnded', () => this._onControlEnded());
  }

  /* EVENTS */

  _onMediaStart() {
    this._trackEvent({
      actionType: clientEventNames.play,
      injectedStartTime: this._getInjectedStarttime(),
    });
  }

  _onMediaPause() {
    this._trackEvent({ actionType: clientEventNames.pause });
  }

  _onMediaEnd() {
    this._trackEvent({ actionType: clientEventNames.pause });
  }

  _onForceLiveAfterPause() {
    this._trackEvent({ actionType: clientEventNames.gotoLive });
  }

  _onControlEnded() {
    this._player.one('mediaPlay', () => {
      this._log('-- startOver');
      this._seekStartTimeStamp = Date.now();
      this._onMediaSeekComplete({ afterSeekTime: 0 });
    });
  }

  _onMediaSeekStart() {
    this._seekStartTimeStamp = Date.now();
    if (this._isSeeking) return;

    // First time when we run the seek start event during scrubbing
    this._beforeSeekTime = this._player.getMediaCurrentTime();
    this._carry += this._beforeSeekTime - this._lastTimeUpdate;
    this._isSeeking = true;
    this._log(`First seek start bst: ${this._beforeSeekTime}, ltu: ${this._lastTimeUpdate}, carry: ${this._carry}`);

    this._player.one('playing', () => this._reportSeekEnd());
  }

  _onMediaSeekComplete({ afterSeekTime }) {
    this._lastTimeUpdate = afterSeekTime;
    this._log(`-- Seek End, bst: ${this._beforeSeekTime}, ast: ${afterSeekTime}, ltu: ${this._lastTimeUpdate}`);
  }

  _onAdRollStart() {
    this._reportSeekEnd();
    this._adMode = true;
    this._trackEvent({ actionType: clientEventNames.pause });
    this._trackEvent({ actionType: clientEventNames.ad_start });
  }

  _onAdRollEnd() {
    this._trackEvent({ actionType: clientEventNames.ad_stop });
  }

  _onDispose() {
    if (!this._player.hasStarted()) this._trackEvent({ actionType: clientEventNames.exitBeforeStart });
    this._trackEvent({ actionType: clientEventNames.unload });
    this._reset();
  }

  _onMediaError() {
    if (!this._reportingUrl || this._reportingUrl === '') {
      this._createErrorReporting();
    } else {
      this._trackEvent({
        actionType: clientEventNames.videoStartFailure,
        errorObject: this._player.error(),
      });
    }
  }

  _onFirstTimeUpdateAfterAdEnd() {
    this._adMode = false;
  }

  _onMediaBufferStart() {
    this._log('-- buffer start');
    this._bufferingStartTimeStamp = Date.now();
    this._trackEvent({ actionType: clientEventNames.buffering });
  }

  _onMediaBufferEnd() {
    this._log('-- running start');
    const startupTime = Math.floor(Date.now() - this._bufferingStartTimeStamp);
    this._trackEvent({ actionType: clientEventNames.running, startupTime });
  }

  _onTimeupdate() {
    const currentTime = this._player.currentTime();

    if (this._isSeeking ||            // our seek handling: stays longer than the player ones
      this._player.seeking() ||       // player seek handling: start earlyer than ours
      this._player.scrubbing() ||
      this._player.isPlayingAd() ||
      this._adMode ||
      currentTime === 0               // between ad and media it could be 0
    ) return;


    if (Math.abs(this._lastTimeUpdate - currentTime) >= this._updateInterval - this._carry) {
      this._log(`-- running, ltu: ${this._lastTimeUpdate}, curr: ${currentTime}, carry: ${this._carry}`);
      this._lastTimeUpdate = currentTime;
      this._carry = 0;
      this._beforeSeekTime = 0;
      this._trackEvent({ actionType: clientEventNames.running });
    }
  }

  _reportSeekEnd() {
    if (this._isSeeking) {
      this._isSeeking = false;
      const startupTime = Math.floor(Date.now() - this._seekStartTimeStamp);
      const isSeekToStarttime = (this._beforeSeekTime === 0) ? this._getInjectedStarttime() : null;
      if (!isSeekToStarttime) {
        this._trackEvent({ actionType: clientEventNames.scrub, startupTime });
      }
    }
  }

  _trackEvent({ actionType, startupTime, errorObject, injectedStartTime }) {
    this._sequenceNumber += 1;
    const duration = parseInt(this._player.getMediaDuration() * 1000, 10) || this._options.data.duration || 1;
    const deltaTime = Math.floor(Date.now() - this._startReportTimeStamp) || 1;
    const position = parseInt(this._player.getMediaCurrentTime() * 1000, 10) || 1;

    const reportPayload = {
      bitrate: '',
      correlationId: this._getCorrelationId(),
      sequenceNumber: this._sequenceNumber,
      actionType,
      deltaTime: Math.max(deltaTime, 1),
      duration: Math.max(duration, 1),
      position: injectedStartTime || Math.max(position, 1),
    };
    if (startupTime) reportPayload.startupTime = Math.max(startupTime, 1);
    if (this._getBitrate()) reportPayload.bitrate = this._getBitrate();
    if (errorObject) {
      if (errorObject.code > 0) {
        reportPayload.error = `error code: ${errorObject.code}`;
      } else if (errorObject.message) {
        reportPayload.error = `error code: XXX, message: ${errorObject.message}`;
      } else {
        reportPayload.error = JSON.stringify(errorObject);
      }
      reportPayload.error = reportPayload.error.substr(0, 255);
    }

    this._log('_trackEvent:', this._getKeyByActionType(actionType));
    this._log('-- track details', this._reportingUrl, reportPayload);
    return superagent.post(this._reportingUrl).send(reportPayload)
      .end((err) => { if (err) this._error(err); });
  }

  _getKeyByActionType(actionType) {
    return Object
      .keys(clientEventNames)
      .filter(key => clientEventNames[key] === actionType)[0];
  }

  _getBitrate() {
    if (this._player.tech_ &&
      this._player.tech_.hls &&
        this._player.tech_.hls.playlists &&
        this._player.tech_.hls.playlists.media_ &&
        this._player.tech_.hls.playlists.media_.attributes &&
        this._player.tech_.hls.playlists.media_.attributes.BANDWIDTH > 0) {
      return this._player.tech_.hls.playlists.media_.attributes.BANDWIDTH.toString();
    }
    return undefined;
  }

  _getInjectedStarttime() {
    if (this._player &&
      this._player.originalOptions &&
      this._player.originalOptions.startTime > 0
    ) {
      const stInSec = this._player.originalOptions.startTime;
      return parseInt(stInSec * 1000, 10);
    }
    return null;
  }

  _getCorrelationId() {
    return `${Date.now()}-${this._options.data.mediaGuid}`;
  }

  _validateOptions(options) {
    const errors = [];
    if (!options) {
      errors.push('no options provided');
      return errors;
    }
    if (!options.base) errors.push('options.base needs to be specified');
    if (!options.brand) errors.push('options.brand needs to be specified');
    if (!options.mtgApiDomain) errors.push('options.mtgApiDomain needs to be specified');

    if (!options.data) {
      errors.push('no options.data provided');
      return errors;
    }
    if (!options.data.mediaGuid) errors.push('options.data.mediaGuid needs to be specified');
    if (!options.data.title) errors.push('options.data.title needs to be specified');
    if (!options.data.deviceId) errors.push('options.data.deviceId needs to be specified');
    if (!options.data.deviceKey) errors.push('options.data.deviceKey needs to be specified');
    if (!options.data.productGuid) errors.push('options.data.productGuid needs to be specified');
    if (options.data.isAnonymous === undefined) errors.push('options.data.isAnonymous needs to be specified');
    if (!options.data.userId) errors.push('options.data.userId needs to be specified');

    return errors;
  }

  _formatOptionsForCSE(options) {
    // CSE reporting doesn't handle double-quotes properly, change them to be single.
    if (options.data.title) options.data.title = options.data.title.replace(/"/g, '\'').slice(0, 63);
    return options;
  }

  _log(...args) {
    debugLog(`[#${this._player.id()}]`, ...args);
  }
  _error(...args) {
    errorLog(`[#${this._player.id()}]`, ...args);
    console.error('> avodp - client-stream error >>', args[0]); // eslint-disable-line no-console
  }
}

videojs.plugin('clientStream', function init(options) {
  this.clientStream = new ClientStream(this, options);
  if (!this.clientStream.errors) this.clientStream._setStreamListener();
});
