import React from 'react';
import debug from 'debug';

/** @const {number} Height, in pixels, of volume bar */
const FULL_VOLUME_HEIGHT = 100;
const debugLog = debug('chromecastUI:log');

class SoundButton extends React.Component {
  constructor(props, context) {
    super(props, context);
    debugLog('Constructing react SoundButton', this.props);

    /** Assume two props
     {} this.props.playerAction
     bool this.props.isMuted
     float (0-1) this.props.volumeLevel
     */

    const volumeHeight = parseInt(FULL_VOLUME_HEIGHT * this.props.volumeLevel, 10);
    this.state = {
      volumeHeight: `${volumeHeight}px`,
      volumeMarginTop: `${-volumeHeight}px`,
      showVolume: false,
    };
  }
  componentWillReceiveProps(nextProps) {
    this.setState(this.updateSoundBar(nextProps.volumeLevel));
  }

  setVolume(event) {
    const soundBackground = this._audio_bg_level;
    let pos = 0;
    if (event.target.id === 'audio_bg_track') {
      pos = FULL_VOLUME_HEIGHT - parseInt(event.nativeEvent.offsetY, 10);
    } else {
      pos = parseInt(soundBackground.clientHeight, 10) - parseInt(event.nativeEvent.offsetY, 10);
    }
    soundBackground.style.height = `${pos}px`;
    soundBackground.style.marginTop = `-${pos}px`;
    this.props.playerAction.setVolume(pos);
  }

  updateSoundBar(volumeLevel) {
    const volumeHeight = parseInt(FULL_VOLUME_HEIGHT * volumeLevel, 10);
    return {
      volumeHeight: `${volumeHeight}px`,
      volumeMarginTop: `${-volumeHeight}px`,
    };
  }

  muteSound() {
    this.props.playerAction.mute(this.props.playerAction);
  }

  unMuteSound() {
    this.props.playerAction.unMute(this.props.playerAction);
  }

  showVolumeSlider() {
    if (!this.props.isMuted) {
      clearTimeout(this.soundbarTimeout);
      this.setState({
        showVolume: true,
      });
    }
  }

  hideVolumeSlider() {
    this.soundbarTimeout = setTimeout(() => {
      this.setState({
        showVolume: false,
      });
    }, 500);
  }

  render() {
    return (
      <div className="sound-button">
        {this.state.showVolume && <button
          id="audio_bg"
          ref={(div) => { this._audio_bg = div; }}
          className="chromecast-control-button chromecast-control-audio-bg"
          onClick={e => this.setVolume(e)}
          onMouseOver={e => this.showVolumeSlider(e)}
          onMouseOut={e => this.hideVolumeSlider(e)}
        />}
        {this.state.showVolume && <button
          id="audio_bg_track"
          className="chromecast-control-button chromecast-control-audio-bg-track"
          onClick={e => this.setVolume(e)}
          onMouseOver={e => this.showVolumeSlider(e)}
          onMouseOut={e => this.hideVolumeSlider(e)}
        />}
        <div
          id="audio_indicator"
          className="chromecast-control-button chromecast-control-audio-indicator"
        />
        {this.state.showVolume && <button
          id="audio_bg_level"
          ref={(div) => { this._audio_bg_level = div; }}
          className="chromecast-control-button chromecast-control-audio-bg-level"
          style={{ height: this.state.volumeHeight, marginTop: this.state.volumeMarginTop }}
          onClick={e => this.setVolume(e)}
          onMouseOver={e => this.showVolumeSlider(e)}
          onMouseOut={e => this.hideVolumeSlider(e)}
        />}
        {!this.props.isMuted && <button
          id="audio_on"
          className="chromecast-control-button control-icon-volume-high"
          onClick={e => this.muteSound(e)}
          onMouseOver={e => this.showVolumeSlider(e)}
          onMouseOut={e => this.hideVolumeSlider(e)}
        />}
        {this.props.isMuted && <button
          id="audio_off"
          className="chromecast-control-button control-icon-volume-mute"
          onClick={e => this.unMuteSound(e)}
        />}
      </div>
    );
  }
}

export default SoundButton;
