import vjs from 'video.js';
import Component from './component';

const VJSComponent = vjs.getComponent('Component');

export default class CTAOverlay extends VJSComponent {
  constructor(player) {
    super();
    this._player = player;
    this._component = new Component('cta-overlay', this._player, 'div', 'cta-overlay');
    this._component.hide();
    this._disabled = true;
  }

  init(imageSrc, text, clickThroughLink, leftPercentageOffset, topPercentageOffset, percentageWidth) {
    this.image = imageSrc;
    this.text = text;
    this.clickThroughLink = clickThroughLink;

    let maxPercentageWidth;
    if (leftPercentageOffset < 50) {
      maxPercentageWidth = (leftPercentageOffset * 2) - 5;
    } else {
      maxPercentageWidth = ((100 - leftPercentageOffset) * 2) - 5;
    }

    const actualPercentageWidth = percentageWidth ? Math.min(maxPercentageWidth, percentageWidth) : maxPercentageWidth;

    this._component.setInnerHtml(`<img src="${imageSrc}" style="width:100%" alt="${text}"/>`);

    this._component.setStyleAttribute('position', 'absolute');
    this._component.setStyleAttribute('left', `${leftPercentageOffset}%`);
    this._component.setStyleAttribute('top', `${topPercentageOffset}%`);
    this._component.setStyleAttribute('width', `${actualPercentageWidth}%`);
    this._component.setStyleAttribute('maxWidth', `${actualPercentageWidth}%`);

    this._component.onClick((event) => {
      event.stopPropagation();
      window.open(clickThroughLink, '_blank');
      this._player.pause();
    });

    this._disabled = false;
  }

  show() {
    if (!this._disabled) this._component.show();
  }

  disable() {
    this._component.hide();
    this._component.setInnerHtml('');
    this._disabled = true;
  }

  isDisabled() {
    return this._disabled;
  }
}
