import debug from 'debug';
import vjs from 'video.js';
import SimpleComponent from './simple-component.js';

const VJSComponent = vjs.getComponent('Component');

// Setup debugging
const debugLog = debug('MtgxSkinPlugin:SubtitleMenu');

export default class SubtitleMenu extends VJSComponent {
  constructor(player) {
    super();
    this._player = player;

    this._init();
  }

  _init() {
    this._log('_init');
    this._player.on('showSubtitleMenu', () => {
      this._log('showSubtitleMenu');

      this._player.trigger('showOverlayMessage', {
        closeCallback: () => {
          this._log('hideSubtitleMenu');
          this._player.trigger('hideOverlayBlur');
        },
        closeable: true,
        blur: true,
        onlyOnce: false,
        renderMessageComponent: this._renderSubtitleMenuOverlay.bind(this),
        className: 'subtitle-menu',
      });

      this._player.on('dispose', () => this._dispose.bind(this));
    });
    this._player.on('hideSubtitleMenu', () => {
      this._log('hideSubtitleMenu');
      this._player.trigger('hideOverlayMessage');
      this._player.trigger('hideOverlayBlur');
    });
  }

  _renderSubtitleMenuOverlay(parent) {
    this._addSubtitleMenuOverlay(parent);

    this._addTitle(this._subtitleMenuOverlay);
    this._addOptions(this._subtitleMenuOverlay);
  }

  _addSubtitleMenuOverlay(parent) {
    this._subtitleMenuOverlay = parent.addChild(
      'SubtitleMenuOverlay',
      SimpleComponent.createComponent({
        className: 'x-subtitle-menu-overlay',
      })
    );
  }

  _addTitle(parent) {
    parent.addChild(
      'SubtitleMenuTitle',
      SimpleComponent.createComponent({
        className: 'title',
        innerHTML: this._player.localize('Subtitles'),
      })
    );
  }

  _addOptions(parent) {
    const textTracksList = parent.addChild(
      'SubtitleMenuTextTracks',
      SimpleComponent.createComponent({
        tagName: 'ol',
      }
    ));

    const textTracks = this._player.getAvailableTextTracks();

    const notShowing = !textTracks.filter(textTrack => textTrack.mode === 'showing').length;

    this._textTrackOptions = [];

    const offOption = this._addOption(textTracksList, {
      label: this._player.localize('Subtitles off'),
      selected: notShowing,
      click: () => this._player.changeSubtitle(null),
    });

    this._textTrackOptions.push(offOption);

    textTracks.forEach((textTrack, index) => {
      let label;
      if (textTrack.language) label = textTrack.label;
      else {
        label = textTrack.kind === 'captions' ?
          this._player.localize('Default language for hearing impaired') :
          this._player.localize('Default language');
      }

      const textTrackOption = this._addOption(textTracksList, {
        label,
        selected: textTrack.mode === 'showing',
        click: () => this._player.changeSubtitle(index),
      });

      this._textTrackOptions.push(textTrackOption);
    });
  }

  _addOption(parent, options) {
    const textTrackOption = parent.addChild(
      'SubtitleMenuTextTrackOption',
      SimpleComponent.createComponent({
        tagName: 'li',
        innerHTML: options.label,
      })
    );

    textTrackOption.on(['click', 'touchend'], (event) => {
      event.stopPropagation();
      this._checkSelected(textTrackOption);
      options.click();
    });

    if (options.selected) textTrackOption.addClass('selected');

    return textTrackOption;
  }

  _checkSelected(selectedTextTrackOption) {
    Array.prototype.forEach.call(this._textTrackOptions, (textTrackOption) => {
      if (textTrackOption === selectedTextTrackOption) textTrackOption.addClass('selected');
      else textTrackOption.removeClass('selected');
    });
  }

  _dispose() {
    this._player.trigger('hideSubtitleMenu');
  }

  _log(...args) {
    debugLog(`[#${this._player.id()}]`, ...args);
  }
}
