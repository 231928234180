import React from 'react';
import window from 'global/window';
import debug from 'debug';
import ProgressBar from './ProgressBar';
import PlayPauseButton from './PlayPauseButton';
import Duration from './DurationIndicator';
import SoundButton from './SoundButton';
import CastingIndicator from './CastingIndicator';
import TitleBars from './TitleBars';
import NextEpisode from './NextEpisode';
import SubtitlesButton from './SubtitlesButton';
import SubtitlesMenu from './SubtitlesMenu';
import ChangeVideo from './ChangeVideo';


const debugLog = debug('chromecastUI:log');

const BAR_STATE = {
  ADS: 'ADS',
  LIVE: 'LIVE',
  ENDED: 'ENDED',
  CONTENT: 'CONTENT',
  NOT_READY: 'NOTREADY',
};

class UIControlBar extends React.Component {
  constructor(props, context) {
    super(props, context);
    debugLog('Constructing react controlBar', this.props);
    this.state = {
      lastUpdateTime: Date.now(),
      barState: this.getBarState(),
      isActive: true,
      showSubtitlesMenu: false,
    };
    this.currentPoster = '';
    this.currentTitle = '';
    this.currentSubtitle = '';
    this.currentSharingUrl = '';
    this.setPlayerSize = this.setPlayerSize.bind(this);


    /** Assume two props
     {} this.props.playerAction
     {} this.props.playerInfo
     */
  }

  componentDidMount() {
    window.addEventListener('resize', this.setPlayerSize);
    this.setPlayerSize();

    // Chromecast specific
    this.updateListener = () => {
      this.setState({
        lastUpdateTime: Date.now(),
        barState: this.getBarState.call(this),
        showSubtitlesMenu: this.getSubtitleMenuState.call(this),
      });
    };

    this.props.playerInfo.controller.addEventListener(
      window.cast.framework.RemotePlayerEventType.ANY_CHANGE, this.updateListener
   );

    this.disconnectListener = (event) => {
      this.setState({ isActive: event.value });
    };

    this.props.playerInfo.controller.addEventListener(
      window.cast.framework.RemotePlayerEventType.IS_CONNECTED_CHANGED, this.disconnectListener
    );
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.setPlayerSize);

    this.props.playerInfo.controller.removeEventListener(
      window.cast.framework.RemotePlayerEventType.ANY_CHANGE,
      this.updateListener);
    this.props.playerInfo.controller.removeEventListener(
      window.cast.framework.RemotePlayerEventType.IS_CONNECTED_CHANGED,
      this.disconnectListener);
  }


  getSubtitleMenuState() {
    return this.state.barState === BAR_STATE.CONTENT && this.state.showSubtitlesMenu;
  }

  getBarState() {
    if (!this.props.playerInfo.mediaInfo || this.props.playerInfo.duration === 0) {
      return BAR_STATE.NOT_READY;
    }

    const mediaType = this.props.playerInfo.mediaInfo.type;
    const streamType = this.props.playerInfo.mediaInfo.streamType;
    const isMediaLoaded = this.props.playerInfo.isMediaLoaded;

    if (!isMediaLoaded) {
      return BAR_STATE.ENDED;
    }

    if (streamType === 'LIVE') {
      return BAR_STATE.LIVE;
    }

    switch (mediaType) {
      case 'AD' :
        return BAR_STATE.ADS;
      case 'CONTENT':
        return BAR_STATE.CONTENT;
      default:
        return BAR_STATE.NOT_READY;
    }
  }

  getPosterImage() {
    if (!this.props.playerInfo.mediaInfo) {
      return this.currentPoster;
    }
    this.currentPoster = this.props.playerInfo.mediaInfo.imageUrl;

    return this.currentPoster;
  }

  getTitle() {
    if (!this.props.playerInfo.mediaInfo) {
      return this.currentTitle;
    }
    this.currentTitle = this.props.playerInfo.mediaInfo.formatTitle;

    return this.currentTitle;
  }

  getSubtitle() {
    if (!this.props.playerInfo.mediaInfo) {
      return this.currentSubtitle;
    }
    this.currentSubtitle = this.props.playerInfo.mediaInfo.videoTitle;

    return this.currentSubtitle;
  }

  getSharingUrl() {
    if (!this.props.playerInfo.mediaInfo) {
      return this.currentSharingUrl;
    }
    this.currentSharingUrl = this.props.playerInfo.mediaInfo.sharingUrl;

    return this.currentSharingUrl;
  }

  getNextEpisodeInfo() {
    if (!this.props.playerInfo.mediaInfo || !this.props.playerInfo.mediaInfo.nextEpisode) {
      return null;
    }

    if (parseInt(this.props.playerInfo.mediaInfo.nextEpisode.countdownTime, 10) === -1) {
      return null;
    }

    return this.props.playerInfo.mediaInfo.nextEpisode;
  }

  getTracks() {
    if (!this.props.playerInfo.mediaInfo || !this.props.playerInfo.mediaInfo.tracks) {
      return null;
    }
    return this.props.playerInfo.mediaInfo.tracks;
  }

  getActiveSubtitles() {
    let activeTrackIds = null;
    if (window.cast &&
    window.cast.framework &&
    window.cast.framework.CastContext &&
    window.cast.framework.CastContext.getInstance() &&
    window.cast.framework.CastContext.getInstance().getCurrentSession() &&
    window.cast.framework.CastContext.getInstance().getCurrentSession().getMediaSession()) {
      activeTrackIds = window.cast.framework.CastContext.getInstance()
       .getCurrentSession()
       .getMediaSession()
       .activeTrackIds;
    }
    if (!activeTrackIds) return 0;
    return activeTrackIds.length > 0 ? activeTrackIds[0] : 0;
  }

  // Copied from mtgx-skin.js
  setPlayerSize() {
    const el = this._fullPlayerElement;

    if (el) {
      if (el.offsetWidth > 900) {
        this.setState({ playerSize: 'x-large' });
      } else if (el.offsetWidth > 580) {
        this.setState({ playerSize: 'x-medium' });
      } else {
        this.setState({ playerSize: 'x-small' });
      }
    }
  }

  toggleSubtitlesMenu() {
    this.setState({
      showSubtitlesMenu: !this.state.showSubtitlesMenu,
    });
  }

  render() {
    if (!this.state.isActive) {
      return null;
    }
    if (this.props.displayMode === 'SMALL') {
      return (<div id="chromecast-ui" className="small">
        <TitleBars
          title={this.getTitle()}
          subtitle={this.getSubtitle()}
          linkUrl={this.getSharingUrl()}
        />
        <div id="media_control" className="chromecast-media-control">
          <PlayPauseButton
            playerAction={this.props.playerAction}
            isPaused={this.props.playerInfo.isPaused}
          />
          {this.state.barState === BAR_STATE.CONTENT && <ProgressBar
            playerAction={this.props.playerAction}
            currentTime={this.props.playerInfo.currentTime}
            duration={this.props.playerInfo.duration}
          />}
          <button id="castbutton" class="control-icon-chromecast-cast-button" is="google-cast-button" />
        </div>
      </div>);
    } else if (this.props.displayMode === 'FULL') {
      return (
        <div
          id="chromecast-ui"
          className={`full viafree-player avodp ${this.state.playerSize}`}
          ref={(div) => { this._fullPlayerElement = div; }}
        >
          <div
            id="chromecast-background-image"
            style={{
              backgroundImage: `url('${this.getPosterImage()}')`,
              filter: `${this.state.showSubtitlesMenu ?
                'saturate(0.95) grayscale(0.05) brightness(0.6) contrast(0.95) blur(25px)' :
                ''
              }`,
            }}
          />
          {!this.getSubtitleMenuState() && !this.getNextEpisodeInfo() && <CastingIndicator
            translatedCastingOn={this.props.translations.castingOn}
            deviceName={this.props.playerInfo.displayName}
          />}
          {!this.getSubtitleMenuState() && !!this.getNextEpisodeInfo() && <NextEpisode
            playerAction={this.props.playerAction}
            translatedPlayingIn={this.props.translations.playingIn}
            nextEpisodeInfo={this.getNextEpisodeInfo()}
          />}
          {<ChangeVideo
            playerAction={this.props.playerAction}
            playerInfo={this.props.playerInfo}
            shouldShow={!this.getSubtitleMenuState()}
            potentialEpisodeInfo={this.props.potentialEpisodeInfo}
            translatedShouldCast={this.props.translations.shouldCast}
          /> }
          {!this.getSubtitleMenuState() && <TitleBars
            title={this.getTitle()}
            subtitle={this.getSubtitle()}
          />}
          {this.getSubtitleMenuState() &&
            <SubtitlesMenu
              tracks={this.getTracks()}
              playerAction={this.props.playerAction}
              subtitlesLabel={this.props.translations.subtitles}
              subtitlesOff={this.props.translations.subtitleOff}
              toggleSubtitlesMenu={() => this.toggleSubtitlesMenu()}
              selectedTrackId={this.getActiveSubtitles()}
            />
          }
          {!this.getSubtitleMenuState() && <div id="media_control" className="chromecast-media-control">
            <PlayPauseButton
              playerAction={this.props.playerAction}
              isPaused={this.props.playerInfo.isPaused}
            />
            {this.state.barState === BAR_STATE.CONTENT && <ProgressBar
              playerAction={this.props.playerAction}
              playerInfo={this.props.playerInfo}
              currentTime={this.props.playerInfo.currentTime}
              duration={this.props.playerInfo.duration}
            />}
            {this.state.barState === BAR_STATE.CONTENT && <Duration
              playerAction={this.props.playerAction}
              currentTime={this.props.playerInfo.currentTime}
              duration={this.props.playerInfo.duration}
            />}
            {this.state.barState === BAR_STATE.CONTENT && this.getTracks() && this.getTracks().length > 0 &&
              <SubtitlesButton
                toggleSubtitlesMenu={() => this.toggleSubtitlesMenu()}
              />
            }
            <SoundButton
              playerAction={this.props.playerAction}
              isMuted={this.props.playerInfo.isMuted}
              volumeLevel={this.props.playerInfo.volumeLevel}
            />
            <button id="castbutton" class="control-icon-chromecast-cast-button" is="google-cast-button" />
          </div>}
        </div>
      );
    }
    return null;
  }
}

export default UIControlBar;
