import debug from 'debug';
import cond from './util/cond';
import mimetypes from './util/mimetypes';

// Setup debugging
const debugLog = debug('AVODPlayer:SourceOrder');

/**
 * Guess the source type of a URL by looking at its extension
 * @param {string} srcUrl - the URL to look at
 * @returns {string} Returns the Mime Type of the URL
 */
export function getSourceTypeFromUrl(srcUrl) {
  let url = srcUrl;

  // Remove ?query-parameter if it exists in URL (does in Viaplay URLs)
  url = url.split('?')[0];
  const protocol = url.split(':').shift().replace(/https/, 'http');
  const extension = url.split('.').pop();

  return cond(`${protocol}:${extension}`, {
    'http:mpd': () => mimetypes.DASH,
    'http:m3u8': () => mimetypes.HLS,
    'http:mp4': () => mimetypes.MP4,
    'rtmp:mp4': () => mimetypes.RTMP_MP4,
    'rtmp:flv': () => mimetypes.RTMP_FLV,
    'http:flv': () => mimetypes.FLV,
    default: () => {
      debugLog(`avodPlayer: Could not determine mimetype of URL '${url}'`);
      return null;
    },
  });
}

/**
 * Take a URL and generate a source object that is understood by videojs
 * @param {string|object} src - a URL where the source type will be guessed or an object that's already a valid
 * source type which will just be returned
 * @returns {object} Returns a valid source object such as { src: 'http://...', type: 'application/dash+xml' }
 */
export function getSourceObjectFromUrl(srcUrl) {
  let url = srcUrl;

  // If we are passed an object, it may already be a valid source object which should just be returned
  if (typeof url === 'object' && url.src && url.type) {
    return url;
  }

  // If we are passed an object without a type, but with a src URL, use that and guess its type
  if (typeof url === 'object' && url.src) {
    url = url.src;
  }

  if (typeof url !== 'string') {
    throw new Error(`getSourceObjectFromUrl expects either a string or a valid source object
      (e.g. { src: 'http://', type: 'application/dash+xml' }). Got ${JSON.stringify(url)}`);
  }

  // Otherwise if we are passed a string, guess its type and return a valid source object
  return { src: url, type: getSourceTypeFromUrl(url) };
}

/**
 * Return a sorted version of an array of sources  based on a provided order of source types
 * @param {Array<Object>} sources - Input array of source objects (such as
 *  { src: 'http://...', type: 'application/dash+xml' })
 * @param {Array<String>} sourceTypeOrder - An ordered array of source mime types
 *
 * @example
 * returns [{ src: 'bar.mpd', type: 'application/dash+xml' }, { src: 'world.m3u8', type: 'application/x-mpegURL' },
 * { src: 'foo.mp4', type: 'video/mp4' }]
 * getSortedSourceUrls([{ src: 'foo.mp4', type: 'application/dash+xml' },
 * { src: 'bar.mpd', type: 'application/x-mpegURL' }, { src: 'world.m3u8', type: 'video/mp4' }],
 * ['application/x-mpegURL', 'application/dash+xml', 'video/mp4']);
 *
 * @returns {Array<String>} Returns the ordered array of sources
 */
export function getSortedSourceObjects(sources, sourceTypeOrder) {
  sources.forEach((s) => {
    if (sourceTypeOrder.indexOf(s.type) === -1) {
      debugLog(`avodPlayer: Mimetype '${s.type}' was not found in source type order array: '${
        sourceTypeOrder}', it will be placed last`);
    }
  });

  const sortedSources = sources.slice(0); // Clone the original sources array
  sortedSources.sort((a, b) => {
    const sourceTypeOrderA = sourceTypeOrder.indexOf(a.type);
    const sourceTypeOrderB = sourceTypeOrder.indexOf(b.type);
    if (sourceTypeOrderA === -1 && sourceTypeOrderB === -1) return 0;
    if (sourceTypeOrderA === -1) return 1;
    if (sourceTypeOrderB === -1) return -1;
    return sourceTypeOrderA - sourceTypeOrderB;
  });
  return sortedSources;
}

/**
 * Returns a version of the array while keeping only one single mp4 stream. The stream is choosen
 * based on the bitrate attribute and the platform.
 * @param {Array<Object>} sourceObjects - Input array of source objects
 *  (such as { src: 'http://...', type: 'application/dash+xml' })
 * @param {int} idealBitrate - The ideal bitrate of the video stream
 * @returns {Array<String>} Returns the array of sources with one mp4 source.
 */
export function chooseAppropriateMp4Stream(sourceObjects, idealBitrate) {
  let diff;
  let idealMp4Stream;
  const updatedSourceObjects = [];

  sourceObjects.forEach((source) => {
    if (source.type === mimetypes.MP4 && source.bitrate) {
      const newdiff = Math.abs(idealBitrate - source.bitrate);
      if (!diff || newdiff < diff) {
        diff = newdiff;
        idealMp4Stream = source;
      }
    } else {
      updatedSourceObjects.push(source);
    }
  });

  if (idealMp4Stream) {
    updatedSourceObjects.push(idealMp4Stream);
  }

  return updatedSourceObjects;
}

