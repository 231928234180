/* global cast */
/**
 * @file castTech.js
 * VideoJS-castTech - Custom Chromecast Player
 */

import videojs from 'video.js';
import debug from 'debug';

const Tech = videojs.getComponent('Tech');
const Html5Tech = videojs.getTech('Html5');
const debugLog = debug('castTech:log');
/**
 * Chromecast Media Controller - Wrapper for Chromecast Media Player API
 *
 * @extends Tech
 */
class CCMediaPlayer extends Html5Tech {

  setSource(srcObject) {
    debugLog('setSource', srcObject);
    const srcUrl = (srcObject && srcObject.src) || srcObject; // srcObject can be either <string> or { src: <string> }
    this.usedSrc = srcUrl;
    this.host = new cast.player.api.Host({
      mediaElement: this.el_,
      url: srcUrl,
    });

    this.protocol = cast.player.api.CreateHlsStreamingProtocol(this.host); // eslint-disable-line new-cap

    if (this.playerCC) {
      debugLog('Setting source on an already loaded player, unloading...');
      this.playerCC.unload();
    } else {
      debugLog('Setting source on a new player, creating the player...');
      this.playerCC = new cast.player.api.Player(this.host);
    }
    this.host.onError = (errorCode) => {
      debugLog('Chromecast player fatal error', errorCode);
      if (this.playerCC) {
        this.playerCC.unload();
        this.playerCC = null;
      }
    };
  }

  /**
   * Load media into Tech.
   */

  load() {
    debugLog('Load Chromcast player with source', this.usedSrc);
    if (this.usedSrc) this.playerCC.load(this.protocol, 0);
  }

  /**
   * Return current source (Used in resume for Freewheel to resume after an ad)
   * Can't be taken from this.el_.src since it contains blobs that
   * might be garbage collected during the ad
   */
  currentSrc() {
    return this.usedSrc;
  }

  play(timeout) {
    debugLog(`Defering playback for ${timeout}ms`);
    const usedTimeout = timeout || 0;
    this.deferredPlayCallbackId_ = setTimeout(() => {
      self.deferredPlayCallbackId_ = null;
      if (this.playerCC) {
        debugLog('Playing when enough data');
        this.playerCC.playWhenHaveEnoughData();
      } else {
        debugLog('Playing');
        this.el_.play();
      }
    }, usedTimeout);
  }

  dispose() {
    debugLog('Disposing castTech');
    this.playerCC.unload();
    this.protocol = null;
    this.usedSrc = null;
    this.playerCC = null;
    super.dispose();
  }
}

CCMediaPlayer.isSupported = () => typeof cast !== 'undefined' && cast.player;

if (Tech.getTech('CastTech')) {
  videojs.log.warn('Not using CastTech as it appears to already be registered');
} else {
  videojs.registerTech('CastTech', CCMediaPlayer);
}

CCMediaPlayer.VERSION = '0.0.1';

export default CCMediaPlayer;
