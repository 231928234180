
export default {

  /**
   * [VIP-14698] Workaround for Chromium bug (https://bugs.chromium.org/p/chromium/issues/detail?id=534301)
   * by changing the audio codec from AAC-LC (40.2) to HE-AAC (40.5).
   *
   * Since this changes the prototype of ParseStream and Player, using two concurrent players with different implicit
   * signaling values is not supported with this fix and the old player needs to be disposed before creating a new
   * player.
   */
  fixImplicitSignaling(videojs) {
    const ParseStream = videojs.m3u8.ParseStream;
    ParseStream.prototype._originalPush = ParseStream.prototype.push;

    // This must be a function() {} not a () => {} to preserve 'this'
    ParseStream.prototype.push = function push(manifestFileLine) {
      let line = manifestFileLine;
      line = line.replace(/mp4a\.40\.2/, 'mp4a.40.5');
      this._originalPush(line);
    };

    const Player = videojs.getComponent('Player');
    Player.prototype._originalDispose = Player.prototype.dispose;
    Player.prototype.dispose = function dispose() {
      this._originalDispose();
      ParseStream.prototype.push = ParseStream.prototype._originalPush;
      Player.prototype.dispose = Player.prototype._originalDispose;
    };
  },
};

