import videojs from 'video.js';

export default class Component {
  constructor(name, parentComponent, tag, className) {
    parentComponent.addChild(name, {
      componentClass: 'Component',
      el: videojs.getComponent('Component').prototype.createEl(tag, {
        className,
      }),
    });
    this._vjsComponent = parentComponent.getChild(name);
  }

  show() {
    this._vjsComponent.show();
  }

  hide() {
    this._vjsComponent.hide();
  }

  setStyleAttribute(key, value) {
    this._vjsComponent.el().style[key] = value;
  }

  setInnerHtml(innerHtml) {
    this._vjsComponent.el().innerHTML = innerHtml;
  }

  onClick(cb) {
    this._vjsComponent.on('click', cb);
  }
}
