import vjs from 'video.js';

const Component = vjs.getComponent('Component');

export default vjs.extend(Component, {
  createEl: function createEl() {
    const pauseAdComponent = Component.prototype.createEl.call(this, 'div', {
      className: 'pause-ad',
      innerHTML: `<div class="ad-creative"></div><div class="close">${this.player().localize('Close ad')}</div>`,
    });
    return pauseAdComponent;
  },
});
