import React from 'react';
import debug from 'debug';

const debugLog = debug('chromecastUI:log');

class CastingIndicator extends React.Component {
  constructor(props, context) {
    super(props, context);
    debugLog('Constructing react titlebars', this.props);

    /** Assume props
     string this.props.translatedCastingOn
     string this.props.deviceName
     */
  }

  render() {
    return (
      <div className="casting-indicator">
        <div>{`${this.props.translatedCastingOn} ${this.props.deviceName}`}</div>
      </div>
    );
  }
}

export default CastingIndicator;
