import vjs from 'video.js';
import debug from 'debug';

import PauseAdComponent from './components/pause-ad';
import DisplayAdHandler from './display-ad-handler';


// Setup debugging
const debugLog = debug('fw-pauseAdHandler:log');

// Trigger Pause ads when media is Paused
export default class PauseAdHandler {

  constructor(options, player) {
    this._options = options;
    this._player = player;

    this._overlayShowing = false;
    this._suggestionsShowing = false;
    this._pauseAdShowing = false;

    if (!this._options.pauseAdUri) this._options.pauseAdUri = this._buildPauseAdUri();

    this._player.getChild('FWAdClickThrough').el().addEventListener('touchend', this._handlePauseAdClick.bind(this));
    this._player.getChild('FWAdClickThrough').el().addEventListener('click', this._handlePauseAdClick.bind(this));

    vjs.registerComponent('FWPauseAd', PauseAdComponent);
    this._pauseAdComponent = this._player.addChild('FWPauseAd');
    this._pauseAdComponent.el().querySelector('.close').addEventListener('click', this._clearPauseAdSlot.bind(this));

    this._player.on('mediaPause', this._loadPauseAdSlot.bind(this));
    this._player.on('mediaPlay', this._clearPauseAdSlot.bind(this));

    this._player.on('overlayMessageShown', this._showOverlayMessage.bind(this));
    this._player.on('overlayMessageHidden', this._hideOverlayMessage.bind(this));

    this._player.on('suggestionsShown', this._showSuggestions.bind(this));
    this._player.on('suggestionsHidden', this._hideSuggestions.bind(this));
  }

  _loadPauseAdSlot() {
    // If we are are seeking or are scrubbing, don't show the ad
    if (this._player.seeking() || this._player.scrubbing()) return;

    this._pauseAdShowing = true;

    this._player.setTimeout(() => {
      if (!this._pauseAdShowing || this._overlayShowing || this._suggestionsShowing) return;

      const xhr = new XMLHttpRequest();
      xhr.addEventListener('load', this._onResponse.bind(this));
      xhr.open('GET', this._options.pauseAdUri);
      xhr.withCredentials = true;
      xhr.send();
    }, this._options.pauseAdDelay);
  }

  _onResponse(event) {
    const xhr = event.target;

    // If we are not paused anymore, are seeking or are scrubbing, don't show the ad
    if (!this._pauseAdShowing || !this._player.paused() || this._player.seeking() || this._player.scrubbing()) return;

    if (xhr.status !== 200) {
      this._log('Failed loading pauseAd -  request failure', this.statusText);
      return;
    }
    const response = xhr.responseText;

    if (!response) {
      this._log('Failed loading pauseAd - empty response');
      return;
    }

    const pauseAdContainer = this._player.getChild('FWPauseAd');

    const parser = new DOMParser();
    const adDocument = parser.parseFromString(response, 'text/html');
    const pauseAdTrackingElements = adDocument.querySelectorAll('body > img');
    let pauseAdDisplayElement = adDocument.querySelectorAll('body > span')[0];

    if (!pauseAdDisplayElement) {
      this._log('Failed loading pauseAd - no span');
      return;
    }

    const pauseAdImageElement = pauseAdDisplayElement.querySelector('img');

    // Save a reference to the <a> to be able to trigger a click on it when the click-through link is clicked.
    this._pauseAdLinkElement = pauseAdDisplayElement.querySelector('a');

    if (!pauseAdImageElement) {
      this._log('Failed loading pauseAd - no image');
      return;
    }

    if (this._options.pauseAdLargeClickArea) {
      this._pauseAdComponent.addClass('pause-ad-large-click-area');
      this._player.getChild('FWAdClickThrough').hide();
    } else {
      // Move the <img> to outside the <a> to make it non clickable
      pauseAdDisplayElement.insertBefore(pauseAdImageElement, pauseAdDisplayElement.firstChild);
    }

    // Executing any scripts that may come with the pause ad slot
    pauseAdDisplayElement = DisplayAdHandler.loadScriptsFromDom(pauseAdDisplayElement);

    // Adding pauseAd to DOM
    const pauseAdCreative = pauseAdContainer.el().querySelector('.ad-creative');
    pauseAdCreative.appendChild(pauseAdDisplayElement);

    pauseAdTrackingElements.forEach((pauseAdTrackingElement) => {
      pauseAdCreative.appendChild(pauseAdTrackingElement);
    });

    this._player.userActive(false);

    this._trigger('pauseAdShowing');
  }

  _handlePauseAdClick() {
    try {
      this._pauseAdLinkElement.click();
    } catch (e) { /* Do nothing */ }
  }

  _clearPauseAdSlot() {
    debugLog('_clearPauseAdSlot');
    this._pauseAdShowing = false;

    const pauseAdContainer = this._player.getChild('FWPauseAd');
    if (pauseAdContainer.el().querySelector('.ad-creative').innerHTML) {
      this._trigger('mediaPlaying');
      pauseAdContainer.el().querySelector('.ad-creative').innerHTML = '';
    }

    this._player.getChild('FWAdClickThrough').show();
  }

  _showOverlayMessage() {
    this._clearPauseAdSlot();
    this._overlayShowing = true;
  }

  _hideOverlayMessage() {
    this._overlayShowing = false;
  }

  _showSuggestions() {
    this._clearPauseAdSlot();
    this._suggestionsShowing = true;
  }

  _hideSuggestions() {
    this._suggestionsShowing = false;
  }

  _buildPauseAdUri() {
    // Don't indent this template literal
    return `${this._options.serverUrl}/ad/g/1?\
prof=${encodeURIComponent(this._options.profileId)}&\
nw=${encodeURIComponent(this._options.networkId)}&\
csid=${encodeURIComponent(this._options.siteSectionId)}&\
ssnw=${encodeURIComponent(this._options.siteSectionNetworkId)}&\
caid=${encodeURIComponent(this._options.videoAssetId)}&\
resp=ad;\
_fw_fss=_fw_search;\
slid=ad_banner&\
ptgt=s&\
w=1024&\
h=576&\
cd=1280,720|1920,1080&\
slau=${encodeURIComponent('1024x576 Pause|1280x720 Pause|1920x1080 Pause')}&\
tpcl=DISPLAY&\
flag=${encodeURIComponent('+cmpn')}&\
prct=${encodeURIComponent('text/html_doc_lit_mobile,text/html_doc_ref')}`;
  }

  _trigger(...evt) {
    this._log('Trigger:', ...evt);

    this._player.trigger(...evt);
  }

  _log(...args) {
    debugLog(`[#${this._player.id()}]`, ...args);
  }
}
