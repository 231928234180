import debug from 'debug';

const debugLog = debug('avodplayer:connectivity-detector:utils');

function hasInternetAccess(callback, safeUrl = '/') {
  return new Promise((resolve, reject) => {
    if (navigator.onLine) {
      // navigator.onLine sometimes, in some browsers, is true if we are connected to a router even if we have no
      // internet access, so we have to check if we can access a known url to know if we have internet access.
      const http = new XMLHttpRequest();
      http.open('HEAD', safeUrl, true);
      http.onreadystatechange = function onreadystatechange() {
        if (this.readyState === this.DONE) {
          if (this.status === 0) {
            // if the call to the safe url is aborted we probably have no internet access
            debugLog('No internet access - ajax');
            reject();
          } else {
            resolve();
          }
        }
      };
      http.send();
    } else {
      debugLog('No internet access - navigator.onLine');
      reject();
    }
  });
}

function timeoutPromise(promise, ms, positive) {
  // Provided promise will be rejected/resolved if timeout limit is reached
  const timeout = new Promise((resolve, reject) => {
    const respond = positive ? resolve : reject;
    setTimeout(respond, ms);
  });
  return Promise.race([
    promise,
    timeout,
  ]);
}

function throttle(callback, ms) {
  // Returns a function witch will be called only once within provided ms
  let wait = false;
  return (...args) => {
    if (!wait) {
      callback(...args);
      wait = true;
      setTimeout(() => {
        wait = false;
      }, ms);
    }
  };
}

export default { hasInternetAccess, timeoutPromise, throttle };
