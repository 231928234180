import debug from 'debug';

const debugLog = debug('state-manager');

export default class StateManager {
  constructor(player) {
    this._player = player;
    player.addClass('media-playing');
    this.currentState = this.STATES.mediaPlaying;
    this.currentState.apply(player, this.currentState);

    this._player.on(['adRollStart', 'loading'], (event) => {
      this.change(this.STATES.loading);
      this._player.one('timeupdate', () => {
        if (event.type === 'adRollStart') this.change(this.STATES.adPlaying);
        else this.change(this.STATES.mediaPlaying);
      });
    });
    this._player.on('mediaPlaying', () => this.change(this.STATES.mediaPlaying));
    this._player.on('mediaEnding', () => this.change(this.STATES.mediaEnding));
    this._player.on('adError', () => this.change(this.STATES.mediaError));
    this._player.on('pauseAdShowing', () => this.change(this.STATES.pauseAdShowing));
  }

  change(state) {
    if (this.currentState.name !== state.name && state.apply(this._player, this.currentState)) {
      this.currentState = state;
      this._log('State Change: CurrentState => ', state.name);
    }
  }

  get MODES() {
    return {
      AD: 'ad',
      MEDIA: 'media',
      LOADING: 'loading',
    };
  }

  get STATES() {
    return {
      adPlaying: {
        name: 'Ad Playing',
        mode: this.MODES.MEDIA,
        apply: (player, currentState) => {
          currentState.clear(player);
          player.addClass('ad-playing');
          return true;
        },
        clear: (player) => {
          player.removeClass('ad-playing');
        },
      },
      mediaPlaying: {
        name: 'Media Playing',
        mode: this.MODES.MEDIA,
        apply: (player, currentState) => {
          if (currentState.mode !== this.MODES.AD) {
            currentState.clear(player);
            player.addClass('media-playing');
            return true;
          }
          return false;
        },
        clear: (player) => {
          player.removeClass('media-playing');
        },
      },
      loading: {
        name: 'Loading',
        mode: this.MODES.LOADING,
        apply: (player, currentState) => {
          currentState.clear(player);
          player.addClass('loading');
          return true;
        },
        clear: (player) => {
          player.removeClass('loading');
        },
      },
      mediaEnding: {
        name: 'Media Ending',
        mode: this.MODES.MEDIA,
        apply: (player, currentState) => {
          currentState.clear(player);
          player.addClass('media-ending');
          return true;
        },
        clear: (player) => {
          player.removeClass('media-ending');
        },
      },
      mediaError: {
        name: 'Media Error',
        mode: this.MODES.MEDIA,
        apply: (player, currentState) => {
          currentState.clear(player);
          player.addClass('media-error');
          return true;
        },
        clear: (player) => {
          player.removeClass('media-error');
        },
      },
      pauseAdShowing: {
        name: 'Pause Ad Showing',
        mode: this.MODES.MEDIA,
        apply: (player, currentState) => {
          if (currentState.mode !== this.MODES.AD) {
            currentState.clear(player);
            player.addClass('pause-ad-showing');
            return true;
          }
          return false;
        },
        clear: (player) => {
          player.removeClass('pause-ad-showing');
        },
      },
    };
  }

  _log(...args) {
    debugLog(`[#${this._player.id()}]`, ...args);
  }
}
