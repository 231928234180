import videojs from 'video.js';

const VjsButton = videojs.getComponent('Button');

export default class OverlayMessageCloseButtonComponent extends VjsButton {
  createEl() {
    const props = {
      className: 'vjs-overlay-message-close-button',
      innerHTML: this.options_.label,
    };
    return VjsButton.prototype.createEl('div', props, {});
  }
}
