import videojs from 'video.js';
import debug from 'debug';
import window from 'global/window';

// Setup debugging
const debugLog = debug('viafree-suggestions:log');

const DEFAULT_OPTIONS = {
  countdownTime: 15,
  cuepoint: 0,
  title: '',
  suggestionAction: null,
  suggestion: null /* { img: '', link: '', title: ''} */,
  labels: {
    watchAgain: 'Watch again',
    startingIn: 'Starting in {seconds} seconds',
    startingInOneSecond: 'Starting in {seconds} second',
  },
};

export default class ViafreeSuggestions {
  constructor(player, incomingOptions) {
    this._player = player;

    this._checkOptions(incomingOptions);
    this._player.ready(() => {
      this._player.addClass('vjs-viafree-suggestions-inactive');
      this._addSuggestionsEventListeners();
      this._createSuggestions();
    });
  }

  _addSuggestionsEventListeners() {
    this._player.on('hideSuggestions', () => this._hideSuggestions());
    this._player.on('playSuggestion', () => this._playSuggestion(this._singleSuggestion));
    this._player.on('mediaStart', () => {
      if (this._options.cuepoint < 0) {
        this._addSuggestionCuepointAt(Math.floor(this._player.getMediaDuration() + this._options.cuepoint));
      } else if (this._options.cuepoint > 0) {
        this._addSuggestionCuepointAt(this._options.cuepoint);
      }
      this._player.on('controlEnded', this._atMediaEnded.bind(this));
    });
  }


  _checkOptions(incomingOptions) {
    if (!incomingOptions || incomingOptions.enabled === false) {
      this._log('Viafree suggestions are disabled');
      return;
    }

    this._options = videojs.mergeOptions(DEFAULT_OPTIONS, incomingOptions);
    this.containsSingle = false;
    this._log('options', this._options);

    if (this._options.suggestion) {
      this._singleSuggestion = this._options.suggestion;
      this._log('Suggestion using next episode', this._singleSuggestion);
      this.containsSingle = true;
    }

    this._multipleSuggestions = this._options.suggestions;
    this._log('Suggestion using related content', this._multipleSuggestions);


    if ((!this._singleSuggestion && !this._multipleSuggestions) ||
      ((!this._singleSuggestion && Array.isArray(this._multipleSuggestions) && !this._multipleSuggestions.length))) {
      this._log('No suggestion - not showing anything');
      return;
    }
  }

  _addSuggestionCuepointAt(time) {
    this.suggestionCuepoint = time;
    debugLog('Adding Viafree suggestion cuepoint', this.suggestionCuepoint);
    this._player.trigger({
      type: 'addCuepoint',
      name: 'suggestions',
      time: this.suggestionCuepoint, // Time in seconds to execute the cuepoint callback
      callback: this._atSuggestionsCuepoint.bind(this),
    });

    this._player.on('mediaSeekComplete', this._onMediaSeekComplete.bind(this));
    this._player.on('adRollStart', this._onAdRollStart.bind(this));
  }

  _onMediaSeekComplete(evt) {
    this._log('_onMediaSeekComplete', evt);

    if (evt.afterSeekTime > this.suggestionCuepoint) {
      if (this._player.isPlayingAd && this._player.isPlayingAd()) {
        this._player.one('adRollEnd', this._atSuggestionsCuepoint.bind(this));
      } else {
        this._atSuggestionsCuepoint();
      }
    } else if (this._suggestionsAreVisible()) { // Seeking backward
      this._player.trigger('hideSuggestions');
    }
  }

  _onAdRollStart() {
    if (!this._suggestionsAreVisible()) return;

    this._player.trigger('hideSuggestions');
    if (this.containsSingle) this._player.one('adRollEnd', this._showSingleSuggestionScreen.bind(this));
    else this._player.one('adRollEnd', this._showMultipleSuggestionsScreen.bind(this));
  }

  _playSuggestion(suggestion) {
    this._player.trigger('suggestionClick');
    this._player.clearInterval(this.countdownInterval);

    if (suggestion.onPlay) {
      this._log('_playSuggestion, calling onPlay callback');
      this._player.trigger('hideSuggestions');
      suggestion.onPlay();
    } else {
      this._log('_playSuggestion, redirecting');
      this._player.trigger('dispose');
      window.location.href = suggestion.link;
    }
  }

  _atMediaEnded() {
    this._log('_atMediaEnded, showing suggestion');
    this._player.removeClass('vjs-viafree-suggestions-shrink-player');
    this._player.one(['mediaPlay', 'mediaSeek'], () => {
      this._log('-- startOver');
      this._player.trigger('hideSuggestions');
      if (this._options.shrinkPlayer) {
        this._player.addClass('vjs-viafree-suggestions-shrink-player');
      }
    });
    this._showMultipleSuggestionsScreen();
  }

  _atSuggestionsCuepoint(cuepoint, name) {
    const remainingTime = Math.round(this._player.getMediaDuration() - this._player.currentTime());
    this._log('_atSuggestionsCuepoint, showing suggestion', cuepoint, name, remainingTime);
    if (this.containsSingle && remainingTime > 2) this._showSingleSuggestionScreen();
    else this._showMultipleSuggestionsScreen();

    return false; // don't remove the cuepoint
  }

  _showSingleSuggestionScreen() {
    this._log('_showSingleSuggestionsScreen');
    if (this._suggestionsAreVisible()) return;

    // On iOS we can't show the sugestions while in fullsreen. Wait for the player to exit fullscreen before
    // showing the suggestions.
    if (this._player.env.iOS && this._player.isFullscreen) {
      this._player.one('fullscreenchange', this._showSingleSuggestionScreen.bind(this));
      return;
    }

    this._player.one('seeking', () => this._player.trigger('hideSuggestions'));
    this._className = 'vjs-viafree-suggestions-active-single';
    this._showSuggestions();

    if (this._options.countdownTime >= 0) {
      this._startCountdown();
    }
  }

  _showMultipleSuggestionsScreen() {
    this._log('_showMultipleSuggestionsScreen multiple');
    this._player.trigger('hideSuggestions');

    // On iOS we can't show the sugestions while in fullsreen. Wait for the player to exit fullscreen before
    // showing the suggestions.
    if (this._player.env.iOS && this._player.isFullscreen) {
      this._player.one('fullscreenchange', this._showMultipleSuggestionsScreen.bind(this));
      return;
    }
    this._player.one('seeking', () => this._player.trigger('hideSuggestions'));
    this._className = 'vjs-viafree-suggestions-active-multiple';
    this.playerClickableOverlay.addClass('vjs-viafree-suggestions-left-margin');
    this.playerClickableOverlay.addClass('vjs-icon-fullscreen-enter');
    this._player.tech_.el().classList.add('vjs-viafree-suggestions-item-size');
    this._showSuggestions();
  }

  _addChildComponent(parentComponent, type, props) {
    return parentComponent.addChild('componentSuggestion', {
      componentClass: 'Component',
      el: videojs.getComponent('Component').prototype.createEl(type, props),
    });
  }

  _createSingleSuggestionUI() {
    const suggestionComponent = this._createSuggestionComponent(this._player, this._singleSuggestion);
    const title = this._singleSuggestion.title || '';

    this._addChildComponent(suggestionComponent, 'div', {
      className: 'vjs-viafree-suggestion-type',
      innerHTML: `<div>${this._options.nextType}</div>`,
    });

    this._addChildComponent(suggestionComponent, 'div', {
      className: 'vjs-viafree-suggestion-video-name',
      innerHTML: `<div>${title}</div>`,
    });

    const closeButton = this._addChildComponent(suggestionComponent, 'div', {
      className: 'vjs-viafree-suggestion-close-button',
      innerHTML: 'X',
    });

    closeButton.on(['click', 'touchend'], (event) => {
      event.stopPropagation();
      this._player.trigger('hideSuggestions');
    });

    this._createCountdown(suggestionComponent);
  }

  _createMutlipleSuggestionUI() {
    // Add the shrunken player
    if (this._options.shrinkPlayer) {
      // Add clickable overlay for the player
      this.playerClickableOverlay = this._addChildComponent(this._player, 'div', {
        className: `vjs-player-clickable-overlay vjs-viafree-suggestions-item-size
        vjs-icon-fullscreen-enter vjs-viafree-suggestions-left-margin`,
      });

      const onMouseoverSmallPlayer = (e) => {
        this._player.tech_.el().classList.add('vjs-viafree-suggestions-small-player-hovered');
        e.stopPropagation();
        e.preventDefault();
        return false;
      };

      const onMouseoutSmallPlayer = (e) => {
        this._player.tech_.el().classList.remove('vjs-viafree-suggestions-small-player-hovered');
        e.stopPropagation();
        e.preventDefault();
        return false;
      };

      const onClickGrowPlayer = (e) => {
        this.playerClickableOverlay.removeClass('vjs-viafree-suggestions-left-margin');
        this.playerClickableOverlay.removeClass('vjs-icon-fullscreen-enter');
        // This code should be used to fix the transition on grow problem
        // this._player.tech_.el().style.top = `${this._player.tech_.el().offsetTop}px`;
        // this._player.tech_.el().style.left = `${this._player.tech_.el().offsetLeft}px`;
        // this._player.tech_.el().style.width = `${this._player.tech_.el().offsetWidth}px`;
        // this._player.tech_.el().style.height = `${this._player.tech_.el().offsetHeight}px`;
        // this._player.tech_.el().style.position = "absolute";
        this._player.trigger('hideSuggestions');
        e.stopPropagation();
        e.preventDefault();
        return false;
      };
      this.playerClickableOverlay.on(['click', 'touchEnd'], onClickGrowPlayer.bind(this));
      this.playerClickableOverlay.on('mouseover', onMouseoverSmallPlayer.bind(this));
      this.playerClickableOverlay.on('mouseout', onMouseoutSmallPlayer.bind(this));
      this._player.addClass('vjs-viafree-suggestions-shrink-player');
    }

    // Add top section
    const suggestionsTop = this._addChildComponent(this._player, 'div', {
      className: 'vjs-viafree-suggestions-top multiple',
    });

    const itemWrapper = this._addChildComponent(suggestionsTop, 'div', {
      className: 'vjs-viafree-suggestions-items-wrapper',
    });

    this._addChildComponent(itemWrapper, 'p', {
      className: 'vjs-viafree-suggestions-title',
      innerHTML: `${this._options.labels.title}`,
    });

    const suggestionsItems = this._addChildComponent(itemWrapper, 'div', {
      className: 'vjs-viafree-suggestions-items',
    });

    // Populate items
    this._multipleSuggestions
      .forEach(suggestion => this._createSuggestionComponent(suggestionsItems, suggestion, true));

    // Add the upsell section
    if (this._options.upsell) {
      const suggestionsUpsell = this._addChildComponent(this._player, 'div', {
        className: 'vjs-viafree-suggestions-upsell multiple',
      });

      this._addChildComponent(suggestionsUpsell, 'div', {
        className: 'vjs-viafree-suggestions-upsell-text',
        innerHTML: `${this._options.upsellCreateAccount.upsellText}`,
      });

      this._addChildComponent(suggestionsUpsell, 'a', {
        className: 'vjs-viafree-suggestion-redirect-button',
        innerHTML: `${this._options.labels.createAccount}`,
        href: `${this._options.upsellCreateAccount.createAccountLink}`,
      });
      this._player.addClass('vjs-viafree-suggestions-show-upsell');
    }
  }

  _createSuggestions() {
    if (this.containsSingle) {
      this._createSingleSuggestionUI();
    }
    this._createMutlipleSuggestionUI();
  }

  _createCountdown(suggestionComponent) {
    const seconds = this._options.countdownTime;
    const label = this._options.labels.startingIn;

    const startingIn = this._addChildComponent(suggestionComponent, 'div', {
      className: 'vjs-viafree-suggestions-starting-in',
    });

    startingIn.on(['click', 'touchend'], () => {
      this._playSuggestion(this._singleSuggestion);
    });

    this._countdownComponent = this._addChildComponent(startingIn, 'div', {
      innerHTML: label.replace('{seconds}', seconds),
    });
  }

  _startCountdown() {
    const remainingTime = Math.round(this._player.getMediaDuration() - this._player.currentTime());
    let seconds = this._options.countdownTime > remainingTime ? remainingTime - 2 : this._options.countdownTime;
    let paused = false;
    // should not need to be cleared here. but make sure that we don't create two countdowns
    // if this method is called twice
    this._player.clearInterval(this.countdownInterval);
    this.countdownInterval = this._player.setInterval(() => {
      if (paused) return;
      seconds -= 1;
      let startingIn = null;
      if (seconds === 1) {
        startingIn = this._options.labels.startingInOneSecond;
      } else {
        startingIn = this._options.labels.startingIn;
      }
      startingIn = this._options.labels.startingIn;

      if (seconds < 1) {
        this._playSuggestion(this._singleSuggestion);
      } else {
        this._countdownComponent.el().innerHTML = startingIn.replace('{seconds}', seconds);
        this._log('updateCountdownTime', seconds);
        this._player.trigger({
          type: 'updateCountdownTime',
          countdownTime: seconds,
        });
      }
    }, 1000);

    this._player.on('pause', () => {
      if (!paused && !this._player.ended()) paused = true;
    });

    this._player.on('play', () => {
      if (paused) paused = false;
    });
  }

  _createSuggestionComponent(suggestionsComponent, suggestion, multiple) {
    const metaParams = {
      className: 'vjs-viafree-suggestions-suggestion-meta',
    };

    if (multiple) {
      metaParams.innerHTML = `<p>${suggestion.title}</p>`;
    }

    const suggestionComponent = this._addChildComponent(suggestionsComponent, 'div', {
      className: `vjs-viafree-suggestions-suggestion ${multiple ? 'vjs-viafree-suggestions-item-size' : 'single'}`,
    });

    suggestionComponent.on(['click', 'touchend'], () => this._playSuggestion(suggestion));

    this._addChildComponent(suggestionComponent, 'img', {
      src: suggestion.img,
      className: 'vjs-viafree-suggestions-suggestion-overlay-image',
    });

    return this._addChildComponent(suggestionComponent, 'div', metaParams);
  }

  _showSuggestions() {
    this._player.trigger('suggestionsShown', { style: this._style, suggestionAction: this._options.suggestionAction });
    this._player.removeClass('vjs-viafree-suggestions-inactive');
    this._player.addClass('vjs-viafree-suggestions-active');
    this._player.addClass(this._className);
    if (this._options.themeSuggestions) {
      this._player.poster(this._options.themeSuggestions.suggestionsPoster);
      this._player.tech_.el().removeAttribute('poster'); // Safari fix
    }
    this._suggestionsShowing = true;
  }

  _hideSuggestions() {
    this._log('_hideSuggestions');
    this._player.clearInterval(this.countdownInterval);
    this._player.trigger('suggestionsHidden');
    this._player.removeClass('vjs-viafree-suggestions-active');
    this._player.removeClass('vjs-viafree-suggestions-active-single');
    this._player.addClass('vjs-viafree-suggestions-inactive');
    this._player.removeClass(this._className);
    this._suggestionsShowing = false;
  }

  // Returns true if any suggestions are showing, not only from this plugin instance.
  _suggestionsAreVisible() {
    return this._player.hasClass('vjs-viafree-suggestions-active');
  }

  _log(...args) {
    debugLog(`[#${this._player.id()}][#${this._id}]`, ...args);
  }
}

videojs.plugin('viafreeSuggestions', function init(options) {
  if (Array.isArray(options)) options.forEach(_options => new ViafreeSuggestions(this, _options));
  else this.suggestions = new ViafreeSuggestions(this, options);
});
