import videojs from 'video.js';
import debug from 'debug';

// Setup debugging
const debugLog = debug('ga:log');
const errorLog = debug('ga:error');

const DEFAULT_OPTIONS = {
  trackingFunctionName: 'ga',
  trackers: [
    {
      options: { name: '' },
    },
  ],
  getCustomDimensions: [],
};

/**
 * Tracked events:
 * - Play
 * - Pause
 * - Total Start
 * - Preroll_start
 * - Ad_in_Preroll_start
 * - Preroll_finish
 * - Advertiser_link
 * - Start
 * - Fullscreen
 * - Midroll#_start
 * - Midroll#_finish
 * - Ad_in_Midroll_start
 * - 10, 20, …, 100
 * - Postroll_start
 * - Ad_in_Postroll_start
 * - Postroll_finish
 * - Ad_blocker
 * - Ad_Stalled
 * - Ad_Error
 * - Media_Error
 * - Share
 */
export default class GoogleAnalyticsPlugin {
  constructor(player, options) {
    this._player = player;
    this._hasAdblocker = false;
    this._adType = null;

    const errors = this._validateOptions(options);
    if (errors.length) {
      this._error('Invalid Google Analytics Configuration', errors);
      return;
    }

    this._options = videojs.mergeOptions(DEFAULT_OPTIONS, options);

    this._log('options', this._options);

    // we need to wait until Analytics.js has loaded until we can finish our setup,
    // which includes checking for existing trackers (not possible until lib loaded)
    this._getGa()(() => {
      // ga will swallow all exceptions that happen inside this callback function,
      // so make sure to catch and log them at least
      try {
        this._setUpTrackers();
      } catch (e) {
        this._log('Callback error:', e);
        throw e;
      }
    });

    // we still want to setup our listeners as soon as possible, so that we don't miss any events
    // (Analytics.js will queue any events until the lib has loaded)
    this._setUpListeners();
  }

  // helper function to get the current ga object to use (default name is "ga", but it is customizable)
  // also, the window.ga (or whatever the name) function is actually *REDEFINED* after analytics.js has loaded (async),
  // which means we can't just keep a reference to window.ga, since this reference wouldn't get updated when
  // analytics.js has loaded (we can't do: this._ga = window[options.trackingFunctionName] because our
  // reference wouldn't get updated)
  _getGa() {
    return window[this._options.trackingFunctionName];
  }

  _setUpTrackers() {
    this._log('_setUpTrackers');

    const trackers = this._options.trackers;

    trackers.forEach((trackerConf, index) => {
      const conf = trackerConf;
      const tracker = this._getTrackerByTrackingId(trackerConf.tid);

      // if any of the trackers we're supposed to report to was configured with a name, and the name doesn't
      // match with the real tracker name, abort (Analytics.js would silently fail when trying to send events
      // on this tracker)
      if (trackerConf.options && trackerConf.options.name &&
        tracker && tracker.get('name') !== trackerConf.options.name) {
        throw new Error(`the tracker name ${
          trackerConf.options.name} did not match the real name of ${tracker.get('name')}`);
      }

      if (tracker) {
        conf.options = trackerConf.options || {};
        conf.options.name = tracker.get('name');

        tracker.set(trackerConf.options);
      } else {
        conf.options = trackerConf.options || {};
        conf.options.name = trackerConf.options.name || `tracker${index}`;

        this._getGa()('create', trackerConf.tid, 'auto', trackerConf.options);
      }
    });
  }

  _setUpListeners() {
    this._log('_setUpListeners');

    const _this = this;
    function createEventTracker(eventName) {
      return _this._trackEvent.bind(_this, eventName);
    }

    const evtNames = this._isViasport() ? this._getViasportEventNames() : this._getViafreeEventNames();

    // General player tracking
    if (evtNames.totalStart) this._player.on('controlStart', createEventTracker(evtNames.totalStart));
    if (evtNames.start) this._player.on('mediaStart', createEventTracker(evtNames.start));
    if (evtNames.pause) this._player.on('controlPause', () => this._onPause(evtNames));
    if (evtNames.play) this._player.on('controlPlay', () => this._onPlay(evtNames));

    if (evtNames.fullscreen) this._player.on('fullscreenEnter', createEventTracker(evtNames.fullscreen));
    if (evtNames.share) this._player.on('share', createEventTracker(evtNames.share));
    if (evtNames.adBlock) this._player.one('adBlock', () => this._onAdblock(evtNames.adBlock));

    // Ad pause/play
    if (evtNames.prerollPause) this._player.on('adPrerollStart', () => { this._adType = 'preroll'; });
    if (evtNames.midrollPause) this._player.on('adMidrollStart', () => { this._adType = 'midroll'; });
    if (evtNames.postrollPause) this._player.on('adPostrollStart', () => { this._adType = 'postroll'; });

    if (evtNames.prerollPause || evtNames.midrollPause || evtNames.postrollPause) {
      this._player.on('adRollEnd', () => { this._adType = null; });
    }

    // Prerolls
    if (evtNames.prerollStart) this._player.on('adPrerollStart', createEventTracker(evtNames.prerollStart));
    if (evtNames.adInPrerollStart) this._player.on('adInPrerollStart', createEventTracker(evtNames.adInPrerollStart));
    if (evtNames.prerollFinish) this._player.on('adPrerollEnd', createEventTracker(evtNames.prerollFinish));

    // Midrolls
    if (evtNames.midrollStart) {
      this._player.on('adMidrollStart', (evt, data) => {
        this._onMidrollStart(evt, data, evtNames.midrollStart);
      });
    }
    if (evtNames.adInMidrollStart) this._player.on('adInMidrollStart', createEventTracker(evtNames.adInMidrollStart));
    if (evtNames.midrollFinish) {
      this._player.on('adMidrollEnd', (evt, data) => {
        this._onMidrollFinish(evt, data, evtNames.midrollFinish);
      });
    }

    // Postrolls
    if (evtNames.postrollStart) this._player.on('adPostrollStart', createEventTracker(evtNames.postrollStart));
    if (evtNames.adInPostrollStart) {
      this._player.on('adInPostrollStart', createEventTracker(evtNames.adInPostrollStart));
    }
    if (evtNames.postrollFinish) this._player.on('adPostrollEnd', createEventTracker(evtNames.postrollFinish));

    // Other Ad events
    if (evtNames.advertiserLink) this._player.on('adClickThrough', createEventTracker(evtNames.advertiserLink));
    if (evtNames.adStalled) this._player.on('adStalled', createEventTracker(evtNames.adStalled));

    // End of video suggestions clicks
    if (evtNames.suggestionClick) this._player.on('suggestionClick', createEventTracker(evtNames.suggestionClick));

    // Progress tracking
    this._player.on('mediaProgress', this._onProgress.bind(this));
    this._player.on('mediaEnd', createEventTracker('100'));

    // Mobile device unmuting
    this._player.on('initialMobileUnmuteInAd', createEventTracker(evtNames.initialMobileUnmuteInAd));
    this._player.on('initialMobileUnmuteInContent', createEventTracker(evtNames.initialMobileUnmuteInContent));
    this._player.on('mobileUnmute', createEventTracker(evtNames.mobileUnmute));
    this._player.on('mobileMute', createEventTracker(evtNames.mobileMute));

    // Errors
    this._player.on('mediaError', createEventTracker('Media_Error'));
    this._player.on('adError', createEventTracker('Ad_Error'));
  }

  _getViafreeEventNames() {
    return {
      totalStart: 'Total Start',
      start: 'Start',
      play: 'Play',
      pause: 'Pause',
      fullscreen: 'Fullscreen',
      share: 'Share',
      adBlock: 'Ad_blocker',
      prerollStart: 'Preroll_start',
      adInPrerollStart: 'Ad_in_Preroll_start',
      prerollFinish: 'Preroll_finish',
      midrollStart: 'Midroll%POSITION%_start',
      adInMidrollStart: 'Ad_in_Midroll_start',
      midrollFinish: 'Midroll%POSITION%_finish',
      postrollStart: 'Postroll_start',
      adInPostrollStart: 'Ad_in_Postroll_start',
      postrollFinish: 'Postroll_finish',
      adStalled: 'Ad_Stalled',
      advertiserLink: 'Advertiser_link',
      initialMobileUnmuteInAd: 'Initial_Mobile_Unmute_Ad',
      initialMobileUnmuteInContent: 'Initial_Mobile_Unmute_Content',
      mobileUnmute: 'Mobile_Unmute',
      mobileMute: 'Mobile_Mute',
    };
  }

  _getViasportEventNames() {
    return {
      totalStart: 'Total Start',
      start: 'Start',
      pause: 'Pause',
      play: 'Play',
      fullscreen: 'Fullscreen',
      share: 'Share',
      adBlock: 'Ad Block',
      prerollStart: 'Preroll Start',
      prerollFinish: 'Preroll Finish',
      midrollStart: 'Midroll Start',
      midrollFinish: 'Midroll Finish',
      postrollStart: 'Postroll Start',
      postrollFinish: 'Postroll Finish',
      prerollPlay: 'Preroll Play',
      prerollPause: 'Preroll Pause',
      midrollPlay: 'Midroll Play',
      midrollPause: 'Midroll Pause',
      postrollPlay: 'Postroll Play',
      postrollPause: 'Postroll Pause',
      advertiserLink: 'Advertiser Link',
      adStalled: 'Ad Stalled',
      suggestionClick: 'Post video suggestion',
      initialMobileUnmuteInAd: 'Initial Mobile Unmute (Ad)',
      initialMobileUnmuteInContent: 'Initial Mobile Unmute (Content)',
      mobileUnmute: 'Mobile Unmute',
      mobileMute: 'Mobile Mute',
    };
  }

  _trackEvent(eventAction) {
    if (this._isViasport() && this._hasAdblocker && eventAction !== this._getViasportEventNames().totalStart) return;

    this._log('_trackEvent', eventAction);

    const customDimensions = this._options.getCustomDimensions;
    const trackers = this._options.trackers;

    const gaObject = {
      hitType: 'event',
      eventCategory: 'Video',
      eventAction,
      eventLabel: this._options.eventLabel,
    };

    customDimensions.forEach((dimension, i) => {
      gaObject[`dimension${(i + 1)}`] = dimension;
    });

    trackers.forEach((tracker) => {
      const trackerNamePrefix = (tracker.options && tracker.options.name) ? `${tracker.options.name}.` : '';
      const action = `${trackerNamePrefix}send`;

      this._log('_trackEvent: tracking event', action, gaObject);
      this._getGa()(action, gaObject);
    });
  }

  _onProgress(evt) {
    this._log('_onProgress', evt);
    const progress = this._options.progressResolution;

    if (evt.percentage === 100) return;

    if (progress && (evt.percentage % progress) !== 0) return;

    this._trackEvent(evt.percentage.toString());
  }

  _onMidrollStart(evt, data, name) {
    this._log('_onMidrollStart', data.position);
    const eventName = name.replace('%POSITION%', data.position);
    this._trackEvent(eventName);
  }

  _onMidrollFinish(evt, data, name) {
    this._log('_onMidrollFinish', data.position);
    const eventName = name.replace('%POSITION%', data.position);
    this._trackEvent(eventName);
  }

  _onAdblock(eventName) {
    this._trackEvent(eventName);
    this._hasAdblocker = true;
  }

  _onPlay(eventNames) {
    if (this._adType === 'preroll') this._trackEvent(eventNames.prerollPlay);
    else if (this._adType === 'midroll') this._trackEvent(eventNames.midrollPlay);
    else if (this._adType === 'postroll') this._trackEvent(eventNames.postrollPlay);
    else this._trackEvent(eventNames.play);
  }

  _onPause(eventNames) {
    if (this._adType === 'preroll') this._trackEvent(eventNames.prerollPause);
    else if (this._adType === 'midroll') this._trackEvent(eventNames.midrollPause);
    else if (this._adType === 'postroll') this._trackEvent(eventNames.postrollPause);
    else this._trackEvent(eventNames.pause);
  }

  _getTrackerByTrackingId(trackingId) {
    this._log('_getTrackerByTrackingId', trackingId);

    return this._getGa().getAll().filter(tracker => tracker.get('trackingId') === trackingId)[0];
  }

  _isViasport() {
    return this._options.client === 'viasport';
  }

  _validateOptions(options) {
    const errors = [];

    if (!options.trackers) errors.push('options.trackers needs to be specified');
    if (options.trackers.constructor !== Array) errors.push('options.trackers needs to be an Array');
    if (options.trackers.length === 0) errors.push('options.trackers cannot be empty');
    if (options.trackingFunctionName && !window[options.trackingFunctionName]) {
      errors.push(`window.${options.trackingFunctionName} does not exist`);
    }

    options.trackers.forEach((tracker) => {
      if (tracker.options && tracker.options.name && !/^[0-9a-z_]*$/i.test(tracker.options.name)) {
        errors.push('options.trackers[].options.name can only contain alphanumeric characters and _');
      }
    });

    return errors;
  }

  _log(...args) {
    debugLog(`[#${this._player.id()}]`, ...args);
  }

  _error(...args) {
    errorLog(`[#${this._player.id()}]`, ...args);
    console.error('> avodp - ga error >>', args[0]); // eslint-disable-line no-console
  }
}

videojs.plugin('googleAnalytics', function init(options) {
  this.ga = new GoogleAnalyticsPlugin(this, options);
});
