import videojs from 'video.js';
import debug from 'debug';

// Setup debugging
const debugLog = debug('AgeGating');

const DEFAULT_OPTIONS = {
  enabled: false,
  labels: {
    prefix: 'Are you over 18?',
    yes: 'Yes',
    no: 'No',
    suffix: '',
    noMessage: 'You are not old enough.',
  },
};

export default class AgeGating {
  constructor(player, incomingOptions) {
    if (!incomingOptions) throw new Error('no options provided');

    this._player = player;
    this._options = videojs.mergeOptions(DEFAULT_OPTIONS, incomingOptions);

    this._log('options', this._options);

    if (!this._options.enabled) {
      return;
    }

    player.el().classList.add('vjs-age-gating');

    this._isShowing = false;
    this._isAgeConfirmed = false;

    // interupt playback
    this._fakePlayHandler = this._onPlay.bind(this);

    this._player.trigger({
      type: 'registerVideoStartBehavior',
      name: 'AGE_GATING',
      playHandler: this._fakePlayHandler,
    });
  }

  _onPlay() {
    this._log('Calling fake play function');
    if (this._isShowing || this._isAgeConfirmed) {
      return;
    }

    this._player.trigger('ageGating.shown');
    this._isShowing = true;

    this._showAgeGate();
  }

  _showAgeGate() {
    this._overlay = this._addContainer('ageGatingOverlay', this._player, { className: 'vjs-age-gating-overlay' });
    this._questionsContainer = this._addContainer('', this._overlay, {});

    this._addLabel('', this._questionsContainer,
      { className: 'vjs-age-gating-prefix', innerHTML: this._options.labels.prefix });

    const answers = this._addContainer('', this._questionsContainer, { className: 'vjs-age-gating-buttons' });

    this._addButton(
      '',
      answers,
      {
        className: 'vjs-age-gating-no-button',
        innerHTML: this._options.labels.no,
      },
      this._ageConfirmedNo.bind(this)
    );

    this._addButton('', answers,
      {
        className: 'vjs-age-gating-yes-button',
        innerHTML: this._options.labels.yes,
      },
      this._ageConfirmedYes.bind(this)
    );

    this._addLabel('', this._questionsContainer,
      { className: 'vjs-age-gating-suffix', innerHTML: this._options.labels.suffix });
  }

  _ageConfirmedYes() {
    this._isShowing = false;
    this._isAgeConfirmed = true;

    this._player.removeChild(this._overlay);

    this._player.trigger({
      type: 'removeVideoStartBehavior',
      playHandler: this._fakePlayHandler,
      forcePlay: true,
    });

    this._player.trigger('ageGating.yesClicked');
  }

  _ageConfirmedNo() {
    this._overlay.removeChild(this._questionsContainer);

    const noContainer = this._addContainer('ageGatingNoContainer', this._overlay, {});

    this._addLabel('', noContainer, {
      className: 'vjs-age-gating-no-message',
      innerHTML: this._options.labels.noMessage,
    });

    this._player.trigger('ageGating.noClicked');
  }

  _addLabel(name, target, options) {
    return this._addContainer(name, target, options);
  }

  _addContainer(name, target, options) {
    const container = target.addChild(name, {
      componentClass: 'Component',
      el: videojs.getComponent('Component').prototype.createEl('div', options),
    });
    container.on('click', (evt) => {
      // This is to avoid click events being bubbled to
      // the video object and trigger a click through on the preroll
      // Happens when autoplay is activated
      evt.stopPropagation();
    });
    return container;
  }

  _addButton(name, target, options, onClick) {
    const button = target.addChild(name,
      { componentClass: 'Button', el: videojs.getComponent('Component').prototype.createEl('div', options) }
    );
    button.on('click', onClick);
    button.on('touchend', onClick); // The click event isn't triggered on touch devices
    return button;
  }

  _log(...args) {
    debugLog(`[#${this._player.id()}]`, ...args);
  }
}

videojs.plugin('ageGating', function init(options) {
  this.ageGating = new AgeGating(this, options);
});
