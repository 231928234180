import vjs from 'video.js';
import debug from 'debug';
import 'videojs-contrib-hls';
import '../plugins/mtgx-skin/src/mtgx-skin';
import '../plugins/cuepoints/src/cuepoints';
import '../plugins/comscore/src/comscore-tracking';
import '../plugins/suggestions/src/suggestions';
import '../plugins/viafree-suggestions/src/viafree-suggestions';
import '../plugins/gtm-datalayer/src/gtm-datalayer';
import '../plugins/events/src/events';
import '../plugins/freewheel/src/freewheel';
import '../plugins/countdown/src/countdown';
import '../plugins/start-manager/src/start-manager';
import '../plugins/src-loader/src/src-loader';
import '../plugins/player-terminator/src/player-terminator';
import '../plugins/visual-ad-cues/src/visual-ad-cues';
import '../plugins/timeline-thumbnails/src/timeline-thumbnails';
import '../plugins/meetrics/src/meetrics';
import './techs/castTech';

import * as sourceOrder from './source-order';
import AkamaiStreamingTools from './util/akamai-streaming-tools';
import browserUtils from './util/browser';
import mimetypes from './util/mimetypes';
import bitrateUtil from './util/bitrate';
import m3u8Util from './util/m3u8';
import meta from './util/meta';
import fullScreenApi from './util/fullscreen-api';
import shortcuts from './util/shortcuts';
import languages from './languages';
import CustomFullScreenToggle from './fullscreen-toggle';
import StateManager from './state-manager';
import '../plugins/client-stream/src/client-stream';
import '../plugins/ga/src/google-analytics';
import '../plugins/gallup/src/gallup-tracking';

delete window.videojs; // Not needed globally since we are using modules
delete window.vjs;

// Debugging (activate with avodp.debug.enable('*') in console)
window.avodp = { debug };
const debugLog = debug('avodplayer:log');
const debugWarn = debug('avodplayer:warn');
debugWarn.log = console.warn.bind(console); // eslint-disable-line no-console
const debugError = debug('avodplayer:error');
debugError.log = console.error.bind(console); // eslint-disable-line no-console

// Hide VJS warnings and error messages (mostly weird deprecation errors that are not relevant)
vjs.log.warn = (...args) => {
  debugWarn('VJS Warning', args);
  return;
};
vjs.log.error = (...args) => {
  debugError('VJS Error', ...args);
  console.error('> avodp - vjs error >>', args[0]); // eslint-disable-line no-console
  return;
};

const defaultOptions = {
  controls: false,
  techOrder: ['castTech', 'html5'],
  plugins: {
    startManager: {},
    cuepoints: {},
    events: {},
    playerTerminator: {},
    overlayMessage: {},
  },
  controlBar: {
    volumeMenuButton: {
      vertical: true,
      inline: false,
    },
  },
  language: window.language === undefined ? 'en' : window.language,
  languages,
};

class AVODPlayer {
  constructor(elementId, options) {
    if (!browserUtils.env.chromecast) this._dynamicImports();
    if (options.chromecast && !browserUtils.env.iOS) {
      const ccSender = this._importSender();
      this.languages = languages;
      this._options = options;
      ccSender.mediaSetup(elementId, options.chromecast, this);
      if (ccSender.isConnected()) {
        return;
      }
    }

    this._originalElementId = elementId;
    this._originalOptions = { ...options };
    this._options = options;

    // Grab options that we are using and send the rest into VideoJS
    const autoplay = this._options.autoplay;
    const muted = this._options.muted;
    const plugins = this._options.plugins;
    const language = this._options.language;
    const assumeImplicitSignaling = this._options.assumeImplicitSignaling;

    this._id = elementId;

    if (typeof elementId === 'string') {
      this._playerElement = document.getElementById(elementId);
    } else { // Unit tests provide an element instead of an id
      this._playerElement = elementId;
      this._id = elementId && elementId.id;
    }

    if (!this._playerElement) {
      debugError(`Element with id ${this._id} does not exist`);
      return;
    }

    this._loadFromDiv = this._playerElement.tagName === 'DIV';

    if (this._loadFromDiv) this._handlePlayerElement(this._id, this._playerElement);

    const optionsForVideojs = { autoplay, muted, plugins, language, controlBar: {} };

    // Fix Chrome audio codec issue
    if (assumeImplicitSignaling && browserUtils.env.chrome) {
      m3u8Util.fixImplicitSignaling(vjs);
    }

    this._vjsOptions = this._getVJSPlayerOptions(optionsForVideojs);
    this._log('Options passed to Video.js', this._vjsOptions);

    // Even before loading the vjs player is loaded, we want to display a loader when in autoplay
    if (this._vjsOptions.autoplay) this._playerElement.classList.add('autoplay');

    this._player = vjs(this._playerElement, this._vjsOptions);

    this._checkForUnsupportedBrowser();

    this._stateManager = new StateManager(this._player);

    this._seekToOffset();

    this._initVideoControlsBehavior();
    this._setupSubtitles();
    this._fixBrowserBugs();
    this._updateContextMenu();

    if (this._options.autoplay) this._setupAutoplayHandlers();

    if (this._options.timeBox && this._options.timeBox.endTimestamp) {
      this._translateTimeBoxEndOptions();
    }

    // Setting the player source
    if (this._options.sources) {
      const orderedSources = this.configureSources(this._options.sources);
      if (!this._vjsOptions.plugins.freewheel || browserUtils.env.mobile) {
        this._player.src(orderedSources);
      } else {
        this._player.one(['adPrerollEnd', 'noPrerolls'], () => {
          this._log('Setting Player Source to Content Stream');
          this._player.src(orderedSources);
        });
      }
    }

    if (this._loadFromDiv) this._setupDivFullScreenBehavior();

    // Expose the AVOD Player to plugins by extending the VJS player instance
    this._player.originalOptions = this._options;
    this._player.bitrate = bitrateUtil;
    this._player.env = browserUtils.env; // Useful for debugging detected userAgent
    this._player.meta = meta; // Contains player name and version

    this._player.isLive = this._isLive.bind(this);
    this._player.getMediaDuration = this.getMediaDuration.bind(this);
    this._player.getMediaCurrentTime = this.getMediaCurrentTime.bind(this);
    this._player.isPlayingAd = this._isPlayingAd.bind(this);
    this._player.getCurrentSubtitle = this._getCurrentSubtitle.bind(this);
    this._player.changeSubtitle = this._changeSubtitle.bind(this);
    this._player.changeSubtitleByType = this._changeSubtitleByType.bind(this);
    this._player.changeSubtitleByLabel = this._changeSubtitleByLabel.bind(this);
    this._player.reload = this._reload.bind(this);
    this._player.configureSources = this.configureSources.bind(this);
    this._player.getAvailableTextTracks = this._getAvailableTextTracks.bind(this);
    this._player.hasAvailableTextTracks = this._hasAvailableTextTracks.bind(this);
    this._player.shouldHaveSubtitles = this._shouldHaveSubtitles.bind(this);
    this._player.connectivity = this.connectivity;

    // Expose the VJS Player to implementations
    this.meta = meta; // Contains player name and version
    this.on = this._player.on.bind(this._player);
    this.one = this._player.one.bind(this._player);
    this.off = this._player.off.bind(this._player);
    this.pause = this._player.pause.bind(this._player);
    this.play = () => { this._player.play(); };
    this.currentTime = this._player.currentTime.bind(this._player);
    this.el = this._player.el.bind(this._player);
    this.duration = this._player.duration.bind(this._player);
    this.currentDimensions = this._player.currentDimensions.bind(this._player);
    this.poster = this._player.poster.bind(this._player);

    if (this._options.reloadCallback && typeof this._options.reloadCallback === 'function') this._setupReload();
    if (this._options.mobileFullscreenPlaying) this._forceMobileFullscreenPlaying();
    if (this._player.env.chromecast) this._setupControlbarBehaviourForChromecast();
    if (!this._player.env.chromecast) this._initConnectivityDetection();

    this._pollForMediaChanges();
    this._forceLiveAfterPause();
    this._deleteLastSegmentIfTooShort();
    this._setControlStartEndClasses();

    shortcuts.init(this._player);
  }

  _dynamicImports() {
    /* eslint-disable */
    require('../plugins/overlay-message/src/overlay-message');
    require( '../plugins/age-gating/src/age-gating');
    require( '../plugins/ad-blocker-blocker/src/ad-blocker-blocker');
    require( '../plugins/domainrestrict/src/domain-restrict');
    require( '../plugins/gemius/src/gemius');
    require( '../plugins/gemius-prism/src/gemius-prism');
    require( '../plugins/meetrics/src/meetrics');

    this.connectivity = require('./connectivity-detection').default;
    /* eslint-enable */
  }

  _importSender() {
    /* eslint-disable */
    return require('../cc-sender/sender.js').default;
    /* eslint-enable */
  }

  // [PLP-8317] If the browser is unsupported destroy the player after the play button has been clicked (or autostart)
  // and display an error message to the user.
  _checkForUnsupportedBrowser() {
    if (!browserUtils.isBrowserSupported()) {
      this._player.trigger({
        type: 'registerVideoStartBehavior',
        name: 'BROWSER_SUPPORT',
        playHandler: () => {
          this._player.trigger('unsupportedBrowser');
          this._player.trigger('destroyPlayer');
          this._player.trigger('showOverlayBlur');
          this._player.trigger('showOverlayMessage', {
            message: this._player.localize('Your web browser is unfortunately not supported'),
          });
        },
      });
    }
  }

  _deleteLastSegmentIfTooShort() {
    this._log('Listening to loadedmetadata event');
    this._player.on('loadedmetadata', () => {
      this._log('Looking to remove last segment if too short');
      if (this._player.tech_.hls && this._player.tech_.hls.playlists && this._player.tech_.hls.playlists.media_) {
        const segmentsArray = this._player.tech_.hls.playlists.media_.segments;
        const lastSegment = segmentsArray[segmentsArray.length - 1];
        if (lastSegment && lastSegment.duration < 0.2) {
          this._log('Removed last segment because it is too short');
          segmentsArray.pop();
        }
      }
    });
  }

  _forceLiveAfterPause() {
    this._player.on('mediaPlay', () => {
      if (
        this._player.isLive() && // We can only check for live after the stream has loaded.
        this._player.seekable() &&
        this._player.seekable().length &&
        this._player.seekable().end(0) !== Infinity &&
        Math.abs(this._player.seekable().end(0) - this._player.currentTime()) > 10 // we are more than 10s from live
      ) {
        setTimeout(() => { // A timeout to make sure that we are triggered after the native/vjs restore function
          this._player.currentTime(this._player.seekable().end(0));
          this._player.trigger('forceLiveAfterPause');
        }, 0);
      }
    });
  }

  /**
   * If the element ID for the player belongs to a <div>, any existing avod player inside
   * the <div> will be disposed, and a <video> element is created as its child and video.js
   * will be initialized on the <video> element.
   */
  _handlePlayerElement(id, elm) {
    debugLog('_handlePlayerElement');

    const vjsElmId = `${id}_vjs`;
    const existingVjsPlayer = vjs.getPlayers()[vjsElmId];

    if (existingVjsPlayer) {
      debugLog(`_handlePlayerElement: AVOD Player already initalized on element #${id}, disposing it`);

      this._options.fullscreen = existingVjsPlayer.isFullscreen();
      existingVjsPlayer.dispose();
      existingVjsPlayer.trigger = () => { }; // Block lingering events after the player has been disposed.
      existingVjsPlayer.el_ = existingVjsPlayer.el_ || { className: '' };
      existingVjsPlayer.tech_.el_ = existingVjsPlayer.tech_.el_ || {};
    }

    debugLog('_handlePlayerElement: Initializing video.js on new video element');
    const newVideo = document.createElement('video');
    newVideo.id = vjsElmId;
    newVideo.setAttribute('class', 'avodp');

    if (this._useMobileAutoplay()) {
      newVideo.setAttribute('playsinline', 'playsinline');
      newVideo.setAttribute('muted', 'muted');
    }

    elm.appendChild(newVideo);

    this._playerElement = newVideo;
    this._id = vjsElmId;
  }

  _setupReload() {
    const reloadButton = document.createElement('button');
    reloadButton.innerHTML = this._player.localize('Try again');
    reloadButton.addEventListener('click', this._reload.bind(this));
    reloadButton.className = 'reload';
    this._player.getChild('errorDisplay').el().appendChild(reloadButton);
  }

  _seekToOffset() {
    const seekTo = (time) => {
      this._log('_seekToOffset', time);

      if (this._player.freewheel && this._player.freewheel.setSkipAdAfterNextSeek) {
        this._player.freewheel.setSkipAdAfterNextSeek(true);
      }
      this._player.currentTime(time);
      this._player.removeClass('autoplay');
    };

    // Wait for mediaStart before seeking to the new start point to make sure that we have all data
    // that we need.
    if (this._options.startTime) {
      this._player.one('mediaStart', () => seekTo(this._options.startTime));
    } else {
      this._player.one('mediaStart', () => {
        if (
          this._player.isLive() &&
          this._player.seekable() &&
          this._player.seekable().length
        ) seekTo(this._player.seekable().end(0));
      });
    }
  }

  // [VIP-21176] [PLP-6389] Force the player to go fullscreen when playing the video and to pause if the user leaves
  // fullscreen for mobile devices. Enable by setting mobileFullscreenPlaying to true in the player config.
  _forceMobileFullscreenPlaying() {
    const goFullscreen = () => {
      if (!this._player.isFullscreen()) this._player.requestFullscreen();
    };

    if (browserUtils.env.iOS || browserUtils.env.android) {
      this._player.on('fullscreenchange', () => {
        if (!this._player.isFullscreen()) this._player.pause();
      });
    }

    if (browserUtils.env.iOS) {
      this._player.on('play', goFullscreen);
    } else if (browserUtils.env.android) {
      this._player.getChild('bigPlayButton').on('touchend', goFullscreen);
      this._player.getChild('controlBar').playToggle.on('touchend', goFullscreen);

      const oldHandleTechTouchEnd = this._player.handleTechTouchEnd_;
      this._player.handleTechTouchEnd_ = (...args) => {
        goFullscreen();
        oldHandleTechTouchEnd.apply(this._player, args);
      };
    }
  }

  /**
   * Replaces the normal vjs fullscreen button with a custom one that allows reloading
   * the player with new configuration without leaving fullscreen.
   */
  _setupDivFullScreenBehavior() {
    vjs.registerComponent('CustomFullScreenToggle', CustomFullScreenToggle);
    this._player.el_[fullScreenApi.requestFullscreen] = () => {
      this._log('Preventing vjs player div from going fullscreen');
    };
    this._player.ready(() => {
      // Need to wait to get access to ControlBar
      const controlBar = this._player.getChild('controlBar');
      if (!controlBar.getChild('CustomFullScreenToggle')) {
        controlBar.removeChild('fullscreenToggle');
        controlBar.addChild('CustomFullScreenToggle');

        if (this._options.fullscreen) {
          // In case we want to start the player is initialized in fullscreen
          controlBar.getChild('CustomFullScreenToggle')._enterFullScreen();
          this._player.trigger('fullscreenchange');
        }
      }
    });
  }

  _setupControlbarBehaviourForChromecast() {
    this._player.options_.inactivityTimeout = 4000;

    this.on('mediaPlay', () => this._player.userActive(true));
    this.on('adPlay', () => this._player.userActive(false));
  }

  _checkForSuggestionsObjectExistance() {
    return (this._options.plugins &&
      this._options.plugins.suggestions &&
      this._options.plugins.suggestions[0] &&
      this._options.plugins.suggestions[0].style === 'multipleformattheme') ||
      (this._options.plugins &&
      this._options.plugins.suggestions &&
      this._options.plugins.suggestions[1] &&
      this._options.plugins.suggestions[1].style === 'multipleformattheme');
  }

  _editPosterBehaviour() {
    this._player.one('controlStart', () => {
      this._player.poster(
        'data:image/gif;base64,R0lGODdhEAAJAJEAAAAAAP///wAAAAAAACH5BAkAAAIALAAAAAAQAAkAAAIKhI+py+0Po5yUFQA7'
      );
    });
    if (!this._checkForSuggestionsObjectExistance()) {
      this._player.one('controlEnded', () => this._player.poster(this._options.poster));
    }
  }

  _setControlStartEndClasses(replay) {
    this._player.one(replay ? 'play' : 'controlStart', () => {
      this._player.addClass('x-started');
      this._player.removeClass('x-ended');
    });
    this._player.one('controlEnded', () => {
      this._player.removeClass('x-started');
      this._player.addClass('x-ended');
      this._setControlStartEndClasses(true);
    });
  }

  _initVideoControlsBehavior() {
    this._player.poster(this._options.poster);
    this._playerElement.removeAttribute('poster'); // Remove the poster from the video tag, we will only use vjs-poster
    this._editPosterBehaviour();

    if (!this._vjsOptions.plugins.freewheel) {
      this._player.controls(true);
      return;
    }

    // Hiding the player controls until the ad request is complete
    this._playerElement.removeAttribute('controls');
    this._playerElement.removeAttribute('crossOrigin');

    this._player.on('adRequestConfigured', () => {
      setTimeout(() => {
        this._player.controls(true);
      }, 200);
    });
    this._player.on('adRequestFailed', () => { this._player.controls(true); });
  }

  _setupAutoplayHandlers() {
    this._player.on('adPrerollStart', () => this._player.removeClass('autoplay'));
    this._player.on('mediaStart', () => this._player.removeClass('autoplay'));
    this._player.on('mediaError', () => this._player.removeClass('autoplay'));
  }

  _setupSubtitles() {
    this._log('_setupSubtitles', this._options.textTracks);
    if (!this._options.textTrack && !this._options.textTracks) {
      this._player.addClass('no-subs');
      return;
    }
    this._options.textTracks = this._options.textTracks || [];
    const textTracks = this._options.textTracks;

    if (this._options.textTrack) {
      let textTrack;
      if (typeof this._options.textTrack === 'string') textTrack = { src: this._options.textTrack };
      else textTrack = this._options.textTrack;

      if (textTrack.kind === undefined) textTrack.kind = 'subtitles';
      if (textTrack.default === undefined) textTrack.default = true;
      if (textTrack.mode === undefined) textTrack.mode = 'showing';

      textTracks.push(textTrack);
    }

    textTracks.forEach((textTrack) => {
      // On Chrome, if the subtitles are loaded from another domain, then we should add the
      // crossorigin attribute to the video element
      if (browserUtils.env.chrome &&
        textTrack.src.indexOf(window.location.host) === -1) {
        this._log('Adding crossOrigin anonymous for Chrome');
        this._player.tech_.el().setAttribute('crossorigin', 'anonymous');
      }

      if (!textTrack.label) {
        switch (textTrack.kind.toLowerCase()) {
          case 'subtitles':
            textTrack.label = this._player.localize('Default language');
            break;

          case 'captions':
            textTrack.label = this._player.localize('Default language for hearing impaired');
            break;

          default:
            textTrack.label = 'null';
            break;
        }
      }

      // When using HLS streams that have a CLOSED-CAPTIONS=NONE flag in the manifest and
      // adding a VTT file, the player crashed on iOS and Safari on Mac. We therefore not
      // include any VTT file and instead use the Akamai Streaming subtitle feature (included)
      // inside the manifest file)
      if (!browserUtils.env.safari) {
        this._player.addRemoteTextTrack({
          kind: textTrack.kind,
          src: textTrack.src,
          label: textTrack.label,
          language: textTrack.language,
          default: textTrack.default,
          mode: textTrack.default ? 'showing' : 'disabled',
        }, true);
      }
    });

    if (browserUtils.env.safari) {
      this._fixSubtitlesOnSafari();
    }
  }

  _initConnectivityDetection() {
    // Trigger a player event for plugins to listen to when we've detected an ad blocker
    this._player.connectivity.on(this._player.connectivity.AD_BLOCKER, () => this._player.trigger('adBlock'));

    // Check for connectivity when starting playing media, after ads and on errors
    this._player.on(['mediaStart', 'mediaPlay', 'adRollEnd', 'adEnd', 'adError'], () => {
      this._player.connectivity.check();
    });
    this._player.on('error', () => {
      if (this._isPlayingAd()) this._player.connectivity.check();
    });
  }

  getVideojsPlayerInstance() {
    return this._player;
  }

  _getVJSPlayerOptions(vjsOptions) {
    this._log('getVJSPlayerOptions');

    // Most mobile devices do not support autoplay
    if (this._isMobileDevice()) {
      if (this._useMobileAutoplay()) vjsOptions.autoplay = true;
      else vjsOptions.autoplay = false;
    }

    vjsOptions.preload = 'auto';

    // [PLP-7959] Disable autoplay when we initialize the player in a background tab/window. The browser will pause
    // all videos in a background tab and this will make all prerolls be skipped due to timeouts.
    if (!window.cast && !window.document.hasFocus() && !this._options.forceAutoplay) vjsOptions.autoplay = false;

    this._updateVjsOptionsForTheme(vjsOptions);

    // [PLP-8317] If the browser is unsupported, return a minimal set of options to prevent tracking, ads and other
    // plugins from starting before we display the error message.
    if (!browserUtils.isBrowserSupported()) {
      vjsOptions.plugins = {
        mtgxSkin: {
          name: this._options.theme.name,
        },
        googleTagManager: vjsOptions.plugins.googleTagManager,
      };
      delete this._options.sources;
      delete vjsOptions.sources;

      return vjs.mergeOptions(defaultOptions, vjsOptions);
    }

    if (this._options.startTime && this._options.plugins.ageGating) {
      delete this._options.plugins.ageGating;
    }

    if (this._options.startTime && !this._options.forcePreroll && this._options.plugins.freewheel) {
      this._options.plugins.freewheel.showPrerolls = false;
    }

    if (this._options.timeBox) this._updateVjsOptionsForTimeBoxStartTime(vjsOptions);

    if (this._options.sourceLoader) {
      vjsOptions.plugins.sourceLoader = this._options.sourceLoader;

      // Remove any other sources if we have a source loader otherwise the player will get confused and crash
      delete this._options.sources;
      delete vjsOptions.sources;
    }

    this._removeVolumeControlForMobileAndTablet(vjsOptions);

    // The castTech has problems switching between live and ad videos so we use contrib-hls for live instead.
    if (this._options.isLive && browserUtils.env.chromecast) vjsOptions.techOrder = this._options.techOrder = ['html5'];

    return vjs.mergeOptions(defaultOptions, vjsOptions);
  }

  _updateVjsOptionsForTimeBoxStartTime(vjsOptions) {
    const timeBoxOptions = this._options.timeBox;

    timeBoxOptions.getCurrentTime = timeBoxOptions.getCurrentTime || Date.now;

    timeBoxOptions.getMessage = () => (
      timeBoxOptions.message || this._player.localize('This video is no longer available')
    );

    this._log(`Time boxed stream; starting in:${(timeBoxOptions.startTimestamp - timeBoxOptions.getCurrentTime())}ms`);

    const _isAfterNow = timestamp => (timeBoxOptions.getCurrentTime() < timestamp);

    if (
      _isAfterNow(timeBoxOptions.startTimestamp) &&
      (
        !timeBoxOptions.endTimestamp ||
        _isAfterNow(timeBoxOptions.endTimestamp)
      )
    ) {
      // Remove the sources and let the source loader add them when the live starts
      if (this._options.sources && !this._options.sourceLoader) {
        const sources = this._options.sources;
        delete this._options.sources;

        this._options.sourceLoader = {
          synchronousCallback: (done) => {
            if (!timeBoxOptions.endTimestamp || _isAfterNow(timeBoxOptions.endTimestamp)) {
              const orderedSources = this.configureSources(sources);
              done(orderedSources[0]);
            } else {
              this._player.pause();
              this._player.trigger('ended');
              this._player.trigger('destroyPlayer');
              this._player.trigger('showOverlayMessage', {
                message: timeBoxOptions.getMessage(),
                blur: true,
              });
            }
          },
        };
      }

      vjsOptions.plugins.countdown = {
        until: timeBoxOptions.startTimestamp,
        getCurrentTime: timeBoxOptions.getCurrentTime,
      };
    }
  }

  _updateVjsOptionsForTheme(vjsOptions) {
    const themeConfig = this._options.theme || {};
    const themes = {
      default: 'video-js',
      viafree: 'viafree-player',
      viasport: 'viafree-player',
    };

    let className = themes.default;
    if (themeConfig.name && Object.keys(themes).indexOf(themeConfig.name) !== -1) {
      className = themes[themeConfig.name];
    }

    // Adding the Theme class to the player
    this._playerElement.classList.add(className);
    Object.keys(themes).forEach((themeName) => {
      if (className !== themes[themeName]) this._playerElement.classList.remove(themes[themeName]);
    });

    if (this._useMobileAutoplay()) {
      themeConfig.showMobileVolumeButton = true;
    }

    vjsOptions.plugins.mtgxSkin = themeConfig;
  }

  _translateTimeBoxEndOptions() {
    const timeBoxOptions = this._options.timeBox;
    this._log(`Time boxed stream; ending in :${(timeBoxOptions.endTimestamp - timeBoxOptions.getCurrentTime())}ms`);

    const checkForEnd = () => {
      if (timeBoxOptions.getCurrentTime() > timeBoxOptions.endTimestamp) {
        this._player.pause();
        this._player.trigger('ended');
        this._player.trigger('destroyPlayer');
        this._player.trigger('showOverlayMessage', {
          message: timeBoxOptions.getMessage(),
          blur: true,
        });
        return true;
      }
      return false;
    };

    this._player.ready(() => {
      const checkLoop = () => {
        if (!checkForEnd()) {
          // Wait 80% of the time left every iteration (or at least 100ms) to be able to compensate for drifting times.
          const timeout = Math.max(100, (timeBoxOptions.endTimestamp - timeBoxOptions.getCurrentTime()) * 0.8);
          this._player.setTimeout(checkLoop, timeout);
        }
      };
      checkLoop();
    });
  }

  _removeVolumeControlForMobileAndTablet(options) {
    // [VIP-19090] Remove volume control for mobile devices
    if (
      browserUtils.env.mobile ||
      browserUtils.env.tablet
    ) {
      options.controlBar.volumeMenuButton = false;
    }
  }

  _fixBrowserBugs() {
    // Fix for Android ~4.4.2 where the class 'vjs-seeking' is left after seeking (no mediaSeekComplete event)
    if (
      browserUtils.env.android &&
      browserUtils.env.osVersion.major === 4 &&
      browserUtils.env.osVersion.minor === 4 &&
      browserUtils.env.browserVersion.patch <= 2
    ) {
      this._player.on(['mediaStart', 'mediaPlay'], () => {
        this._player.removeClass('vjs-seeking');
      });
    }

    // Fix for IE11 + W8.1 bug where 'playing' is not fired after 'seeked'
    // (https://connect.microsoft.com/IE/feedbackdetail/view/2237449/playing-event-is-not-fired-when-seeking-i-e-changing-currenttime-programatically-in-a-video-element)
    // and the 'vjs-waiting' class is left when playing starts, this is sometimes an issue for Safari as well.
    this._player.on('mediaSeekComplete', () => {
      this._player.removeClass('vjs-waiting');
    });
  }

  _fixSubtitlesOnSafari() {
    // VIP-14602: Set the text track mode on 'mediaStart' to enable default subtitle for Safari
    let intervalId;

    const restoreSubtitleState = () => {
      const currentSubtitleType = this._getAvailableTextTracks()
        .filter(textTrack => textTrack.default || textTrack.mode === 'showing')
        .map(textTrack => textTrack.kind)
        .pop() || 'subtitles';

      // Check to see if the text tracks are available yet
      this._player.textTracks().removeEventListener('change', restoreSubtitleState);
      this._player.off('texttrackchange', restoreSubtitleState);
      if (!this._hasAvailableTextTracks()) {
        this._player.textTracks().addEventListener('change', restoreSubtitleState);
        this._player.one('texttrackchange', restoreSubtitleState);
        return;
      }

      let counter = 0;
      const restoreSubtitles = () => {
        this._changeSubtitleByType(currentSubtitleType);

        // For some reason we can't set the subtitles too early, so we'll try a couple of times to make it stick
        counter += 1;
        if (counter >= 4) {
          this._player.clearInterval(intervalId);
        }
      };

      this._player.clearInterval(intervalId);
      intervalId = this._player.setInterval(restoreSubtitles, 100);
    };

    this._player.on('mediaStart', restoreSubtitleState);
  }

  _stripSubtitlesFromSourceForAndroid4(sourceObjects) {
    if (
      browserUtils.env.android &&
      browserUtils.env.osVersion.major === 4
    ) {
      return sourceObjects.map((sourceObject) => {
        sourceObjects.src = sourceObject.src.replace(/[?&]cc.?=.*/, '');
        return sourceObject;
      });
    }
    return sourceObjects;
  }

  configureSources(sources) {
    this._log(
      '\n- Identified OS', browserUtils.env.uaParser.os,
      '\n- Identified Browser', browserUtils.env.uaParser.browser,
      '\n- Identified Environment', browserUtils.env
    );

    let sourceList = [{ src: '' }];

    if (Array.isArray(sources)) {
      sourceList = sources;
    } else if (typeof sources === 'string') {
      sourceList = [{ src: sources }];
    } else if (sources instanceof Object) {
      sourceList = [sources];
    }

    const sourceTypeOrder = [mimetypes.HLS, mimetypes.MP4];

    // Make sure that all sources are converted into source objects
    // (e.g. { src: 'http...', type: 'application/dash+xml' })
    let sourceObjects = sourceList.map(sourceOrder.getSourceObjectFromUrl);

    const idealBitrate = bitrateUtil.getIdealBitrate();

    sourceObjects = sourceOrder.chooseAppropriateMp4Stream(sourceObjects, idealBitrate);

    if (this._options.overrideAkamaiStartingBitrate) {
      const akamaiStreamingTools = new AkamaiStreamingTools();
      sourceObjects = akamaiStreamingTools.modifyAkamaiHlsStream(sourceObjects, idealBitrate);
    }

    sourceObjects = this._stripSubtitlesFromSourceForAndroid4(sourceObjects);

    // Sort the source objects by their mime type in accordance to the sourceTypeOrder
    const sortedSourceObjects = sourceOrder.getSortedSourceObjects(sourceObjects, sourceTypeOrder);

    this._log('Load sources', sortedSourceObjects);
    return sortedSourceObjects;
  }

  /**
   * Show current version when right clicking on the video
   * [VIP-13749] Disable right click on all elements.
   */
  _updateContextMenu() {
    const contextMenu = document.createElement('div');
    contextMenu.innerHTML = meta.playerVersion;
    contextMenu.className = 'x-context-menu';

    this._player.el().appendChild(contextMenu);

    const childElements = this._player.el().querySelectorAll('*');
    Array.prototype.slice.call(childElements, 0).forEach((element) => {
      element.oncontextmenu = () => false;
    });

    this._player.el().oncontextmenu = (event) => {
      contextMenu.style.display = 'inline-block';
      contextMenu.style.left = `${event.offsetX + 15}px`;
      contextMenu.style.top = `${event.offsetY - 5}px`;
      setTimeout(() => {
        contextMenu.style.display = 'none';
      }, 1000);
      event.stopPropagation();
      event.preventDefault();
    };
  }
  // Player util methods

  _pollForMediaChanges() {
    // Listen to preMediaStart instead of mediaStart to make sure that we set the media duration before mediaStart
    this._player.one('preMediaStart', () => { this._duration = this._player.duration(); });

    this._mediaCurrentTime = 0;
    this._player.on('timeupdate', () => {
      if (
        this._player.currentTime() &&
        !this._player.seeking() &&
        !this._player.scrubbing() &&
        !this._isPlayingAd()
      ) {
        this._mediaCurrentTime = this._player.currentTime();
      }
    });
    // If we seek past a midroll we'll save the time before seeking
    this._player.on('mediaSeekComplete', (e) => { this._mediaCurrentTime = e.beforeSeekTime; });
  }


  getMediaDuration() {
    return this._duration;
  }

  getMediaCurrentTime() {
    return this._mediaCurrentTime;
  }

  _isPlayingAd() {
    return this._player.freewheel && this._player.freewheel.isPlayingAd && this._player.freewheel.isPlayingAd();
  }

  _isLive() {
    if (this.getMediaDuration() >= 0) return this.getMediaDuration() === Infinity;
    return undefined;
  }

  _useMobileAutoplay() {
    return this._supportsMobileAutoplay() && this._options.mobileAutoplay === true;
  }

  _supportsMobileAutoplay() {
    return (browserUtils.env.iOS && browserUtils.env.osVersion.major >= 10)
      || (browserUtils.env.android &&
        browserUtils.env.chrome &&
        browserUtils.env.browserVersion.major >= 53);
  }

  _isMobileDevice() {
    return browserUtils.env.mobile;
  }

  _shouldHaveSubtitles() {
    return this._options.textTracks && this._options.textTracks.length > 0;
  }

  _reload(reloadCallback) {
    try {
      try {  // If we have played the media, try to rewind 5 seconds
        if (this.getMediaCurrentTime()) this._originalOptions.startTime = Math.max(0, this.getMediaCurrentTime() - 5);
      } catch (e) { /* Use old startTime */ }

      const reload = () => {
        const newPlayer = new AVODPlayer(this._originalElementId, this._originalOptions);
        if (reloadCallback && typeof reloadCallback === 'function') {
          reloadCallback(newPlayer);
        }
        if (this._options.reloadCallback && typeof this._options.reloadCallback === 'function') {
          this._options.reloadCallback(newPlayer);
        }
      };

      setTimeout(() => {
        // Re check for adblockers to see if they have been disabled
        this.connectivity.check()
          .then(() => reload())
          .catch(() => reload());
      }, 0);
    } catch (e) { /* Do nothing. Player recovery crashed. */ }
  }

  _getAvailableTextTracks() {
    if (!this._player.textTracks || typeof this._player.textTracks !== 'function') return [];

    const textTracks = [...this._player.textTracks()]
      .filter(textTrack => textTrack.kind === 'subtitles' || textTrack.kind === 'captions');

    if (browserUtils.env.safari) return textTracks;

    // Filter out other metadata tracks that isn't subtitles or captions.
    // For non-apple devices we get both the m3u8 and the vtt subtitles, so we filter out the m3u8 since they
    // don't have all the metadata (language).
    const filteredTextTracks = textTracks
      .filter(textTrack => textTrack.kind !== 'subtitles' || textTrack.kind !== 'captions')
      .filter(textTrack => textTrack.cues && textTrack.cues.length > 0);

    return filteredTextTracks
      .filter(textTrack => filteredTextTracks.length === 1 || !textTrack.language);
  }

  _hasAvailableTextTracks() {
    return this._getAvailableTextTracks().length !== 0;
  }

  _fixSubtitleTypes = () => {
    const textTracks = [...this._player.textTracks()]
      .filter(textTrack => textTrack.kind === 'subtitles' || textTrack.kind === 'captions');

    // When we get the subtitles from the URL/Akamai we can't get the 'kind' so we'll assume that they are in order.
    if (
      textTracks.length === 2 &&
      textTracks[0].kind === textTracks[1].kind &&
      browserUtils.env.mac &&
      browserUtils.env.safari
    ) {
      // Text tracks can't be added dynamically on Safari on MacOS so we have to change the 'kind' directly.
      textTracks[0].kind = 'subtitles';
      textTracks[1].kind = 'captions';
    }
  }

  _changeSubtitle(newIndex) {
    if (!this._hasAvailableTextTracks()) return;
    const textTracks = this._getAvailableTextTracks();
    this._fixSubtitleTypes();

    textTracks.forEach((textTrack, index) => {
      // First set to null to trigger a change, otherwise setting to 'showing' will do
      // nothing if the mode is already 'showing' with the actual subtitles not so showing.
      textTrack.mode = null;
      textTrack.mode = newIndex === index ? 'showing' : 'disabled';
    });
  }

  _getCurrentSubtitle() {
    const textTracks = this._getAvailableTextTracks();
    let currentTextTrack = null;
    textTracks.forEach((textTrack) => {
      if (textTrack.mode === 'showing') currentTextTrack = textTrack;
    });
    return currentTextTrack;
  }

  _changeSubtitleByType(subType) {
    const textTracks = this._getAvailableTextTracks();
    if (!textTracks.length) return;
    this._fixSubtitleTypes();

    let foundTextTrackToShow = false;

    textTracks.forEach((textTrack) => {
      // First set to null to trigger a change, otherwise setting to 'showing' will do
      // nothing if the mode is already 'showing' with the actual subtitles not so showing.
      // If more than one text track matches the chosen one, select the first one to prevent multiple
      // from being shown as selected.
      textTrack.mode = null;
      const isTextTrackShown = textTrack.kind === subType.toLowerCase();
      textTrack.mode = (isTextTrackShown && !foundTextTrackToShow) ? 'showing' : 'disabled';
      if (isTextTrackShown) foundTextTrackToShow = true;
    });
  }

  _changeSubtitleByLabel(label) {
    const textTracks = this._getAvailableTextTracks();
    if (!textTracks.length) return;
    this._fixSubtitleTypes();

    let foundTextTrackToShow = false;

    textTracks.forEach((textTrack) => {
      // First set to null to trigger a change, otherwise setting to 'showing' will do
      // nothing if the mode is already 'showing' with the actual subtitles not so showing.
      // If more than one text track matches the chosen one, select the first one to prevent multiple
      // from being shown as selected.
      textTrack.mode = null;
      const isTextTrackShown = textTrack.label === label;
      textTrack.mode = (isTextTrackShown && !foundTextTrackToShow) ? 'showing' : 'disabled';
      if (isTextTrackShown) foundTextTrackToShow = true;
    });
  }

  dispose() {
    if (!this._player) return;
    try {
      this._player.dispose();
    } catch (e) { /**/ }
    this._player.el_ = this._player.el_ || { className: '' };
    this._player.tech_.el_ = this._player.tech_.el_ || {};
  }

  // ---------

  _log(...args) {
    debugLog(`[#${this._id}]`, ...args);
  }
}

function avodPlayer(elementId, options) {
  return new AVODPlayer(elementId, options);
}

avodPlayer.isBrowserSupported = browserUtils.isBrowserSupported;
avodPlayer.requiresProgressiveDownload = () => !browserUtils.isBrowserSupported();

module.exports = avodPlayer;
