import debug from 'debug';
import vjs from 'video.js';
import browserUtils from '../../../src/util/browser';

import SharingScreen from './sharing';
import SharingButton from './sharing-button';
import MobileVolumeButton from './mobile-volume-button';
import SubtitleButton from './subtitle-button';
import SubtitleMenu from './subtitle-menu';
import SubtitleMenuButton from './subtitle-menu-button';
import ChromecastButton from './cast-button';

// Setup debugging
const debugLog = debug('MtgxSkinPlugin');

class MtgxSkinPlugin {
  constructor(player, options) {
    this._player = player;
    this._options = options;
    this._addPlatformClasses();

    if (!browserUtils.env.chromecast) this._setupInteractiveDisplay();
    if (this._options.formatTitle) this._addVideoTitleComponents();
    if (this._options.themeColor) this._setThemeColor();
    this._player.ready(() => { this._setSizeClasses(); });
  }

  _setupInteractiveDisplay() {
    this._addPlayButtonOverlay();
    if (this._options.logo) this._addLogoComponents();
    if (this._options.name === 'viafree' || this._options.name === 'viasport') {
      this._setupSubtitleMenu();
    } else {
      this._addSubtitleButton();
    }

    if (this._options.enableChromecast) this._addChromecastButton();

    this._player.on('mouseleave', this._hideControls.bind(this));

    if (this._options.name === 'viafree' || this._options.name === 'viasport') this._addControlBarOverlay();

    this._player.on('controlEnded', this._showPoster.bind(this));

    this._player.ready(() => {
      const setSizeClasses = this._setSizeClasses.bind(this);
      window.addEventListener('resize', setSizeClasses);
      this._player.on('dispose', () => window.removeEventListener('resize', setSizeClasses));
    });

    if (this._options.showMobileVolumeButton) {
      vjs.registerComponent('MobileVolumeButton', MobileVolumeButton);
      this._player.ready(() => {
        // Need to wait to get access to ControlBar
        this._player.getChild('controlBar').addChild('MobileVolumeButton');
      });
    }

    if (this._options.sharing) {
      vjs.registerComponent('SharingButton', SharingButton);
      this._player.ready(() => {
        // Need to wait to get access to ControlBar
        this._player.getChild('controlBar').addChild('SharingButton');
        this._sharingScreen = new SharingScreen(this._player, this._options);
      });
    }
  }

  _hideControls() {
    if (this._player.isFullscreen()) {
      return;
    }

    this._player.userActive(false);
  }

  _setSizeClasses() {
    this._player.removeClass('x-large');
    this._player.removeClass('x-medium');
    this._player.removeClass('x-small');

    if (this._player.el().offsetWidth > 900) {
      this._player.addClass('x-large');
    } else if (this._player.el().offsetWidth > 580) {
      this._player.addClass('x-medium');
    } else {
      this._player.addClass('x-small');
    }
  }

  _addSubtitleButton(options) {
    vjs.registerComponent('SubtitleButton', SubtitleButton);
    this._player.on('mediaStart', () => {
      const tracks = this._player.getAvailableTextTracks();
      if (!tracks.length) return;

      // Need to wait to get access to ControlBar
      if (!this._player.getChild('controlBar').getChild('SubtitleButton')) {
        this._player.getChild('controlBar').addChild('SubtitleButton', options);
      }
    });
  }

  _setupSubtitleMenu() {
    vjs.registerComponent('SubtitleMenu', SubtitleMenu);
    vjs.registerComponent('SubtitleMenuButton', SubtitleMenuButton);
    this._addSubtitleMenuButton();
    new SubtitleMenu(this._player, this._options);
  }

  _addSubtitleMenuButton(options) {
    this._player.on('mediaStart', () => {
      const addButton = () => {
        if (!this._player.hasAvailableTextTracks()) return;

        if (!this._player.getChild('controlBar').getChild('SubtitleMenuButton')) {
          this._player.getChild('controlBar').addChild('SubtitleMenuButton', options);
        }
      };

      if (!this._player.hasAvailableTextTracks()) {
        this._player.one('texttrackchange', () => addButton());
        this._player.textTracks().addEventListener('change', () => addButton());
      } else {
        addButton();
      }
    });
  }

  _addChromecastButton() {
    vjs.registerComponent('ChromecastButton', ChromecastButton);
    this._player.on('ready', () => {
      // Need to wait to get access to ControlBar
      if (!this._player.getChild('controlBar').getChild('ChromecastButton')) {
        this._player.getChild('controlBar').addChild('ChromecastButton');
      }
    });
  }

  _addVideoTitleComponents() {
    const Component = vjs.getComponent('Component');
    const BigVideoTitle = vjs.extend(Component, {
      createEl: function createEl() {
        return Component.prototype.createEl.call(this, 'div', {
          className: 'x-big-title',
        });
      },
    });
    vjs.registerComponent('BigVideoTitle', BigVideoTitle);

    const SmallVideoTitle = vjs.extend(Component, {
      createEl: function createEl() {
        return Component.prototype.createEl.call(this, 'div', {
          className: 'x-small-title',
        });
      },
    });
    vjs.registerComponent('SmallVideoTitle', SmallVideoTitle);

    this._player.addChild('SmallVideoTitle');
    this._player.addChild('BigVideoTitle');
    this._player.getChild('BigVideoTitle').el().innerHTML = this._options.formatTitle;
    this._player.getChild('SmallVideoTitle').el().innerHTML = this._options.formatTitle;

    if (this._options.videoTitle) {
      const videoTitle = document.createElement('span');
      videoTitle.innerHTML = this._options.videoTitle;
      this._player.getChild('BigVideoTitle').el().appendChild(videoTitle);
    }

    if (this._options.durationTitle) {
      const durationTitle = document.createElement('span');
      durationTitle.className = 'duration';
      durationTitle.innerHTML = this._options.durationTitle;
      this._player.getChild('BigVideoTitle').el().appendChild(durationTitle);
    }
  }

  _addLogoComponents() {
    const Component = vjs.getComponent('Component');
    const LogoBeforeStart = vjs.extend(Component, {
      createEl: function createEl() {
        return Component.prototype.createEl.call(this, 'div', {
          className: 'x-logo-before-start',
        });
      },
    });

    const LogoAfterStart = vjs.extend(Component, {
      createEl: function createEl() {
        return Component.prototype.createEl.call(this, 'div', {
          className: 'x-logo-after-start',
        });
      },
    });

    vjs.registerComponent('LogoBeforeStart', LogoBeforeStart);
    this._player.addChild('LogoBeforeStart');
    vjs.registerComponent('LogoAfterStart', LogoAfterStart);
    this._player.addChild('LogoAfterStart');

    const colorLogo = document.createElement('img');
    const bwLogo = document.createElement('img');
    colorLogo.setAttribute('src', this._options.logo.color);
    bwLogo.setAttribute('src', this._options.logo.bw);
    this._player.getChild('LogoBeforeStart').el().appendChild(colorLogo);
    this._player.getChild('LogoAfterStart').el().appendChild(bwLogo);
  }

  _addPlayButtonOverlay() {
    const Component = vjs.getComponent('Component');
    const PlayButtonOverlay = vjs.extend(Component, {
      createEl: function createEl() {
        return Component.prototype.createEl.call(this, 'div', {
          className: 'x-play-button-overlay',
        });
      },
    });
    vjs.registerComponent('PlayButtonOverlay', PlayButtonOverlay);
    this._player.addChild('PlayButtonOverlay');
  }

  _addControlBarOverlay() {
    const Component = vjs.getComponent('Component');
    const PlayButtonOverlay = vjs.extend(Component, {
      createEl: function createEl() {
        return Component.prototype.createEl.call(this, 'div', {
          className: 'x-control-bar-overlay',
        });
      },
    });
    vjs.registerComponent('ControlBarOverlay', PlayButtonOverlay);
    this._player.addChild('ControlBarOverlay');
  }

  _showPoster() {
    this._player.posterImage.show();
  }

  _setThemeColor() {
    const themeColor = this._options.themeColor;
    const sheet = document.createElement('style');
    sheet.innerHTML = '.vjs-play-progress, .vjs-seek-handle.vjs-slider-handle::before';
    sheet.innerHTML += `{background-color: ${themeColor} !important;}`;

    sheet.innerHTML += '.viafree-player .vjs-control:active,';
    sheet.innerHTML += '.viafree-player .subtitle-menu .vjs-overlay-message-label .x-subtitle-menu-overlay li:active';
    sheet.innerHTML += `{color: ${themeColor} !important;}`;
    document.body.appendChild(sheet);
  }

  _addPlatformClasses() {
    // Add platform name to the body object
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    if (userAgent.match(/iPad/i) || userAgent.match(/iPhone/i) || userAgent.match(/iPod/i)) {
      this._player.addClass('mobile-ios');
    } else if (userAgent.match(/Android/i)) {
      this._player.addClass('mobile-android');
    } else if (browserUtils.env.chromecast) {
      this._player.addClass('chromecast-receiver');
    }
  }

  _log(...args) {
    debugLog(`[#, ${this._player.id()}]`, ...args);
  }
}
vjs.plugin('mtgxSkin', function init(options) {
  this.mtgxSkin = new MtgxSkinPlugin(this, options);
});
