import document from 'global/document';
import debug from 'debug';

const errorLog = debug('fw:display-ad-error');

class DisplayAdHandler {

  constructor() {
    this.writeDisplay = this.writeDisplay.bind(this);
  }

  /**
   * Global method to create elements for freewheel display ads.
   *
   * If the content is a full HTML page or if we get an URL to the HTML we will render the ad in an iframe to make the
   * scripts work.
   * If we get partial HTML we will try to add the elements directly to our DOM to be able to resize it correctly.
   */
  writeDisplay(content, url, renditionHeight, renditionWidth, assetMimeType, slotId) {
    const adSlotElement = document.getElementById(`_fw_container_${slotId}`);

    // The Freewheel SDK expects an element with the ad to be returned but the element will be repositioned and resized
    // so we provide a dummy element for it to play with.
    const adDummyElement = document.createElement('div');
    adDummyElement.id = `_fw_ad_container_html_${slotId}_`;
    adDummyElement.style.visibility = 'hidden';
    adDummyElement.style.display = 'none';

    // [VIP-28859] We sometimes get the ad as an URL, display it in an iframe
    if (adSlotElement && url) {
      const adIframeElement = document.createElement('iframe');
      adIframeElement.style.border = '0px none transparent';
      adIframeElement.style.width = `${renditionWidth}px`;
      adIframeElement.style.height = `${renditionHeight}px`;
      adIframeElement.style.overflow = 'hidden';
      adIframeElement.setAttribute('scrolling', 'no');
      adSlotElement.appendChild(adIframeElement);
      adIframeElement.src = url;

      return adSlotElement;
    }

    // We should never have both content and URL empty, but if we do, just return with a dummy element
    // to prevent crashes.
    if (!content) {
      if (adSlotElement) adSlotElement.appendChild(adDummyElement);
      adDummyElement.className = 'no_content';
      return adDummyElement;
    }

    const parser = new DOMParser();
    const adDocument = parser.parseFromString(content, assetMimeType);
    let adRenditionElement = adDocument.querySelectorAll('body > span, body > div')[0];
    // Adsambler put their script in the outerHTML element (freewheel wrapper)
    // so we make sure we grab those if present
    const outerHTMLScripts = adDocument.querySelectorAll('body > script');

    if (adSlotElement) {
      if ((/^<html/.test(content))) { // Check if we need an iframe
        // Some ads needs to be rendered in an iframe to be able to do document.write
        const adIframeElement = document.createElement('iframe');
        adIframeElement.style.border = '0px none transparent';
        adIframeElement.style.width = `${renditionWidth}px`;
        adIframeElement.style.height = `${renditionHeight}px`;
        adIframeElement.style.overflow = 'hidden';
        adIframeElement.setAttribute('scrolling', 'no');
        adSlotElement.appendChild(adIframeElement);
        adIframeElement.contentWindow.document.write(content);

        return adSlotElement;
      }

      // If we don't need an iframe add the element to the specified slot element.
      adSlotElement.appendChild(adRenditionElement);
      // VIP-24539 A little hack to make display ads that have script tags run those tags
      // Mostly an issue for DK on Viasport
      adRenditionElement = this.loadScriptsFromDom(adRenditionElement, outerHTMLScripts);

      return adSlotElement;
    }

    // If we don't have a placeholder element already in the DOM for the slot, treat the ad as an overlay.

    adRenditionElement.querySelectorAll('img')[0].removeAttribute('width');
    adRenditionElement.querySelectorAll('img')[0].removeAttribute('height');
    adRenditionElement.querySelectorAll('img')[0].style.width = '100%';
    adRenditionElement.style.margin = '';

    const adOverlayElement = document.querySelectorAll('.ad-overlay')[0];
    // 520px is the "normal" size of the overlay, 730px is the "normal" size of the player
    // adOverlayElement.style.maxWidth = `${520 / 730 * 100}%`;
    adOverlayElement.style.height = `${renditionHeight}px`;
    adOverlayElement.style.maxWidth = `${renditionWidth}px`;

    const adOverlayContentElement = adOverlayElement.querySelectorAll('.ad-overlay-content')[0];
    while (adOverlayContentElement.firstChild) adOverlayContentElement.removeChild(adOverlayContentElement.firstChild);
    adOverlayContentElement.appendChild(adRenditionElement);

    adOverlayElement.appendChild(adDummyElement);

    return adDummyElement;
  }

  loadScriptsFromDom(elm, outerHTMLScripts) {
    // VIP-24539 A little hack to make display ads that have script tags run those tags
    // Mostly an issue for DK on Viasport - display-ad-handler.js
    const scripts = elm.getElementsByTagName('script');

    // If any outerHTML scripts were present we need to execute these
    if (outerHTMLScripts && outerHTMLScripts.length) {
      for (let i = 0; i < outerHTMLScripts.length; i += 1) {
        try {
          const n = document.createElement('script');
          n.type = outerHTMLScripts[i].type || 'text/javascript';
          n.innerHTML = outerHTMLScripts[i].innerHTML;
          document.head.appendChild(n);
        } catch (e) {
          errorLog('Failed to append scripts', outerHTMLScripts[i], e);
        }
      }
    }

    // Script element found in freewheels child DOM elements.
    let text = '';
    for (let i = 0; i < scripts.length; i += 1) {
      const scriptTag = scripts[i];
      const node = document.createElement('script');
      if (scriptTag.src && scriptTag.src.length > 0) {
        node.src = scriptTag.src;
      } else {
        text = scriptTag.text;
        const blob = new Blob([text], { type: scriptTag.type || 'text/javascript' });
        node.src = URL.createObjectURL(blob);
      }

      node.type = scriptTag.type || 'text/javascript';
      elm.removeChild(scriptTag);
      document.head.appendChild(node);

      setTimeout(() => {
        document.head.removeChild(node);
      }, 100);
    }

    return elm;
  }
}

export default new DisplayAdHandler();
