import videojs from 'video.js';
import debug from 'debug';

// Setup debugging
const debugLog = debug('AVODPlayer:PlayerTerminator');

export default class PlayerTerminator {

  constructor(player) {
    this._player = player;

    this._player.on('destroyPlayer', this._destroyPlayer.bind(this));
  }

  _destroyPlayer() {
    this._log('_destroyPlayer');
    this._player.ready(() => {
      this._playerCleanup();
    });
    this._player.isDestroyed = true;
  }

  _playerCleanup() {
    this._player.play = () => { };

    this._player.on('firstplay', (event) => {
      event.stopImmediatePropagation();
      this._player.pause();
    });

    this._player.autoplay(false);
    this._player.pause();
    this._player.userActive(false);
    this._player.getChild('controlBar').hide();
    this._player.getChild('bigPlayButton').hide();
    this._player.getChild('loadingSpinner').hide();

    this._player.tech_.dispose();
    // dummy tech to prevent 'Cannot read property 'x' of null' errors
    const noop = () => ({ style: {}, getAttribute: () => {}, className: '' });
    this._player.tech_ = {
      dispose: noop, one: noop, on: noop, off: noop, ready: noop, contentEl: noop, el: noop, hls: { playlists: {} },
    };

    setTimeout(() => { // Wait for call stack to finish
      this._player.trigger('dispose');
    }, 0);
  }

  _log(...args) {
    debugLog(`[#${this._player.id()}]`, ...args);
  }
}

videojs.plugin('playerTerminator', function init() {
  this.playerTerminator = new PlayerTerminator(this);
});
