import vjs from 'video.js';

const VjsButton = vjs.getComponent('ClickableComponent');

const SharingButton = vjs.extend(VjsButton, {
  createEl: function createEl() {
    return VjsButton.prototype.createEl.call(this, 'div', {});
  },
  buildCSSClass: function buildCSSClass() {
    return `${this.className} ${VjsButton.prototype.buildCSSClass.call(this)}`;
  },
  handleClick: function handleClick() {
    this.player().trigger('showSharingScreen');
  },
});

SharingButton.prototype.kind_ = 'sharing';
SharingButton.prototype.controlText_ = 'Share';
SharingButton.prototype.className = 'x-sharing-button';

module.exports = SharingButton;
