import document from 'global/document';
import FreewheelSDKWrapper from './freewheel-sdk-wrapper';

export default class AdManagerHandler {
  static load(adManagerUrl, logLevel, onLoaded, onError) {
    // console.log(FreewheelSDKWrapper.SDK);
    if (FreewheelSDKWrapper.isLoaded()) {
      onLoaded();
    } else {
      AdManagerHandler._loadAdManager(adManagerUrl, (err) => {
        // we couldn't load the AdManager.js file, trigger the same flow as for a failed ad request
        if (err) {
          onError();
          return;
        }

        if (!FreewheelSDKWrapper.isLoaded()) {
          throw new Error('window.tv.freewheel.SDK needs to be loaded');
        }
        FreewheelSDKWrapper.setLogLevel(logLevel);

        onLoaded();
      });
    }
  }

  static getNewAdManagerInstance(networkId, serverUrl) {
    const adManager = new FreewheelSDKWrapper.AdManager();

    adManager.setNetwork(networkId);
    adManager.setServer(serverUrl);

    return adManager;
  }

  static getAdContext(
    adManager,
    options,
    duration,
    currentTime,
    idealBitrate,
    isDesktop,
    logFunc,
  ) {
    const adContext = adManager.newContext();

    // profileId can be specified like {html5: 'string', flash: 'string'} to switch depending on tech
    // alternatively a string can be provided to use the same for both flash and html5
    const profileId = options.profileId;
    const profileIdHtml5 = profileId.html5 || profileId;

    adContext.setProfile(profileIdHtml5);
    adContext.addKeyValue('_fw_fss', '_fw_search');
    if (options.subsessionToken) {
      adContext.setCapability('sync', FreewheelSDKWrapper.SDK.CAPABILITY_STATUS_ON);
      adContext.setCapability('ress', FreewheelSDKWrapper.SDK.CAPABILITY_STATUS_ON);
      adContext.startSubsession(options.subsessionToken);
    }

    // add custom key-value pairs to the ad request
    Object.keys(options.adRequestKeyValues).forEach((key) => {
      adContext.addKeyValue(key, options.adRequestKeyValues[key]);
    });

    const unintendedAutostart = (options.unintendedAutostart ?
      FreewheelSDKWrapper.SDK.VIDEO_ASSET_AUTO_PLAY_TYPE_UNATTENDED :
      FreewheelSDKWrapper.SDK.VIDEO_ASSET_AUTO_PLAY_TYPE_ATTENDED);

    adContext.setVideoAsset(
      options.videoAssetId,
      options.videoLiveDuration > 0 ? options.videoLiveDuration : duration,
      options.siteSectionNetworkId,
      null,
      options.autoplay ? unintendedAutostart :
        FreewheelSDKWrapper.SDK.VIDEO_ASSET_AUTO_PLAY_TYPE_NONE,
      null,
      options.useFwId ? FreewheelSDKWrapper.SDK.ID_TYPE_FW : FreewheelSDKWrapper.SDK.ID_TYPE_CUSTOM
    );
    adContext.setSiteSection(options.siteSectionId, options.siteSectionNetworkId);

    if (options.showPrerolls && !options.useFwId) { // [VIP-24837] Hack
      adContext.addTemporalSlot(
        'Preroll_1',
        FreewheelSDKWrapper.SDK.ADUNIT_PREROLL, 0);
    }

    if (options.showMidrolls && options.midrollCuepoints) {
      options.midrollCuepoints.forEach((cuepoint, i) => {
        const customSlotId = `Midroll_${cuepoint}`;
        logFunc(`getAdContext: binding ${customSlotId} to cuepoint at ${cuepoint}`);
        adContext.addTemporalSlot(
          customSlotId,
          FreewheelSDKWrapper.SDK.ADUNIT_MIDROLL, cuepoint, null, options.setMidrollSequence ? (i + 1) : null);
      });
    }

    if (options.showPostrolls && !options.useFwId) { // [VIP-24837] Hack
      adContext.addTemporalSlot(
        'Postroll_1',
        FreewheelSDKWrapper.SDK.ADUNIT_POSTROLL, 0);
    }

    adContext.setVideoAssetCurrentTimePosition(currentTime);

    AdManagerHandler._setupAdContextParameters(
      adContext, options.videoStartDetectTimeout, options.videoProgressDetectTimeout, options.prct);
    AdManagerHandler._prepareForAsyncLoading(adContext, isDesktop);
    AdManagerHandler._updateDesiredBitrate(adContext, idealBitrate);

    return adContext;
  }

  static _setupAdContextParameters(adContext, videoStartDetectTimeout, videoProgressDetectTimeout, prct) {
    adContext.setParameter(
      FreewheelSDKWrapper.SDK.PARAMETER_RENDERER_DISPLAY_COAD_SCRIPT_NAME,
      'writeDisplay',
      FreewheelSDKWrapper.SDK.PARAMETER_LEVEL_GLOBAL
    );

    adContext.setParameter(
      FreewheelSDKWrapper.SDK.PARAMETER_RENDERER_VIDEO_START_DETECT_TIMEOUT,
      videoStartDetectTimeout,
      FreewheelSDKWrapper.SDK.PARAMETER_LEVEL_GLOBAL
    );
    adContext.setParameter(
      FreewheelSDKWrapper.SDK.PARAMETER_RENDERER_VIDEO_PROGRESS_DETECT_TIMEOUT,
      videoProgressDetectTimeout,
      FreewheelSDKWrapper.SDK.PARAMETER_LEVEL_GLOBAL
    );
    adContext.setParameter(
      FreewheelSDKWrapper.SDK.PARAMETER_RENDERER_VIDEO_DISPLAY_CONTROLS_WHEN_PAUSE,
      false,
      FreewheelSDKWrapper.SDK.PARAMETER_LEVEL_GLOBAL
    );

    // [PLP-7587] Force freewheel to set cookies for VASTs
    adContext.setParameter(
      'translator.vast.loadWithCookie',
      true,
      FreewheelSDKWrapper.SDK.PARAMETER_LEVEL_GLOBAL
    );

    if (prct) {
      adContext.setParameter(
        FreewheelSDKWrapper.SDK.PARAMETER_PAGE_SLOT_CONTENT_TYPE,
        prct, FreewheelSDKWrapper.SDK.PARAMETER_LEVEL_GLOBAL);
    }

    // don't let AdManager try to reset content source etc - it often fails (and we manage it in the plugin anyway)
    adContext.setParameter(
      FreewheelSDKWrapper.SDK.PARAMETER_EXTENSION_CONTENT_VIDEO_ENABLED,
      false,
      FreewheelSDKWrapper.SDK.PARAMETER_LEVEL_GLOBAL
    );

    // disable automatic ad clickthrough, and handle manually instead
    adContext.setParameter(
      FreewheelSDKWrapper.SDK.PARAMETER_RENDERER_VIDEO_CLICK_DETECTION,
      false,
      FreewheelSDKWrapper.SDK.PARAMETER_LEVEL_GLOBAL
    );
  }

  static _updateDesiredBitrate(adContext, idealBitrate) {
    adContext.setParameter(FreewheelSDKWrapper.SDK.PARAMETER_DESIRED_BITRATE, idealBitrate,
      FreewheelSDKWrapper.SDK.PARAMETER_LEVEL_GLOBAL);
  }

  /**
   * [VIP-21291] Calls to liverail will sometimes timeout so we need to make the calls asynchronus where possible to be
   * able to skip them and to not block the gui redering.
   * This is not possible on mobile and tablet devices that require that play events should originate from a user event.
   */
  static _prepareForAsyncLoading(adContext, isDesktop) {
    if (isDesktop) {
      adContext.setParameter('translator.vast.asyncLoad', true, FreewheelSDKWrapper.SDK.PARAMETER_LEVEL_GLOBAL);
    }
  }

  static _loadAdManager(adManagerUrl, cb) {
    // load AdManager.js async from remote server, call callback when done
    // NOTE: long-term it'd be better to provide our own wrapper around AdManager,
    // so we don't need to include it when we use the Flash AdManager
    // (today we're using FreewheelSDKWrapper.SDK a lot for both JS and AS3)
    const headTag = document.getElementsByTagName('head')[0];
    const adManagerTag = document.createElement('script');
    adManagerTag.type = 'text/javascript';
    adManagerTag.src = adManagerUrl;
    adManagerTag.addEventListener('load', () => {
      headTag.removeChild(adManagerTag);
      cb();
    }, false);

    adManagerTag.addEventListener('abort', () => cb(new Error('Failed loading AdManager.js')), false);
    adManagerTag.addEventListener('error', () => cb(new Error('Failed loading AdManager.js')), false);

    headTag.insertBefore(adManagerTag, headTag.firstChild);
  }
}
