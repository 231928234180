import videojs from 'video.js';
import debug from 'debug';

// Setup debugging
const debugLog = debug('sourceloader:log');
const errorLog = debug('sourceloader:error');
errorLog.log = console.error.bind(console); // eslint-disable-line no-console

export default class VideoSourceLoader {
  constructor(player, options) {
    this._player = player;
    this._options = options;

    const errors = this._validateOptions(this._options);
    if (errors.length) {
      this._error('Invalid configuration', errors);
      return;
    }

    this._fakePlayHandler = this._onPlay.bind(this);

    this._player.trigger({
      type: 'registerVideoStartBehavior',
      name: 'SOURCE_LOADER',
      playHandler: this._fakePlayHandler,
    });
  }

  _setSource(source) {
    this._log('_setSource', source);

    this._player.trigger('sourceSetAsync');
    this._player.src(source);
    this._player.trigger({
      type: 'removeVideoStartBehavior',
      playHandler: this._fakePlayHandler,
      forcePlay: true,
    });
  }

  _onPlay() {
    this._log('_onPlay - Calling callback for src loading');

    if (this.alreadyCalled) return;
    this.alreadyCalled = true;

    let callback;
    let async;
    if (this._options.asynchronousCallback &&
      this._player.env.desktop) {
      callback = this._options.asynchronousCallback;
      async = true;

      // [VIP-21656] The loader is only going to show up if we use an asynchronous callback
      // because the synchronous one block the DOM update. Also, on mobile devices,
      // there is a risk that the video would not start after one tap if the synchronous
      // callback takes too long. So we want to keep the play button on screen so that
      // users would click on play again.
      this._startLoadingMode();
    } else {
      callback = this._options.synchronousCallback;
      async = false;
    }

    callback((source) => {
      this._log('_Calling SET SOURCE');
      this._handleSource(source, async);
    });
  }

  _handleSource(source, async) {
    this._log('_handleSource');
    if (!source || source === '' || source.error ||
      (typeof source !== 'string' && !source.src)) {
      this._onError(source);
      return;
    }
    this._player.trigger({
      type: 'gotSourceUrl',
      source,
    });
    let sourceObject;
    if (typeof source === 'string') {
      sourceObject = {
        src: source,
        type: this._getSourceTypeFromUrl(source),
      };
    } else {
      sourceObject = source;
    }

    sourceObject = this._player.configureSources(sourceObject).pop();

    if (sourceObject.tokenized ||
      sourceObject.src.indexOf('hmac=') !== -1 ||
      sourceObject.src.indexOf('token=') !== -1) {
      this._handleTokenizedSource(sourceObject, async, this._usesCookies(sourceObject.src));
      return;
    }

    this._setSource([sourceObject]);
  }

  _handleTokenizedSource(sourceObject, async, usesCookies) {
    this._log('_handleTokenizedSource', async);
    const xhr = new XMLHttpRequest();

    function onResponse() {
      let manifestFile = xhr.responseText;

      if (xhr.status >= 400) {
        this._log('Stream loading error', xhr.status);
        this._player.error(4); // MEDIA ERROR
        return;
      }
      if (this._subManifestsUseRelativePaths(manifestFile)) {
        manifestFile = this._getManifestsWithAbsolutePaths(sourceObject.src, manifestFile);
      }

      const blob = new Blob([manifestFile], { type: sourceObject.type });
      const blobUrl = window.URL.createObjectURL(blob);

      this._setSource([{
        src: this._doesNotSupportBlob() ? sourceObject.src : blobUrl,
        type: sourceObject.type,
        withCredentials: usesCookies,
      }]);
    }

    xhr.addEventListener('load', onResponse.bind(this));
    xhr.addEventListener('error', () => {
      this._log('Stream loading error');
      this._player.error(4); // MEDIA ERROR
    });
    xhr.open('GET', sourceObject.src, async);
    xhr.withCredentials = usesCookies;
    xhr.send();
  }

  _getSourceTypeFromUrl(srcUrl) {
    let url = srcUrl;

    // Remove ?query-parameter if it exists in URL (does in Viaplay URLs)
    url = url.split('?')[0];
    const extension = url.split('.').pop();

    if (extension === 'mpd') return 'application/dash+xml';
    if (extension === 'm3u8') return 'application/x-mpegURL';
    if (extension === 'mp4') return 'video/mp4';

    return 'application/x-mpegURL'; // We default to HLS;
  }

  _onError(source) {
    if (source.error && source.error.code === 0) {
      this._log('An custom error occured loading the stream source', source.error);
      this._player.error(source.error.message); // CUSTOM ERROR
    } else {
      this._log('Stream url is invalid');
      this._player.error(4); // MEDIA ERROR
    }
  }

  _startLoadingMode() {
    this._log('_startLoadingMode');
    this._player.addClass('autoplay');

    this._player.on('adPrerollStart', () => this._player.removeClass('autoplay'));
    this._player.on('mediaStart', () => this._player.removeClass('autoplay'));
    this._player.on('mediaError', () => this._player.removeClass('autoplay'));
  }

  _validateOptions(options) {
    const errors = [];

    if (!options) errors.push('no options provided');
    if (!options.synchronousCallback) {
      errors.push('options.synchronousCallback is required so that the plugin would work on mobile');
    }

    return errors;
  }

  _getManifestsWithAbsolutePaths(sourceUrl, manifestFile) {
    const pathToMasterManifest = sourceUrl.substring(0, sourceUrl.indexOf('?'));
    const pathToSubPlaylists = pathToMasterManifest.substring(0, pathToMasterManifest.lastIndexOf('/') + 1);
    return manifestFile.replace(/[^"\n]*\.m3u8/g, `${pathToSubPlaylists}$&`);
  }

  _subManifestsUseRelativePaths(mainManifestAsString) {
    return mainManifestAsString.search(/https?.*\.m3u8\b/) === -1;
  }

  _doesNotSupportBlob() {
    return this._player.env.iOS ||
      this._player.env.android ||
      // Function URL.createObjectURL(blob) does not work properly on MS Edge
      // https://connect.microsoft.com/IE/feedback/details/2473311/url-createobjecturl-blob-not-creating-a-good-url-in-edge-ie-works-on-chrome
      this._player.env.edge;
  }

  _usesCookies(src) {
    // We assume that all the jic origins do not use (or support) 3rd party auth
    // Meaning: they return a '*' for Allow-cross-origin header
    return src.indexOf('jic') === -1;
  }

  _log(...args) {
    debugLog(`[#${this._player.id()}]`, ...args);
  }

  _error(...args) {
    errorLog(`[#${this._player.id()}]`, ...args);
    console.error('> avodp - srcloader error >>', args[0]); // eslint-disable-line no-console
  }
}

// only instantiate the plugin if it's in a AMD module or not under test
videojs.plugin('sourceLoader', function init(options) {
  this.sourceLoader = new VideoSourceLoader(this, options);
});
