import React from 'react';
import debug from 'debug';

const debugLog = debug('chromecastUI:log');

class ProgressBar extends React.Component {
  constructor(props, context) {
    super(props, context);
    debugLog('Constructing react progressBar', this.props);

    /** Assume props
     () seekTo - seeking action callback
     int currentTime
     int duration
     */

    this.state = this.updateProgressBar(this.props.currentTime, this.props.duration);
    this.cuepointElementArray = [];
  }

  componentDidMount() {
    this.setLeftValueOfCuepoints();
  }

  componentWillReceiveProps(nextProps) {
    this.setState(this.updateProgressBar(nextProps.currentTime, nextProps.duration));
  }

  componentDidUpdate() {
    this.setLeftValueOfCuepoints();
  }

  setLeftValueOfCuepoints() {
    this.cuepointElementArray.forEach((cuepointObject) => {
      if (cuepointObject.element) {
        cuepointObject.element.style.left =
          `${(Math.floor((cuepointObject.time / this.props.duration) * 100 * 100) / 100)}%`;
      }
    });
  }

  updateProgressBar(currentTime, duration) {
    const initValue = {
      progressWidth: 0,
      progressIndicatorMargin: 0,
    };

    if (duration === 0) {
      return initValue;
    }

    const progressPercentage = Math.floor((currentTime / duration) * 100 * 100) / 100;

    if (isNaN(progressPercentage)) {
      return initValue;
    }

    return {
      progressWidth: `${progressPercentage}%`,
      progressIndicatorMargin: `${progressPercentage}%`,
    };
  }

  seekMedia(event) {
    const cursorEndPosition = parseInt(event.nativeEvent.offsetX, 10);
    const widthFullBar = this._progress_bg.offsetWidth;
    let seekedTime = this.props.currentTime;
    if (event.target.id === 'progress_indicator') {
      seekedTime = parseInt(
        this.props.currentTime + ((this.props.duration * cursorEndPosition) /
        widthFullBar), 10);
    } else {
      seekedTime = parseInt(
        (cursorEndPosition * this.props.duration) / widthFullBar, 10);
    }
    this.updateProgressBar(seekedTime, this.props.duration);
    this.props.playerAction.seekTo.call(this.props.playerAction, seekedTime);
  }

  render() {
    this.cuepointElementArray = [];
    return (
      <button
        className="progress-bar"
        onClick={e => this.seekMedia(e)}
      >
        <div
          id="progress_bg"
          ref={(div) => { this._progress_bg = div; }}
          className="chromecast-control-button progress_bg"
        />
        <div
          id="progress"
          className="chromecast-control-button progress"
          style={{ width: this.state.progressWidth }}
        />
        <div
          id="progress_indicator"
          className="progress_indicator"
          style={{ marginLeft: this.state.progressIndicatorMargin }}
        />
        {
          this.props.playerInfo &&
          this.props.playerInfo.mediaInfo &&
          this.props.playerInfo.mediaInfo.cuepoints &&
          this.props.playerInfo.mediaInfo.cuepoints.map(
            cuePoint =>
              <div
                className="chromecast-cuepoint"
                key={cuePoint}
                ref={e => this.cuepointElementArray.push({ element: e, time: cuePoint })}
              />
          )
        }
      </button>
    );
  }
}

export default ProgressBar;
