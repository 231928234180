import videojs from 'video.js';
import debug from 'debug';
import window from 'global/window';

// Setup debugging
const debugLog = debug('suggestions:log');

const DEFAULT_OPTIONS = {
  countdownTime: 15,
  cuepoint: 0,
  title: '',
  suggestionAction: null,
  suggestion: null /* { img: '', link: '', title: ''} */,
  labels: {
    watchAgain: 'Watch again',
    startingIn: 'Starting in {seconds} seconds',
    startingInOneSecond: 'Starting in {seconds} second',
  },
};

const STYLE = {
  SINGLE: 'single',
  MULTIPLE: 'multiple',
  MULTIPLE_FORMAT: 'multipleformat',
  MULTIPLE_FORMAT_THEME: 'multipleformattheme',
  IMAGE: 'image',
  MULTIPLE_FORMAT_UPSELL: 'multipleformatupsell',
};

let suggestionsCounter = 0;

export default class Suggestions {
  constructor(player, incomingOptions) {
    this._player = player;

    this._id = suggestionsCounter;
    suggestionsCounter += 1;

    if (!incomingOptions || incomingOptions.enabled === false) {
      this._log('Suggestions are disabled');
      return;
    }

    this._options = videojs.mergeOptions(DEFAULT_OPTIONS, incomingOptions);
    this._log('options', this._options);
    this._hidden = true;

    if (this._options.suggestion) {
      this._suggestions = [this._options.suggestion];
      this._log('Suggestion using next episode', this._suggestions);
    } else {
      this._suggestions = this._options.suggestions;
      this._log('Suggestion using related content', this._suggestions);
    }

    if (!this._suggestions || !this._suggestions.length) {
      this._log('No suggestion - not showing anything');
      return;
    }

    if (this._options.style) {
      this._style = this._options.style;
    } else {
      this._style = this._suggestions.length === 1 ? STYLE.SINGLE : STYLE.MULTIPLE;
    }

    if (this._style === STYLE.IMAGE) {
      this._options.countdownTime = -1;
    }

    this._player.ready(() => {
      this._player.on('hideSuggestions', this._hideSuggestions.bind(this));

      this._player.on('playSuggestion', () => {
        this._playSuggestion(this._suggestions[0]);
      });

      this._player.addClass('vjs-suggestions-inactive');

      if (this._style === STYLE.MULTIPLE_FORMAT) {
        this._className = 'vjs-suggestions-multiple-format';
      } else if (this._style === STYLE.MULTIPLE_FORMAT_THEME) {
        this._className = 'vjs-suggestions-theme';
      } else if (this._style === STYLE.MULTIPLE) {
        this._className = 'vjs-suggestions-multiple';
      } else if (this._style === STYLE.SINGLE) {
        this._className = 'vjs-suggestions-single';
      } else if (this._style === STYLE.IMAGE) {
        this._className = 'vjs-suggestions-image';
      } else if (this._style === STYLE.MULTIPLE_FORMAT_UPSELL) {
        this._className = 'vjs-suggestions-upsell';
      }

      this._createSuggestions(this._className);

      if (
        this._style === STYLE.SINGLE ||
        this._style === STYLE.MULTIPLE ||
        this._style === STYLE.MULTIPLE_FORMAT ||
        this._style === STYLE.MULTIPLE_FORMAT_THEME ||
        this._style === STYLE.MULTIPLE_FORMAT_UPSELL
      ) {
        this._createTitleBar();
        this._createWatchAgainButton();
      }

      this._player.on('mediaStart', () => {
        if (this._options.cuepoint < 0) {
          this._addSuggestionCuepointAt(Math.floor(this._player.getMediaDuration() + this._options.cuepoint));
        } else if (this._options.cuepoint > 0) {
          this._addSuggestionCuepointAt(this._options.cuepoint);
        } else {
          this._player.on('controlEnded', this._atMediaEnded.bind(this));
        }
      });
    });
  }

  _addPlayerClasses() {
    if (this._style === STYLE.MULTIPLE_FORMAT_THEME || this._style === STYLE.MULTIPLE_FORMAT_UPSELL) {
      this._player.addClass('vjs-suggestions-multiple-format');
    }
  }

  _addSuggestionCuepointAt(time) {
    this.suggestionCuepoint = time;
    debugLog('Adding suggestion cuepoint', this.suggestionCuepoint);
    this._player.trigger({
      type: 'addCuepoint',
      name: 'suggestions',
      time: this.suggestionCuepoint, // Time in seconds to execute the cuepoint callback
      callback: this._atSuggestionsCuepoint.bind(this),
    });

    this._player.on('mediaSeekComplete', this._onMediaSeekComplete.bind(this));
    this._player.on('adRollStart', this._onAdRollStart.bind(this));
  }

  _onMediaSeekComplete(evt) {
    this._log('_onMediaSeekComplete', evt);

    if (evt.afterSeekTime > this.suggestionCuepoint) {
      if (this._player.isPlayingAd && this._player.isPlayingAd()) {
        this._player.one('adRollEnd', this._showSuggestionsScreen.bind(this));
      } else {
        this._showSuggestionsScreen();
      }
    }
  }

  _onAdRollStart() {
    if (!this._suggestionsAreVisible()) return;

    this._player.trigger('hideSuggestions');
    this._player.one('adRollEnd', this._showSuggestionsScreen.bind(this));
  }

  _playSuggestion(suggestion) {
    this._player.trigger('suggestionClick');
    this._player.clearInterval(this.countdownInterval);

    if (suggestion.onPlay) {
      this._log('_playSuggestion, calling onPlay callback');
      this._player.trigger('hideSuggestions');
      suggestion.onPlay();
    } else {
      this._log('_playSuggestion, redirecting');
      this._player.trigger('dispose');
      window.location.href = suggestion.link;
    }
  }

  _atMediaEnded() {
    this._log('_atMediaEnded, showing suggestion');
    this._showSuggestionsScreen();
  }

  _atSuggestionsCuepoint(cuepoint, name) {
    this._log('_atSuggestionsCuepoint, showing suggestion', cuepoint, name);
    this._showSuggestionsScreen();
    return false; // don't remove the cuepoint
  }

  _showSuggestionsScreen() {
    this._log('_showSuggestionsScreen');

    // On iOS we can't show the sugestions while in fullsreen. Wait for the player to exit fullscreen before
    // showing the suggestions.
    if (this._player.env.iOS && this._player.isFullscreen) {
      this._player.one('fullscreenchange', this._showSuggestionsScreen.bind(this));
      return;
    }

    this._player.one('seeking', () => this._player.trigger('hideSuggestions'));
    this._showSuggestions();

    if (this._options.countdownTime >= 0) {
      this._startCountdown();
    }
  }

  _createSuggestions(className) {
    this._suggestionsWrapperComponent = this._player.addChild('suggestions', {
      componentClass: 'Component',
      el: videojs.getComponent('Component').prototype.createEl('div', {
        className: `${className} vjs-suggestions`,
      }),
    });

    const suggestionsComponent = this._suggestionsWrapperComponent.addChild('suggestions', {
      componentClass: 'Component',
      el: videojs.getComponent('Component').prototype.createEl('div', {
        className: 'vjs-suggestions-suggestions',
      }),
    });

    if (this._style === STYLE.MULTIPLE_FORMAT) {
      suggestionsComponent.addChild('suggestionsTitle', {
        componentClass: 'Component',
        el: videojs.getComponent('Component').prototype.createEl('div', {
          className: 'vjs-suggestions-title',
          innerHTML: `<div>${this._options.nextType}</div>`,
        }),
      });
    }

    if (this._style === STYLE.MULTIPLE_FORMAT_THEME) {
      this._suggestionsWrapperComponent.addChild('redirectButton', {
        componentClass: 'Component',
        el: videojs.getComponent('Component').prototype.createEl('a', {
          className: 'vjs-suggestion-redirect-button',
          innerHTML: `${this._options.labels.themeSuggestionsLink}`,
          href: `${this._options.themeSuggestions && this._options.themeSuggestions.suggestionsLink}`,
        }),
      });

      this._suggestionsWrapperComponent.addChild('curvedOverlay', {
        componentClass: 'Component',
        el: videojs.getComponent('Component').prototype.createEl('div', {
          className: 'vjs-curved-overlay-curve',
          innerHTML: '<svg class="vjs-curved-overlay-curve" width="100%" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMid slice" data-reactid="56"><path d="M0 0 Q 50 8 100 0 V100 H0 Z" data-reactid="57"></path></svg>',
        }),
      });

      const playerClickableOverlay = this._player.addChild('playerClickableOverlay', {
        componentClass: 'Component',
        el: videojs.getComponent('Component').prototype.createEl('div', {
          className: 'vjs-player-ClickableOverlay',
        }),
      });

      this._player.addChild('posterClickableOverlay', {
        componentClass: 'Component',
        el: videojs.getComponent('Component').prototype.createEl('a', {
          className: 'vjs-poster-ClickableOverlay',
          href: `${this._options.themeSuggestions && this._options.themeSuggestions.suggestionsLink}`,
        }),
      });

      const onClickGrowPlayer = (e) => {
        this._player.trigger('hideSuggestions');
        e.stopPropagation();
        e.preventDefault();
        return false;
      };

      const onMouseoverSmallPlayer = (e) => {
        this._player.tech_.el().classList.add('vjs-small-player-hovered');
        e.stopPropagation();
        e.preventDefault();
        return false;
      };

      const onMouseoutSmallPlayer = (e) => {
        this._player.tech_.el().classList.remove('vjs-small-player-hovered');
        e.stopPropagation();
        e.preventDefault();
        return false;
      };

      playerClickableOverlay.on(['click', 'touchEnd'], onClickGrowPlayer.bind(this));
      playerClickableOverlay.on('mouseover', onMouseoverSmallPlayer.bind(this));
      playerClickableOverlay.on('mouseout', onMouseoutSmallPlayer.bind(this));
    }

    this._suggestions.forEach((suggestion, index) => {
      const shouldHaveCountdown = (
        index === 0 &&
        this._options.countdownTime >= 0 &&
        this._style !== STYLE.MULTIPLE_FORMAT &&
        this._style !== STYLE.MULTIPLE_FORMAT_THEME
      );
      this._createSuggestionComponent(suggestionsComponent, suggestion, shouldHaveCountdown);
    });

    if (this._style === STYLE.MULTIPLE_FORMAT_UPSELL) {
      this._suggestionsWrapperComponent.addChild('upsellSeparatorContainer', {
        componentClass: 'Component',
        el: videojs.getComponent('Component').prototype.createEl('div', {
          className: 'vjs-suggestion-upsell-separator-container',
        }),
      }).addChild('upsellSeparator', {
        componentClass: 'Component',
        el: videojs.getComponent('Component').prototype.createEl('div', {
          className: 'vjs-suggestion-upsell-separator',
        }),
      });

      const upsellBox = this._suggestionsWrapperComponent.addChild('upsellTextContainer', {
        componentClass: 'Component',
        el: videojs.getComponent('Component').prototype.createEl('div', {
          className: 'vjs-suggestion-upsell-text-container',
        }),
      });

      upsellBox.addChild('upsellText', {
        componentClass: 'Component',
        el: videojs.getComponent('Component').prototype.createEl('div', {
          className: 'vjs-suggestion-upsell-text',
          innerHTML: `${this._options.upsellCreateAccount.upsellText}`,
        }),
      });

      upsellBox.addChild('redirectButton', {
        componentClass: 'Component',
        el: videojs.getComponent('Component').prototype.createEl('a', {
          className: 'vjs-suggestion-redirect-button',
          innerHTML: `${this._options.labels.createAccount}`,
          href: `${this._options.upsellCreateAccount.createAccountLink}`,
        }),
      });
    }
  }

  _createWatchAgainButton() {
    const watchAgainButton = this._suggestionsWrapperComponent.addChild('watchAgainOverlay', {
      componentClass: 'ClickableComponent',
      el: videojs.getComponent('Component').prototype.createEl('div', {
        className: 'vjs-suggestions-watch-again',
        innerHTML: `<span><i class="vjs-icon-replay"></i>${this._style === STYLE.MULTIPLE_FORMAT_UPSELL ?
          '' : this._options.labels.watchAgain}</span>`,
      }),
    });

    watchAgainButton.on(['click', 'touchend'], () => {
      this._player.clearInterval(this.countdownInterval);
      this._player.trigger('hideSuggestions');
      if (this._player.ended() || this._player.paused()) {
        this._player.play();
      }

      if (!this._player.ended()) {
        this._player.currentTime(0);
      }
    });
  }

  _createTitleBar() {
    if (!this._options.labels.title) return;

    this._suggestionsWrapperComponent.addChild('titleBar', {
      componentClass: 'Component',
      el: videojs.getComponent('Component').prototype.createEl('div', {
        className: 'vjs-suggestions-title-bar',
        innerHTML: `<h1>${this._options.labels.title}</h1>`,
      }),
    });
  }

  _createCountdown(suggestionComponent) {
    const seconds = this._options.countdownTime;
    let label;
    if (this._style === STYLE.MULTIPLE) {
      label = `${seconds}`;
    } else {
      label = this._options.labels.startingIn;
    }

    const startingIn = suggestionComponent.addChild('startingInOverlay', {
      componentClass: 'Component',
      el: videojs.getComponent('Component').prototype.createEl('div', {
        className: 'vjs-suggestions-starting-in',
      }),
    });
    startingIn.on(['click', 'touchend'], () => {
      this._playSuggestion(this._suggestions[0]);
    });

    this._countdownComponent = startingIn.addChild('countdown', {
      componentClass: 'Component',
      el: videojs.getComponent('Component').prototype.createEl('div', {
        innerHTML: label.replace('{seconds}', seconds),
      }),
    });
  }

  _startCountdown() {
    let seconds = this._options.countdownTime;

    let paused = false;
    // should not need to be cleared here. but make sure that we don't create two countdowns
    // if this method is called twice
    this._player.clearInterval(this.countdownInterval);
    this.countdownInterval = this._player.setInterval(() => {
      if (paused) return;
      seconds -= 1;
      let startingIn = null;
      if (this._style === STYLE.MULTIPLE) {
        startingIn = `${seconds}`;
      } else if (seconds === 1) {
        startingIn = this._options.labels.startingInOneSecond;
      } else {
        startingIn = this._options.labels.startingIn;
      }

      if (seconds < 1) {
        this._playSuggestion(this._suggestions[0]);
      } else {
        this._countdownComponent.el().innerHTML = startingIn.replace('{seconds}', seconds);
        this._log('updateCountdownTime', seconds);
        this._player.trigger({
          type: 'updateCountdownTime',
          countdownTime: seconds,
        });
      }
    }, 1000);

    this._player.on('pause', () => {
      if (!paused && !this._player.ended()) paused = true;
    });

    this._player.on('play', () => {
      if (paused) paused = false;
    });
  }

  _createSuggestionComponent(suggestionsComponent, suggestion, shouldHaveCountdown) {
    const title = suggestion.title || '';
    let dateElement;
    if (suggestion.date) {
      dateElement = `<p class="vjs-suggestions-suggestion-date">${suggestion.date}</p>`;
    } else {
      dateElement = '';
    }

    let imageElement;
    if (this._style === STYLE.MULTIPLE_FORMAT || this._style === STYLE.MULTIPLE_FORMAT_THEME) {
      imageElement = `<div class="vjs-suggestions-suggestion-overlay-image"><img src="${suggestion.img}" /></div>`;
    } else {
      imageElement = `<img src="${suggestion.img}" />`;
    }

    let className = 'vjs-suggestions-suggestion';
    if (shouldHaveCountdown) className += ' vjs-suggestions-countdown';

    const suggestionComponent = suggestionsComponent.addChild('suggestions', {
      componentClass: 'ClickableComponent',
      el: videojs.getComponent('Component').prototype.createEl('div', {
        className,
        innerHTML: imageElement,
      }),
    });
    const suggestionMetaComponent = suggestionComponent.addChild('suggestionsMeta', {
      componentClass: 'Component',
      el: videojs.getComponent('Component').prototype.createEl('div', {
        className: 'vjs-suggestions-suggestion-meta',
        innerHTML: `<p>${title}</p>${dateElement}`,
      }),
    });

    if (this._options.nextType && this._style === STYLE.SINGLE) {
      suggestionMetaComponent.addChild('nextEpisodeType', {
        componentClass: 'Component',
        el: videojs.getComponent('Component').prototype.createEl('div', {
          className: 'vjs-suggestion-type',
          innerHTML: `<div>${this._options.nextType}</div>`,
        }),
      });

      suggestionMetaComponent.addChild('nextEpisodeType', {
        componentClass: 'Component',
        el: videojs.getComponent('Component').prototype.createEl('div', {
          className: 'vjs-suggestion-video-name',
          innerHTML: `<div>${title}</p>${dateElement}</div>`,
        }),
      });

      const closeButton = suggestionComponent.addChild('nextEpisodeType', {
        componentClass: 'ClickableComponent',
        el: videojs.getComponent('Component').prototype.createEl('div', {
          className: 'vjs-suggestion-close-button',
          innerHTML: 'X',
        }),
      });
      closeButton.on(['click', 'touchend'], (event) => {
        event.stopPropagation();
        this._player.trigger('hideSuggestions');
      });
    }

    suggestionComponent.on(['click', 'touchend'], () => this._playSuggestion(suggestion));

    if (shouldHaveCountdown) {
      this._createCountdown(suggestionMetaComponent);
    }
  }

  _showSuggestions() {
    // If we are already showing a suggestions screen then don't show this one
    if (this._suggestionsAreVisible() && !this._suggestionsShowing) return;

    this._addPlayerClasses();

    if (this._countdownComponent) {
      this._countdownComponent.el().innerHTML = this._options.labels.startingIn.replace(
        '{seconds}', this._options.countdownTime);
      this._player.trigger({
        type: 'updateCountdownTime',
        countdownTime: this._options.countdownTime,
      });
    }
    this._player.trigger('suggestionsShown', { style: this._style, suggestionAction: this._options.suggestionAction });
    this._player.removeClass('vjs-suggestions-inactive');
    this._player.addClass('vjs-suggestions-active');
    this._player.addClass(this._className);
    if (this._options.themeSuggestions) {
      this._player.poster(this._options.themeSuggestions.suggestionsPoster);
      this._player.tech_.el().removeAttribute('poster'); // Safari fix
    }
    this._suggestionsShowing = true;
  }

  _hideSuggestions() {
    this._log('_hideSuggestions');
    this._player.clearInterval(this.countdownInterval);
    this._player.trigger('suggestionsHidden');
    this._player.removeClass('vjs-suggestions-active');
    this._player.addClass('vjs-suggestions-inactive');
    this._player.removeClass(this._className);
    this._suggestionsShowing = false;
  }

  // Returns true if any suggestions are showing, not only from this plugin instance.
  _suggestionsAreVisible() {
    return this._player.hasClass('vjs-suggestions-active');
  }


  _log(...args) {
    debugLog(`[#${this._player.id()}][#${this._id}]`, ...args);
  }
}

videojs.plugin('suggestions', function init(options) {
  if (Array.isArray(options)) options.forEach(_options => new Suggestions(this, _options));
  else this.suggestions = new Suggestions(this, options);
});
