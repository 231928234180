import Promise from 'promise';
import debug from 'debug';

const debugLog = debug('avodplayer:connectivity-detector:adblock');

/**
 * [VIP-16800] BlockAdBlock (4.x beta 3) fails to detect AdBlockPlus after page load sometimes.
 * AdBlockPlus hides the player to hide the ads, if we detect this an adblocker should be present.
 */
export default class AdBlockDetector {

  check() {
    debugLog('checking');
    return new Promise((resolve, reject) => {
      if (this._hasAdBlockPlus()) reject('adblock');
      else resolve();
    });
  }

  _hasAdBlockPlus() {
    const videoTags = [...document.querySelectorAll('.avodp video')];

    return videoTags.filter(videoTag => videoTag.style.cssText.match(/display.*none.*important/)).length;
  }
}
