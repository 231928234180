import videojs from 'video.js';
import debug from 'debug';
import objectAssign from 'object-assign';
import window from 'global/window';

// Setup debugging
const debugLog = debug('gtm:log');
const errorLog = debug('gtm:error');

const DEFAULT_OPTIONS = {
  nowFunction: () => new Date(),
};

const eventTypes = {
  videoMessage: 'video message',
  videoInteraction: 'video interaction',
  contentStart: 'stream start',
  videoPlaying: 'video playing',
  adPlaying: 'ad playing',
  ageGate: 'age gate',
  adError: 'ad error',
};

const eventActions = {
  message: 'message',
  play: 'play',
  autoplay: 'autoplay',
  pause: 'pause',
  fullscreen: 'fullscreen',
  share: 'share',
  contentStart: 'stream start',
  videoPlaying: 'video playing',
  adPlaying: 'ad playing',
  suggestions: 'player autostart',
  adViews: 'ad views',
};

export default class GoogleTagManagerPlugin {
  constructor(player, options) {
    this._player = player;

    const errors = this._validateOptions(options);
    if (errors.length) {
      this._error('Invalid Google Tag Manager Configuration', errors);
      return;
    }

    this._options = videojs.mergeOptions(DEFAULT_OPTIONS, options);
    this._customDimensions = this._options.customDimensions;

    this._log('options', this._options);

    this.dataLayer = window[this._options.dataLayer] || window.dataLayer;

    this.mediaOrAdStartTriggered = false;

    this._setUpListeners();

    this.stopwatch = this._getStopWatch();
  }

  _getStopWatch() {
    let _timeElapsed = 0;
    let _startTime = null;
    const _pause = () => {
      if (_startTime !== null) {
        _timeElapsed +=
          this._options.nowFunction().getTime() - _startTime.getTime();
      } else {
        this._error('Stopwatch paused before started, please check why.');
      }
    };
    return {
      startOrResume: () => {
        _startTime = this._options.nowFunction();
      },
      pause: _pause,
      getTimeAndRestart: () => {
        let _result = 0;
        if (_startTime !== null) {
          _pause();
          _result = _timeElapsed;
        }
        _startTime = this._options.nowFunction();
        _timeElapsed = 0;
        return _result / 1000;
      },
    };
  }

  _validateOptions(options) {
    const errors = [];
    if (!options.customDimensions) errors.push('options.customDimensions needs to be specified');
    if (!options.progressResolution) errors.push('options.progressResolution needs to be specified');
    if (options.progressTracking && options.progressTracking.constructor !== Array) {
      errors.push('options.progressTracking needs to be an Array');
    }
    if (options.dataLayer && typeof options.dataLayer !== 'string') {
      errors.push('options.dataLayer needs to be a Strnig or not be set');
    }

    return errors;
  }

  _setUpListeners() {
    this._log('_setUpListeners');

    this._setupMediaTrackingListeners();
    this._setupAdTrackingListeners();

    this._player.one(
      'adBlock',
      this._trackVideoPlayerEvent.bind(
        this,
        eventTypes.videoMessage,
        eventActions.message,
        'ad blocker',
        null,
      ),
    );

    this._player.on(
      'fullscreenEnter',
      this._trackVideoPlayerEvent.bind(
        this,
        eventTypes.videoInteraction,
        eventActions.fullscreen,
        null,
        null,
      ),
    );
    this._player.on(
      'share',
      this._trackVideoPlayerEvent.bind(
        this,
        eventTypes.videoInteraction,
        eventActions.share,
        null,
        null,
      ),
    );

    this._player.on(
      'ageGating.shown',
      this._trackVideoPlayerEvent.bind(
        this,
        eventTypes.ageGate,
        eventActions.message,
        eventTypes.ageGate,
        null,
      ),
    );

    // Track suggestions according to PLP-7711
    this._player.on('suggestionsShown', (evt, options) => {
      if (options.suggestionAction) {
        this._trackVideoPlayerEvent(
          eventTypes.videoMessage,
          eventActions.suggestions,
          options.suggestionAction,
          null,
        );
      } else if (options.style === 'multipleformat') {
        this._trackVideoPlayerEvent(
          eventTypes.videoMessage,
          eventActions.suggestions,
          'related format',
          null,
        );
      } else {
        this._trackVideoPlayerEvent(
          eventTypes.videoMessage,
          eventActions.suggestions,
          'next episode',
          null,
        );
      }
    });

    // Track unsupported browsers according to PLP-8317
    this._player.on('unsupportedBrowser', () => {
      this._trackVideoPlayerEvent(
        eventTypes.videoMessage,
        eventActions.message,
        'unsupported browser',
        null,
      );
    });

    this._player.on('adError', (infos) => {
      this._trackVideoPlayerEvent(
        eventTypes.adError,
        eventActions.adViews,
        'adError',
        infos.src,
      );
    });
  }

  _setupMediaTrackingListeners() {
    // Video player interactions as per document in PLP-7142
    this._player.on('controlStart', () => {
      // [PLP-10940] if doNotTrackAutoplay is set in options, don't send autoplay as tracking event
      const action = (this._player.originalOptions.autoplay && !this._player.originalOptions.doNotTrackAutoplay) ?
        eventActions.autoplay : eventActions.play;

      this._trackVideoPlayerEvent(
        eventTypes.videoInteraction,
        action,
        null,
        null,
      );
    });

    // Video player notifications as per document in PLP-7142
    this._player.on('mediaStart', () => {
      this.stopwatch.startOrResume();
      this._trackRealContentStart();
      if (!this._player.originalOptions.startTime) {
        this._trackVideoPlayerEvent(
          eventTypes.videoPlaying,
          eventActions.videoPlaying,
          'video views',
          0,
        );
      } else {
        this._trackVideoPlayerEvent(
          eventTypes.videoPlaying,
          eventActions.videoPlaying,
          'resume video',
          0,
        );
      }
    });

    // Progress tracking
    this._player.on('mediaProgress', this._onProgress.bind(this));

    this._player.on(['mediaPause', 'adPause'], () => {
      this._trackVideoPlayerEvent(
        eventTypes.videoInteraction,
        eventActions.pause,
        null,
        null,
      );
    });
    this._player.on(['mediaPlay', 'adPlay'], () => {
      this._trackVideoPlayerEvent(
        eventTypes.videoInteraction,
        eventActions.play,
        null,
        null,
      );
    });

    this._player.on('mediaPause', () => {
      this.stopwatch.pause();
    });
    this._player.on('mediaPlay', () => {
      this.stopwatch.startOrResume();
    });

    this._player.on('mediaEnd', () => {
      this._trackVideoPlayerEvent(
        eventTypes.videoPlaying,
        eventActions.videoPlaying,
        '100',
        this.stopwatch.getTimeAndRestart(),
      );
    });
  }

  _setupAdTrackingListeners() {
    this._player.on('adPrerollStart', () => {
      this._adType = 'preroll';
    });
    this._player.on('adPostrollStart', () => {
      this._adType = 'postroll';
    });
    this._player.on('adMidrollStart', (evt, data) => {
      this.stopwatch.pause();
      this._adType = `midroll${data.position}`;
    });

    this._player.on('adMidrollEnd', () => {
      this.stopwatch.startOrResume();
    });
    this._player.on('adRollEnd', () => {
      this._adType = 'N/A';
    });

    this._player.on('adStart', (evt) => {
      this._trackRealContentStart();

      if (evt && evt.ad) {
        this._adFilmCode = evt.ad.customId;
        this._adDuration = evt.ad.duration;
      }
      this._trackVideoPlayerEvent(
        eventTypes.adPlaying,
        eventActions.adPlaying,
        'ad views',
        null,
      );
    });

    this._player.on('adEnd', () => {
      this._trackVideoPlayerEvent(
        eventTypes.adPlaying,
        eventActions.adPlaying,
        '100',
        this._adDuration,
      );
      this._adFilmCode = 'N/A';
    });

    this._player.on('pauseAdShowing', () => {
      this._trackVideoPlayerEvent(
        eventTypes.adPlaying,
        eventActions.adViews,
        'pause ad',
        null,
      );
    });
  }

  _onProgress(evt) {
    this._log('_onProgress', evt);
    const progress = this._options.progressResolution;

    if (evt.percentage === 100) return;

    if (progress && evt.percentage % progress !== 0) return;

    if (this._options.progressTracking.indexOf(evt.percentage) >= 0) {
      const percentage = evt.percentage.toString();

      this._trackVideoPlayerEvent(
        eventTypes.videoPlaying,
        eventActions.videoPlaying,
        percentage,
        this.stopwatch.getTimeAndRestart(),
      );
    }
  }

  _trackVideoPlayerEvent(eventType, eventAction, eventLabel, eventValue) {
    const event = {
      event: eventType,
      eventData: {
        category: 'video',
        action: eventAction,
      },
    };
    if (eventLabel !== null) {
      event.eventData.label = eventLabel;
    }
    if (eventValue !== null) {
      event.eventData.value = eventValue;
    }
    const overrideValues = {};
    if (this._customDimensions.adType) {
      overrideValues.adType = this._adType || 'N/A';
    }

    // [PLP-7727]: Add Freewheel film code in Custom Dimension
    this._customDimensions.adFilmCode = this._adFilmCode || 'N/A';

    objectAssign(event, this._customDimensions, overrideValues);
    this.dataLayer.push(event);

    this._log('REPORT EVENT >>', event.event, event.eventData, event);
  }

  // Only track the actual content start event according to PLP-7713
  _trackRealContentStart() {
    if (!this.mediaOrAdStartTriggered) {
      this._trackVideoPlayerEvent(
        eventTypes.contentStart,
        eventActions.contentStart,
        null,
        null,
      );
      this.mediaOrAdStartTriggered = true;
    }
  }

  _log(...args) {
    debugLog(`[#${this._player.id()}]`, ...args);
  }

  _error(...args) {
    errorLog(`[#${this._player.id()}]`, ...args);
    console.error('> avodp - ga error >>', args[0]); // eslint-disable-line no-console
  }
}

videojs.plugin('googleTagManager', function init(options) {
  this.gtm = new GoogleTagManagerPlugin(this, options);
});
