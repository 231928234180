import window from 'global/window';

export default class FreewheelSDKWrapper {
  static isLoaded() {
    return window.tv &&
      window.tv.freewheel &&
      window.tv.freewheel.SDK &&
      window.tv.freewheel.SDK.AdManager;
  }

  static get AdManager() {
    return (FreewheelSDKWrapper.isLoaded()) ?
      window.tv.freewheel.SDK.AdManager : null;
  }

  static get SDK() {
    return (FreewheelSDKWrapper.isLoaded()) ?
      window.tv.freewheel.SDK : null;
  }

  static setLogLevel(logLevel) {
    window.tv.freewheel.SDK.setLogLevel(window.tv.freewheel.SDK[logLevel]);
  }

  static get SLOT_TYPES() {
    return {
      PREROLL: window.tv.freewheel.SDK.TIME_POSITION_CLASS_PREROLL,
      MIDROLL: window.tv.freewheel.SDK.TIME_POSITION_CLASS_MIDROLL,
      POSTROLL: window.tv.freewheel.SDK.TIME_POSITION_CLASS_POSTROLL,
      OVERLAY: window.tv.freewheel.SDK.TIME_POSITION_CLASS_OVERLAY,
      DISPLAY: window.tv.freewheel.SDK.TIME_POSITION_CLASS_DISPLAY,
    };
  }

  static isTemporal(slotType) {
    return [
      FreewheelSDKWrapper.SLOT_TYPES.PREROLL,
      FreewheelSDKWrapper.SLOT_TYPES.MIDROLL,
      FreewheelSDKWrapper.SLOT_TYPES.POSTROLL]
      .indexOf(slotType) !== -1;
  }
}
