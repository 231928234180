import videojs from 'video.js';

const DEFAULT_OPTIONS = {
  errorMessage: 'Prohibited',
};

export default class DomainRestrict {
  constructor(player, options) {
    if (!options) throw new Error('no options provided');
    if (!options.allowedDomains) throw new Error('options.allowedDomains needs to be specified');
    if (!Array.isArray(options.allowedDomains)) throw new Error('options.allowedDomains needs to be of type Array');

    this._player = player;
    this._options = videojs.mergeOptions(DEFAULT_OPTIONS, options);

    if (!this._isAllowed(this._options.allowedDomains)) {
      this._player.ready(() => {
        // We wait for ready to avoid some videojs errors since now TextTrack setup happens on ready not on init
        this._player.trigger('destroyPlayer');
        this._player.trigger('showOverlayMessage', {
          message: this._options.errorMessage,
        });
      });
    }
  }

  /**
   * Allow domain ´<second-level-domain>.<top-level-domain>´ and any subdomain beneath it
   * @param originhash - serverside generated hash of ´<second-level-domain>.<top-level-domain>´
   * @returns {boolean}
   * @private
   */
  _isAllowed(allowedDomains) {
    let domain;

    if (this._inIframe()) {
      // For browsers that don't have referrer, we just allow the embed
      if (!document.referrer) return true;

      domain = document.referrer.replace(/https?:\/\//, '').split('/')[0];
    } else {
      domain = document.domain;
    }

    const parts = domain.split('.');
    const topDomain = parts.length > 1 ? `${parts[parts.length - 2]}.${parts[parts.length - 1]}` : parts[0];
    const domainHash = this._hashCode(topDomain);

    return allowedDomains.indexOf(domainHash) > -1;
  }

  _inIframe() {
    try {
      return window.self !== window.top;
    } catch (e) {
      return true;
    }
  }

  /**
   * Simple hashcode from Java String.hashCode()
   * source: http://werxltd.com/wp/2010/05/13/javascript-implementation-of-javas-string-hashcode-method/
   * @returns {number}
   */
  _hashCode(str) {
    let hash = 0;
    let i;
    let chr;
    let len;

    if (str.length === 0) return hash;

    for (i = 0, len = str.length; i < len; i += 1) {
      chr = str.charCodeAt(i);
      hash = ((hash << 5) - hash) + chr; // eslint-disable-line no-bitwise
      // Convert to 32bit integer
      hash |= 0; // eslint-disable-line no-bitwise
    }

    return hash;
    // jshint ignore:end
  }
}

videojs.plugin('domainRestrict', function init(options) {
  this.domainRestrict = new DomainRestrict(this, options);
});

