import Promise from 'promise';
import debug from 'debug';
import utils from '../utils';

const debugLog = debug('avodplayer:connectivity-detector:url');
const TIMEOUT_LIMIT = 10000;

/**
 * Polls a specified URL and checks if we get a normal response (200, 404, doesn't matter) or if the call is aborted.
 * For .js-files a script-tag is used due to CORS, for everything else we use XHR.
 */
export default class UrlAvailabilityDetector {

  constructor(url) {
    this._url = url;
  }

  check() {
    debugLog('checking');
    return new Promise((resolve, reject) => {
      this._checkUrl(this._url)
        .then(resolve)
        .catch(() => reject('url'));
    });
  }

  _checkUrl(url) {
    const fixedUrl = url.replace(/{RANDOM}/g, this._getRandomString);

    if (fixedUrl.match(/\.js/)) return this._checkUrlJS(fixedUrl);
    return this._checkUrlOther(fixedUrl);
  }

  /**
   * Based on https://github.com/sitexw/BlockAdBlock v4.0.0-beta.3
   */
  _checkUrlJS(url) {
    const element = document.createElement('script');
    element.async = true;
    const checkPromise = new Promise((resolve, reject) => {
      element.src = url;
      element.onerror = () => {
        reject();
        window.document.body.removeChild(element);
      };
      element.onload = () => {
        resolve();
        window.document.body.removeChild(element);
      };
      window.document.body.appendChild(element);
    });

    // [PLP-10632] Make the adblocker detection more friendly towards timeouts
    return utils.timeoutPromise(checkPromise, TIMEOUT_LIMIT)
      .then(() => {
        try {
          window.document.body.removeChild(element);
        } catch (e) {
          // The element was already removed
        }
      });
  }

  /**
   * Based on https://github.com/sitexw/BlockAdBlock v4.0.0-beta.3
   */
  _checkUrlOther(url) {
    return new Promise((resolve, reject) => {
      const readyStates = [false, false, true, false];
      let status = null;

      const respond = (responseForce) => {
        if (responseForce) {
          reject();
        } else {
          if (status === 0) {
            reject();
            return;
          }
          for (let i = 0; i < 4; i += 1) {
            if (readyStates[i] === false) {
              reject();
              return;
            }
          }
          resolve();
        }
      };

      const xhr = new XMLHttpRequest();
      xhr.onreadystatechange = () => {
        readyStates[xhr.readyState - 1] = true;
        status = xhr.status;
        if (xhr.readyState === 4) {
          respond();
        }
      };

      try {
        xhr.open('HEAD', url, true);
        xhr.timeout = TIMEOUT_LIMIT;
        xhr.send();
      } catch (e) {
        if (e.result === '2153644038') {
          respond(true);
        }
      }
    });
  }


  _getRandomString() {
    return Math.random().toString(36).substring(7);
  }
}
