import videojs from 'video.js';

const VjsComponent = videojs.getComponent('Component');

export default class OverlayMessageLabelComponent extends VjsComponent {
  createEl() {
    const props = {
      className: 'vjs-overlay-message-label',
      innerHTML: this.options_.label,
    };
    return VjsComponent.prototype.createEl('div', props, {});
  }
}
