import { render, unmountComponentAtNode } from 'react-dom';
import React from 'react';
import debug from 'debug';
import window from 'global/window';
import UIControlBar from './UI/UIControlBar';
import PlayerActions from './playerActions';


const debugLog = debug('castPlayer:log');

const PLAYER_STATE = {
  IDLE: 'IDLE',
  LOADING: 'LOADING',
  LOADED: 'LOADED',
  PLAYING: 'PLAYING',
  PAUSED: 'PAUSED',
  STOPPED: 'STOPPED',
  ERROR: 'ERROR',
};

/**
 * Cast player object
 * Main variables:
 *  - playerActions object for handling media playback
 *  - Cast player variables for controlling Cast mode media playback
 *  - Current media variables for transition between Cast and local modes
 * @struct @constructor
 */

export default class castPlayer {

  constructor() {
    /** @type {PLAYER_STATE} A state for media playback */
    this.playerState = PLAYER_STATE.IDLE;

    /* Cast player variables */
    /** @type {cast.framework.RemotePlayer} */
    this.remotePlayer = null;
    /** @type {cast.framework.RemotePlayerController} */
    this.remotePlayerController = null;
    this.savedPlayerState = null;
    this.renderElement = {
      SMALL: null,
      FULL: null,
    };
    this.chromecastCustomdata = {};
    window.castPlayerDebug = this;
  }

  initializeCastPlayer(isAvailable) {
    if (!isAvailable) return;

    const options = {};
    // Set the receiver application ID to your own (created in the
    // Google Cast Developer Console), or optionally
    // use the chrome.cast.media.DEFAULT_MEDIA_RECEIVER_APP_ID
    options.receiverApplicationId = window.receiverAppId;

    // Auto join policy can be one of the following three:
    // ORIGIN_SCOPED - Auto connect from same appId and page origin
    // TAB_AND_ORIGIN_SCOPED - Auto connect from same appId, page origin, and tab
    // PAGE_SCOPED - No auto connect
    options.autoJoinPolicy = window.chrome.cast.AutoJoinPolicy.ORIGIN_SCOPED;

    window.cast.framework.CastContext.getInstance().setOptions(options);

    this.remotePlayer = new window.cast.framework.RemotePlayer();
    this.remotePlayerController = new window.cast.framework.RemotePlayerController(this.remotePlayer);

    this.remotePlayerController.addEventListener(
      window.cast.framework.RemotePlayerEventType.IS_CONNECTED_CHANGED,
      () => setTimeout(this.switchPlayer.bind(this), 0)
    );
  }

  mediaSetup(elementId, options, avodPlayer) {
    debugLog('Sender options received', options);
    this.localPlayer = avodPlayer;

    let receivedRenderElement = null;
    if (typeof elementId === 'string') {
      receivedRenderElement = document.getElementById(elementId);
    } else { // Unit tests provide an element instead of an id
      receivedRenderElement = elementId;
    }

    if (options.displayMode !== 'FULL' && options.displayMode !== 'SMALL') {
      return;
    }

    this.displayMode = options.displayMode;

    if (this.displayMode === 'FULL' && !!this.renderElement.SMALL) {
      unmountComponentAtNode(this.renderElement.SMALL);
    } else if (this.displayMode === 'SMALL' && !!this.renderElement.FULL) {
      unmountComponentAtNode(this.renderElement.FULL);
    }

    this.renderElement[this.displayMode] = receivedRenderElement;
    if (this.renderElement[this.displayMode].className.indexOf('viafree-player') < 0) {
      this.renderElement[this.displayMode].className += ' viafree-player';
    }

    if (options.contentId) {
      this.potentialEpisodeInfo = {
        formatTitle: this.localPlayer._options.theme.formatTitle,
        videoTitle: this.localPlayer._options.theme.videoTitle,
        startTime: this.localPlayer._options.startTime,
        chromecastCustomdata: this.localPlayer._options.chromecastCustomdata,
        contentId: options.contentId,
        image: this.localPlayer._options.poster,
      };
      this.contentId = options.contentId;
    }

    const playerTranslations = (
        this.localPlayer &&
        this.localPlayer.languages &&
        this.localPlayer._options.language &&
        this.localPlayer.languages[this.localPlayer._options.language]
      ) || {};
    // Should exist this way this.translations = options.translations;
    this.translations = {
      castingOn: playerTranslations['Casting to'] || 'Casting', // eslint-disable-line dot-notation
      shouldCast: playerTranslations['Do you want to cast?'] || 'Do you want to cast?',
      playingIn: playerTranslations['The program starts in'] || 'The program starts in',
      subtitles: playerTranslations['Subtitles'] || 'Subtitles', // eslint-disable-line dot-notation
      subtitleOff: playerTranslations['Subtitles off'] || 'Off',
    };
    this.resumeCallback = options.resumeCallback;
    if (this.isConnected()) {
      this.launchUI();
    }
  }

  launchUI() {
    if (!this.renderElement[this.displayMode]) return;

    render(React.createElement(UIControlBar, {
      playerAction: this.playerActions,
      playerInfo: this.remotePlayer,
      translations: this.translations,
      displayMode: this.displayMode,
      potentialEpisodeInfo: this.potentialEpisodeInfo,
    }, null), this.renderElement[this.displayMode]);
  }

  isConnected() {
    return this.remotePlayer && this.remotePlayer.isConnected;
  }

  switchPlayer() {
    debugLog('Switching player');
    this.playerState = PLAYER_STATE.IDLE;
    if (this.remotePlayer.isConnected) {
      if (this.localPlayer) {
        this.currentLocalPlayerTime = this.localPlayer.getMediaCurrentTime();
        if (
          this.localPlayer._options &&
          this.localPlayer._options.chromecastCustomdata
        ) {
          this.chromecastCustomdata = Object.assign(
            this.chromecastCustomdata,
            this.localPlayer._options.chromecastCustomdata
          );
        }
        if (
          this.localPlayer._options &&
          this.localPlayer._options.plugins &&
          this.localPlayer._options.plugins.clientStream &&
          this.localPlayer._options.plugins.clientStream.data &&
          this.localPlayer._options.plugins.clientStream.data.userId
        ) {
          this.chromecastCustomdata = Object.assign(this.chromecastCustomdata, {
            userId: this.localPlayer._options.plugins.clientStream.data.userId,
          });
        }

        this.localPlayer.dispose();
      }
      this.setupRemotePlayer();
      this.launchUI();
    } else {
      this.resumeCallback(this.savedPlayerState);
    }
  }

  /**
   * Set the playerAction target to use the remote player
   */

  setupRemotePlayer() {
    /** @type {PlayerHandler} Delegation proxy for media playback */
    this.playerActions = new PlayerActions(this.remotePlayer, this.remotePlayerController);

    // Add event listeners for player changes which may occur outside sender app

    // There's no RemotePlayerEventType for savedPlayerState
    this.remotePlayerController.addEventListener(
      window.cast.framework.RemotePlayerEventType.ANY_CHANGE,
      (event) => {
        if (event.field === 'savedPlayerState') {
          this.savedPlayerState = event.value;
        }
      }
    );

    if (!this.remotePlayer.mediaInfo) {
      this.playerActions.load(this.contentId, this.currentLocalPlayerTime, this.chromecastCustomdata);
    }
  }
}
