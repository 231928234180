import React from 'react';
import debug from 'debug';
import PropTypes from 'prop-types';

const debugLog = debug('chromecastUI:log');

class ChangeVideo extends React.Component {
  static get propTypes() {
    return {
      playerAction: PropTypes.object.isRequired,
      playerInfo: PropTypes.object.isRequired,
      potentialEpisodeInfo: PropTypes.object,
    };
  }

  constructor(props, context) {
    super(props, context);
    this._hasPlayedVideo = false;
    debugLog('Constructing react NextEpisode', this.props);
    this.hasTheDialogBeenDismissed = this._shouldTheDialogBeDismissed();
    this.state = {
      showing: false,
    };

    /** Assume props
     string this.props.translatedShouldCast
     {} this.props.playerAction
     { countdownTime
      videoTitle
      videoImage
      videoNextType
      } = this.props.nextEpisodeInfo
     */
  }

  componentWillReceiveProps(nextProps) {
    // If we are currently playing the potential video, save that info for later.
    if (!this.hasTheDialogBeenDismissed) {
      this.hasTheDialogBeenDismissed = this._shouldTheDialogBeDismissed();
    }

    this.setState({ showing: this._shouldShow(nextProps) });
  }

  cancelEpisode() {
    this.hasTheDialogBeenDismissed = true;

    this.setState({ showing: false });
  }

  playEpisode() {
    if (this.props.playerAction) {
      this.props.playerAction.load(
        this.props.potentialEpisodeInfo.contentId,
        this.props.potentialEpisodeInfo.startTime,
        this.props.potentialEpisodeInfo.chromecastCustomdata
      );
    }
  }

  /**
   * Calculate if we should show the change video dialog
   */
  _shouldShow(nextProps) {
    // If we don't have all the new data, don't show the dialog
    if (
      this.props.playerInfo &&
      this.props.playerInfo.mediaInfo &&
      this.props.playerInfo.mediaInfo.duration
    ) {
      this._hasPlayedVideo = true;
    }

    if (!(
      this.props &&
      this.props.potentialEpisodeInfo
    )) {
      return false;
    }

    // If we don't have the old data then we are not casting anything so snow the dialog
    if (!(
      this.props.playerInfo &&
      this.props.playerInfo.mediaInfo) &&
      this._hasPlayedVideo
    ) {
      return true;
    }


    // If we are not updating the props, check to see if the currently playing video differs from
    // the one we are trying to load or if we have already played the potential video.
    if (!nextProps) {
      return (
        this.props.playerInfo.mediaInfo.contentId !== this.props.potentialEpisodeInfo.contentId
      ) ||
      !this.hasTheDialogBeenDismissed;
    }

    // Are we changing the video or have we never played the potential video
    return !!(
      (
        nextProps.potentialEpisodeInfo &&
        nextProps.potentialEpisodeInfo.contentId !== this.props.potentialEpisodeInfo.contentId
      ) ||
      !this.hasTheDialogBeenDismissed
    );
  }

  /**
   * Calculate if we should show dismiss the dialog, i.e. if we have played the potential episode
   */
  _shouldTheDialogBeDismissed() {
    return (
      this.props.potentialEpisodeInfo &&
      this.props.playerInfo &&
      this.props.playerInfo.mediaInfo &&
      this.props.potentialEpisodeInfo.contentId === this.props.playerInfo.mediaInfo.contentId
    );
  }

  render() {
    if (this.state.showing && this.props.shouldShow) {
      return (<div className="chromecast-change-video">
        <div className="content-change-video">
          <div>{this.props.translatedShouldCast}</div>
          <button className="cancel" onClick={e => this.cancelEpisode(e)}>Close</button>
        </div>
        <div className="chromecast-change-video-image-clicker">
          <img alt={this.props.potentialEpisodeInfo.videoTitle} src={this.props.potentialEpisodeInfo.image} />
          <button className="play control-icon-play" onClick={e => this.playEpisode(e)} />
        </div>
        <div className="content-change-video">
          <div className="content-change-video-title">{this.props.potentialEpisodeInfo.formatTitle}</div>
          <div
            className="content-change-video-subtitle"
            dangerouslySetInnerHTML={{ // eslint-disable-line react/no-danger
              __html: this.props.potentialEpisodeInfo.videoTitle,
            }}
          />
        </div>
      </div>);
    }
    return null;
  }
}

export default ChangeVideo;
