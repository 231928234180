import React from 'react';
import debug from 'debug';

const debugLog = debug('chromecastUI:log');

class PlayPauseButton extends React.Component {
  constructor(props, context) {
    super(props, context);
    debugLog('Constructing react playPauseButton', this.props);

    /** Assume two props
     {} this.props.playerAction
     bool this.props.isPaused
     */
  }

  play() {
    this.props.playerAction.play(this.props.playerAction);
  }

  pause() {
    this.props.playerAction.pause(this.props.playerAction);
  }

  render() {
    return (
      <div className="play-pause">
        {this.props.isPaused && <button
          id="play"
          className="chromecast-control-button control-icon-play"
          onClick={e => this.play(e)}
        />}
        {!this.props.isPaused && <button
          id="pause"
          className="chromecast-control-button control-icon-pause"
          onClick={e => this.pause(e)}
        />}
      </div>
    );
  }
}

export default PlayPauseButton;
