import debug from 'debug';
import objectAssign from 'object-assign';
import mimetypes from './mimetypes';

// Setup debugging
const debugLog = debug('AVODPlayer:AkamaiStreamingTools');

export default class AkamaiStreamingTools {
  modifyAkamaiHlsStream(sourceObjects, idealBitrate) {
    return sourceObjects.map((source) => {
      const newSource = objectAssign({}, source);
      if (newSource.type === mimetypes.HLS && newSource.src.indexOf('__b__') !== -1) {
        newSource.src = newSource.src.replace(/__b__=\d+/, `__b__=${idealBitrate}`);
        debugLog('Replaced source: ', newSource.src);
      }
      return newSource;
    });
  }
}
