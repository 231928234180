import debug from 'debug';
import videojs from 'video.js';

// Setup debugging
const debugLog = debug('TimelineThumbnails');

const DEFAULT_OPTIONS = {};

// const VjsComponent = videojs.getComponent('Component');

class SimpleComponent {
  static createComponent(options) {
    const _options = {
      tagName: 'div',
      componentClass: 'Component',
      className: '',
      innerHTML: '',
      ...options,
    };

    return {
      componentClass: _options.componentClass,
      el: videojs.getComponent(_options.componentClass).prototype.createEl(_options.tagName, {
        className: _options.className,
        innerHTML: _options.innerHTML,
      }),
    };
  }
}

// from vjs/utils/dom.js
const Dom = {
  getBoundingClientRect: (el) => {
    const result = {};
    if (el && el.getBoundingClientRect && el.parentNode) {
      const rect = el.getBoundingClientRect();

      ['bottom', 'height', 'left', 'right', 'top', 'width'].forEach((k) => {
        if (rect[k] !== undefined) {
          result[k] = rect[k];
        }
      });

      if (!result.height) {
        result.height = parseFloat(Dom.computedStyle(el, 'height'));
      }

      if (!result.width) {
        result.width = parseFloat(Dom.computedStyle(el, 'width'));
      }
    }
    return result;
  },
  findPosition: (el) => {
    let box;

    if (el.getBoundingClientRect && el.parentNode) {
      box = el.getBoundingClientRect();
    }

    if (!box) {
      return {
        left: 0,
        top: 0,
      };
    }

    const docEl = document.documentElement;
    const body = document.body;

    const clientLeft = docEl.clientLeft || body.clientLeft || 0;
    const scrollLeft = window.pageXOffset || body.scrollLeft;
    const left = box.left + (scrollLeft - clientLeft);

    const clientTop = docEl.clientTop || body.clientTop || 0;
    const scrollTop = window.pageYOffset || body.scrollTop;
    const top = box.top + (scrollTop - clientTop);

    // Android sometimes returns slightly off decimal values, so need to round
    return {
      left: Math.round(left),
      top: Math.round(top),
    };
  },
  getPointerPosition: (el, event) => {
    const position = {};
    const box = Dom.findPosition(el);
    const boxW = el.offsetWidth;
    const boxH = el.offsetHeight;

    const boxY = box.top;
    const boxX = box.left;
    let pageY = event.pageY;
    let pageX = event.pageX;

    if (event.changedTouches) {
      pageX = event.changedTouches[0].pageX;
      pageY = event.changedTouches[0].pageY;
    }

    position.y = Math.max(0, Math.min(1, ((boxY - pageY) + boxH) / boxH));
    position.x = Math.max(0, Math.min(1, (pageX - boxX) / boxW));

    return position;
  },
  computedStyle: (el, prop) => {
    if (!el || !prop) {
      return '';
    }

    if (typeof window.getComputedStyle === 'function') {
      const cs = window.getComputedStyle(el);

      return cs ? cs[prop] : '';
    }

    return el.currentStyle[prop] || '';
  },
};

export default class TimelineThumbnailsPlugin {
  constructor(player, incomingOptions) {
    this._player = player;
    this._options = videojs.mergeOptions(DEFAULT_OPTIONS, incomingOptions);


    if (!incomingOptions) {
      this._log('no options provided');
      return;
    }
    this._log('options', this._options);

    this._player.ready(() => {
      this._player.el().classList.add('x-timeline-thumbnails');
      this._player.one('mediaStart', () => this._handleTimelineThumbnails());
      this._createComponent();
    });
  }


  _handleTimelineThumbnails() {
    this._log('_handleTimelineThumbnails');
    this._player.controlBar.progressControl.seekBar.el().addEventListener('mouseout', () => {
      this.thumbnailContainer.el.style.display = 'none';
    });
    this._player.controlBar.progressControl.seekBar.el().addEventListener('mousemove', (event) => {
      const seekBar = this._player.controlBar.progressControl.seekBar;
      const seekBarEl = seekBar.el();
      const seekBarRect = Dom.getBoundingClientRect(seekBarEl);
      let seekBarPoint = Dom.getPointerPosition(seekBarEl, event).x;

      if (seekBarPoint > 1) {
        seekBarPoint = 1;
      } else if (seekBarPoint < 0) {
        seekBarPoint = 0;
      }

      const x = ((seekBarRect.right - seekBarRect.left) * seekBarPoint);
      this.thumbnailContainer.el.style.left = `${x}px`;
      this.thumbnailContainer.el.style.display = 'block';

      const spriteMeta = this._positionToSpriteMeta(seekBarPoint);

      const spriteUrl = this._options.srcUrl
        .replace('{number}', (spriteMeta.fileNumber < 10 ? '0' : '') + spriteMeta.fileNumber);
      const spriteX = spriteMeta.x * this._options.tileDimensions.width;
      const spriteY = spriteMeta.y * this._options.tileDimensions.height;

      this.thumbnailContainer.el.style.backgroundImage = `url(${spriteUrl})`;
      this.thumbnailContainer.el.style.backgroundPositionX = `-${spriteX}px`;
      this.thumbnailContainer.el.style.backgroundPositionY = `-${spriteY}px`;
    });
  }

  _positionToSpriteMeta(position) {
    const imagesPerFile = this._options.rows * this._options.columns;
    const imageNumber = Math.floor((position / this._options.granularity));
    const fileNumber = parseInt(imageNumber / imagesPerFile, 10);
    const remainder = imageNumber - (fileNumber * 25);

    return {
      fileNumber: fileNumber + 1,
      y: parseInt(remainder / this._options.rows, 10),
      x: remainder % this._options.columns,
    };
  }

  _createComponent() {
    this.thumbnailContainer = SimpleComponent.createComponent({
      className: 'x-timeline-thumbnail',
      innerHtml: 'test',
    });

    this._player.controlBar.addChild('thumbnailContainer', this.thumbnailContainer);
  }

  _log(...args) {
    debugLog(`[#${this._player.id()}]`, ...args);
  }
}

videojs.plugin('timelineThumbnails', function init(options) {
  this.timelineThumbnails = new TimelineThumbnailsPlugin(this, options);
});
