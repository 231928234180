import videojs from 'video.js';

export default class SimpleComponent {
  static createComponent(options) {
    const _options = {
      tagName: 'div',
      componentClass: 'Component',
      className: '',
      innerHTML: '',
      ...options,
    };

    return {
      componentClass: _options.componentClass,
      el: videojs.getComponent(_options.componentClass).prototype.createEl(_options.tagName, {
        className: _options.className,
        innerHTML: _options.innerHTML,
      }),
    };
  }
}
