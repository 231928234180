import debug from 'debug';
import vjs from 'video.js';

// Setup debugging
const debugLog = debug('MtgxSkinPlugin-ChromecastButton');

const VjsButton = vjs.getComponent('ClickableComponent');

export default class CastButton extends VjsButton {
  constructor(player) {
    super();
    this._player = player;
  }

  handleClick() {
    this._player.pause();
  }

  createEl() {
    return VjsButton.prototype.createEl.call(this, 'div', {
      className: 'vjs-castbutton vjs-button',
      role: 'button',
      'aria-live': 'polite',
      innerHTML: '<button is="google-cast-button" ' +
      'id="castbutton" ' +
      'class=""></button>',
    });
  }


  _log(...args) {
    debugLog(`[#, ${this._player.id()}]`, ...args);
  }
}

