import browserUtils from './browser';

const defaultBitrates = {
  mobile: 750,
  tablet: 1500,
  desktop: 2000,
};

let defaultBitrate = defaultBitrates.desktop;

if (browserUtils.env.mobile) defaultBitrate = defaultBitrates.mobile;
else if (browserUtils.env.tablet) defaultBitrate = defaultBitrates.tablet;

export default {
  getIdealBitrate: () => defaultBitrate,
};
