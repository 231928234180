import window from 'global/window';

class Shortcuts {
  constructor() {
    this._keyDown = this._keyDown.bind(this);
  }
  init(player) {
    this._player = player;
    window.addEventListener('keydown', this._keyDown);
  }
  _keyDown(event) {
    if (this._player.isFullscreen()) {
      if (event.which === 32) {
        if (this._player.paused()) this._player.play();
        else this._player.pause();

        event.preventDefault();
      }
    }
  }
}

export default new Shortcuts();
